/* reset */
/* main variables */
body { margin: 0px; padding: 0px; font-family: TTFirsNeue, arial, sans-serif; background-color: #fff; height: 100%; font-weight: 400; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color: #1F1F1F; position: relative; scroll-behavior: smooth; font-size: 2rem !important; line-height: 1.2; background-repeat: repeat; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { font-size: 10px !important; height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; }

input, textarea, select, button { font-family: TTFirsNeue, arial, sans-serif; }

input, textarea { color: #1F1F1F; font-family: TTFirsNeue, arial, sans-serif; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

input[type="button"], input[type="submit"], button { cursor: pointer; }

td { margin: 0px; padding: 0px; }

form { padding: 0px; margin: 0px; }

a { color: #FFDA22; -webkit-transition: all 0.3s ease-in; -moz-transition: all 0.3s ease-in; -ms-transition: all 0.3s ease-in; -o-transition: all 0.3s ease-in; transition: all 0.3s ease-in; text-decoration: none; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a, span, div, button { outline: none !important; }

input[type=submit], input[type=button], button { -webkit-appearance: none; outline: none; }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); outline: none; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

strong { font-weight: 700; }

.clearfix:after, .wrapper:after, .row:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

img { max-width: 100%; }

@media screen and (max-width: 1599px) { html { font-size: 9px !important; } }

@media screen and (max-width: 1439px) { body { font-size: 1.8rem !important; } }

@media screen and (max-width: 767px) { html { font-size: 10px !important; }
  body { font-size: 1.6rem !important; } }

@media screen and (max-width: 359px) { html { font-size: 9px !important; } }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-Light/TTFirsNeue-Light.eot"); src: url("../fonts/TTFirsNeue-Light/TTFirsNeue-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-Light/TTFirsNeue-Light.woff2") format("woff2"), url("../fonts/TTFirsNeue-Light/TTFirsNeue-Light.woff") format("woff"), url("../fonts/TTFirsNeue-Light/TTFirsNeue-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; font-display: swap; }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-Regular/TTFirsNeue-Regular.eot"); src: url("../fonts/TTFirsNeue-Regular/TTFirsNeue-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-Regular/TTFirsNeue-Regular.woff2") format("woff2"), url("../fonts/TTFirsNeue-Regular/TTFirsNeue-Regular.woff") format("woff"), url("../fonts/TTFirsNeue-Regular/TTFirsNeue-Regular.ttf") format("truetype"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-Italic/TTFirsNeue-Italic.eot"); src: url("../fonts/TTFirsNeue-Italic/TTFirsNeue-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-Italic/TTFirsNeue-Italic.woff2") format("woff2"), url("../fonts/TTFirsNeue-Italic/TTFirsNeue-Italic.woff") format("woff"), url("../fonts/TTFirsNeue-Italic/TTFirsNeue-Italic.ttf") format("truetype"); font-weight: 400; font-style: italic; font-display: swap; }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-Medium/TTFirsNeue-Medium.eot"); src: url("../fonts/TTFirsNeue-Medium/TTFirsNeue-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-Medium/TTFirsNeue-Medium.woff2") format("woff2"), url("../fonts/TTFirsNeue-Medium/TTFirsNeue-Medium.woff") format("woff"), url("../fonts/TTFirsNeue-Medium/TTFirsNeue-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; font-display: swap; }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-DemiBold/TTFirsNeue-DemiBold.eot"); src: url("../fonts/TTFirsNeue-DemiBold/TTFirsNeue-DemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-DemiBold/TTFirsNeue-DemiBold.woff2") format("woff2"), url("../fonts/TTFirsNeue-DemiBold/TTFirsNeue-DemiBold.woff") format("woff"), url("../fonts/TTFirsNeue-DemiBold/TTFirsNeue-DemiBold.ttf") format("truetype"); font-weight: 600; font-style: normal; font-display: swap; }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-Bold/TTFirsNeue-Bold.eot"); src: url("../fonts/TTFirsNeue-Bold/TTFirsNeue-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-Bold/TTFirsNeue-Bold.woff2") format("woff2"), url("../fonts/TTFirsNeue-Bold/TTFirsNeue-Bold.woff") format("woff"), url("../fonts/TTFirsNeue-Bold/TTFirsNeue-Bold.ttf") format("truetype"); font-weight: 700; font-style: normal; font-display: swap; }

@font-face { font-family: 'TTFirsNeue'; src: url("../fonts/TTFirsNeue-MediumItalic/TTFirsNeue-MediumItalic.eot"); src: url("../fonts/TTFirsNeue-MediumItalic/TTFirsNeue-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTFirsNeue-MediumItalic/TTFirsNeue-MediumItalic.woff2") format("woff2"), url("../fonts/TTFirsNeue-MediumItalic/TTFirsNeue-MediumItalic.woff") format("woff"), url("../fonts/TTFirsNeue-MediumItalic/TTFirsNeue-MediumItalic.ttf") format("truetype"); font-weight: 500; font-style: italic; font-display: swap; }

@font-face { font-family: 'icomoon'; font-display: block; src: url("../fonts/icomoon/fonts/icomoon.eot?ihyadm"); src: url("../fonts/icomoon/fonts/icomoon.eot?ihyadm#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf?ihyadm") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff?ihyadm") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg?ihyadm#icomoon") format("svg"); font-weight: normal; font-style: normal; font-display: swap; }

[class^="icon-"], [class*=" icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-search:before { content: "\e922"; }

.icon-arrow:before, .menu-dropdown ul a span:before { content: "\e900"; }

.icon-arrow-back:before { content: "\e901"; }

.icon-arrow-short:before, .accept-change:hover:before { content: "\e902"; }

.icon-calendar:before { content: "\e903"; }

.icon-caret:before, .icon-caret-side:before { content: "\e904"; }

.icon-case:before { content: "\e905"; }

.icon-check:before { content: "\e906"; }

.icon-chevron:before { content: "\e907"; }

.icon-close:before, .tab.shown .link-accordion i:before, .faq.active .faq__icon i:before { content: "\e908"; }

.icon-crown:before { content: "\e909"; }

.icon-crown-2:before { content: "\e90a"; }

.icon-down:before { content: "\e90b"; }

.icon-download:before { content: "\e90c"; }

.icon-edit:before { content: "\e90d"; }

.icon-email:before { content: "\e90e"; }

.icon-load:before { content: "\e90f"; }

.icon-location:before { content: "\e910"; }

.icon-login:before { content: "\e911"; }

.icon-look:before { content: "\e912"; }

.icon-look_close:before { content: "\e913"; }

.icon-minus:before { content: "\e914"; }

.icon-next:before { content: "\e915"; }

.icon-next-1:before { content: "\e916"; }

.icon-place:before { content: "\e917"; }

.icon-plus:before { content: "\e918"; }

.icon-prev:before { content: "\e919"; }

.icon-prev-1:before { content: "\e91a"; }

.icon-project:before { content: "\e91b"; }

.icon-quotes:before, .page-osnova .article blockquote:before { content: "\e91c"; }

.icon-remove:before { content: "\e91d"; }

.icon-tel:before { content: "\e91e"; }

.icon-up:before { content: "\e91f"; }

.icon-warning:before { content: "\e920"; }

.icon-warning-square:before { content: "\e921"; }

.main-wrapper { padding: 0; min-width: 320px; width: 100%; position: relative; overflow: hidden; min-height: 100%; display: flex; flex-direction: column; justify-content: flex-start; transition: filter .3s ease-out; z-index: 1; }

.wrapper { min-width: 320px; max-width: 1822px; padding-right: 5rem; padding-left: 5rem; margin: 0 auto; position: relative; width: 100%; }

.wrapper-full { max-width: 100%; padding-right: 3.4rem; padding-left: 3.4rem; }

footer { order: 3; }

/* titles */
p { padding: 1.3rem 0; }

h1, .h1 { font-weight: 500; font-size: 8rem; line-height: 120%; margin-bottom: 3.6rem; overflow: hidden; padding-bottom: 2px; }

h1 span, .h1 span { display: inline-block; transform: translateY(120%); }

.content-loaded h1 span, .content-loaded .h1 span { transform: translateY(0); transition: transform .6s ease; }

body:not(.content-loaded) .wow:not(.anim-2) { opacity: 0; animation: none; }

.h1 { margin-bottom: 3rem; max-width: 60%; position: relative; }

.h1:after { content: ''; display: block; margin-top: 20px; height: 0.4rem; width: 9rem; background: #FFDA22; }

.h1-md { font-size: 6rem; }

.h1-sm { font-size: 4rem; }

h2, .h2 { font-weight: 500; font-size: 3rem; line-height: 1.3; padding: 0; margin: 6.9rem 0 .9rem; padding-bottom: 2px; }

h2 div, .h2 div { overflow: hidden; }

h2 span, .h2 span { display: inline-block; }

.h2 { margin: 0 0 44px; font-size: 4rem; line-height: 1.2; }

.h2:after { content: ''; display: block; margin-top: 20px; border: 0; height: 0.4rem; width: 9rem; background: #FFDA22; }

h3, .h3 { font-size: 3rem; line-height: 1.2; font-weight: 700; padding: 1rem 0 2rem; text-align: center; }

.h3 { padding: 0 0 3.3rem; }

.h2.animated:after { transform: scale(1); }

.section-dark { background: #1F1F1F; color: #fff; }

.section-dark .h2 { color: #fff; }

.section-dark .h3 { color: #fff; }

h4 { font-weight: 700; line-height: 1.4; text-transform: uppercase; padding-top: 25px; }

/* text position */
.text-left { text-align: left !important; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.nowrap { white-space: nowrap !important; }

.center { text-align: center; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content { min-width: 320px; text-align: left; width: 100%; order: 2; flex-grow: 1; position: relative; }

@keyframes smoothbounceball { from { transform: translate3d(0, 0, 0); }
  30% { transform: translate3d(-2rem, 0, 0); }
  60% { transform: translate3d(0, 0, 0); }
  to { transform: translate3d(0, 0, 0); } }

@keyframes smoothbounceball2 { from { transform: translate3d(0, 0, 0); }
  30% { transform: translate3d(2rem, 0, 0); }
  60% { transform: translate3d(0, 0, 0); }
  to { transform: translate3d(0, 0, 0); } }

.btn { border: 0; color: #1F1F1F; font-weight: 400; padding: 0 3.9rem; vertical-align: top; text-align: center; height: 10rem; line-height: 10rem; font-size: 1.8rem; background: #FFDA22; text-transform: uppercase; border-radius: 0; display: inline-flex; align-items: center; justify-content: center; }

.btn i { width: 0; font-size: 70%; overflow: hidden; transition: margin-left .5s ease, width .5s ease; display: inline-block; }

.btn:disabled { pointer-events: none; background: #E3E3E3; }

.btn:hover i { margin-left: 5.8rem; width: auto; }

.btn-2 i { margin-left: 24px; width: auto; }

.btn-2:hover i { margin-left: 5.8rem; }

.btn-3 { background: #1F1F1F; color: #fff; }

.btn-sm { height: 6rem; line-height: 6rem; }

.btn_sm { height: 5.9rem; line-height: 5.9rem; }

[type="text"], [type="tel"], [type="email"], [type="password"], textarea { height: 6.4rem; position: relative; border: 1px solid #B5B5B5; border-radius: 0; color: #1F1F1F; background: #FCFCFC; font-size: 1.6rem; z-index: 1; padding: 0 3.1rem; font-weight: 400; width: 100%; }

[type="text"].error, [type="tel"].error, [type="email"].error, [type="password"].error, textarea.error { border-color: #FF5050; }

[type="text"]:focus, [type="tel"]:focus, [type="email"]:focus, [type="password"]:focus, textarea:focus { border-color: #000; }

[type="text"]:-webkit-autofill, [type="text"]:-webkit-autofill:hover, [type="text"]:-webkit-autofill:focus, [type="tel"]:-webkit-autofill, [type="tel"]:-webkit-autofill:hover, [type="tel"]:-webkit-autofill:focus, [type="email"]:-webkit-autofill, [type="email"]:-webkit-autofill:hover, [type="email"]:-webkit-autofill:focus, [type="password"]:-webkit-autofill, [type="password"]:-webkit-autofill:hover, [type="password"]:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus { background: #fff; border: 1px solid #B5B5B5; -webkit-text-fill-color: #000; -webkit-box-shadow: inset 0 0 100px 40px transparent; transition: none; }

[type="text"]:disabled, [type="tel"]:disabled, [type="email"]:disabled, [type="password"]:disabled, textarea:disabled { border-color: #e4e4e4; color: #cdcdcd; }

[type="text"]:disabled::-webkit-input-placeholder, [type="tel"]:disabled::-webkit-input-placeholder, [type="email"]:disabled::-webkit-input-placeholder, [type="password"]:disabled::-webkit-input-placeholder, textarea:disabled::-webkit-input-placeholder { color: #cdcdcd; }

[type="text"]:disabled::-moz-placeholder, [type="tel"]:disabled::-moz-placeholder, [type="email"]:disabled::-moz-placeholder, [type="password"]:disabled::-moz-placeholder, textarea:disabled::-moz-placeholder { color: #cdcdcd; }

[type="text"]:disabled:-moz-placeholder, [type="tel"]:disabled:-moz-placeholder, [type="email"]:disabled:-moz-placeholder, [type="password"]:disabled:-moz-placeholder, textarea:disabled:-moz-placeholder { color: #cdcdcd; }

[type="text"]:disabled:-ms-input-placeholder, [type="tel"]:disabled:-ms-input-placeholder, [type="email"]:disabled:-ms-input-placeholder, [type="password"]:disabled:-ms-input-placeholder, textarea:disabled:-ms-input-placeholder { color: #cdcdcd; }

::-webkit-input-placeholder { color: #B5B5B5; }

::-moz-placeholder { color: #B5B5B5; }

:-moz-placeholder { color: #B5B5B5; }

:-ms-input-placeholder { color: #B5B5B5; }

.field-disable .box-field_label { color: #cdcdcd; }

textarea { padding-top: 1.6rem; width: 100%; height: 11.2rem; resize: none; }

label.error, .help-block { color: #FF5050; font-weight: 400; font-size: 1.4rem; line-height: 1.9rem; display: block; }

.box-field { position: relative; margin-bottom: 3.5rem; }

.box-field__input { position: relative; }

.box-field__input label.error { position: absolute; left: 0; top: 100%; }

.box-field__checkbox { margin-top: 15px; }

.box-field__btn { margin-top: 25px; }

.box-field__label { font-size: 1.6rem; margin-bottom: 6px; }

.form-checkbox { margin: 47px 0 28px; }

.scrollDisabled { overflow: hidden; position: fixed !important; margin-top: 0; width: 100%; z-index: 0; }

.scrollDisabled.web, .scrollDisabled.web.compensate-for-scrollbar { padding-right: 17px; }

.scrollDisabled .main-wrapper { overflow: visible; }

.hide { display: none; }

.color-decor { color: #29A690; }

.decor-wrap { overflow: hidden; height: 21rem; z-index: 1; width: 100%; position: relative; margin-top: 8rem; }

.decor-wrap_2 { margin-top: 11rem; }

.socials__item { margin-right: 3rem; display: inline-block; }

.checkbox-el { font-weight: 500; }

.checkbox-el label { position: relative; }

.checkbox-el input { position: absolute; left: 0; top: 0; opacity: 0; }

.checkbox-el__txt { font-weight: 500; font-size: 1.6rem; line-height: 1.9rem; color: #1F1F1F; display: inline-flex; align-items: center; cursor: pointer; }

.checkbox-el .check { display: block; border: 1px solid #B5B5B5; background: #FCFCFC; width: 3.5rem; height: 3.5rem; flex-shrink: 0; margin: 0 1.5rem 0 0; position: relative; border-radius: 0; }

.checkbox-el .check:after { position: absolute; left: -.2rem; content: ''; top: 50%; margin-top: -1rem; width: 100%; height: 100%; }

.checkbox-el input:checked ~ .checkbox-el__txt .check { background: #FFDA22; transition: border-color .2s ease, background .2s ease; }

.checkbox-el input:checked ~ .checkbox-el__txt .check:after { background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNyAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTcgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNS4zLDAuMyA3LjQsOC42IDEuNiwzLjcgMC40LDUuMyA3LjYsMTEuNCAxNi43LDEuNyAiLz4NCjwvc3ZnPg0K"); background-size: 1.8rem 1.8rem; background-position: 64% 0%; background-repeat: no-repeat; }

.checkbox-el .decor { border-bottom: 1px solid; }

.checkbox-el:hover .check { border-color: #808080; }

.checkbox-el input:disabled ~ .checkbox-el__txt { pointer-events: none; color: #cdcdcd; }

.checkbox-el input:disabled ~ .checkbox-el__txt .check { border-color: #e4e4e4; }

.radio-el { font-weight: 500; }

.radio-el label { position: relative; }

.radio-el input { position: absolute; left: 0; top: 0; opacity: 0; }

.radio-el__txt { font-weight: 500; font-size: 1.6rem; line-height: 1.9rem; color: #1F1F1F; display: inline-flex; align-items: center; cursor: pointer; }

.radio-el .check { display: block; border: 1px solid #B5B5B5; background: #fff; width: 2.5rem; height: 2.5rem; flex-shrink: 0; margin: 0 1.5rem 0 0; position: relative; border-radius: 50%; }

.radio-el .check:after { position: absolute; left: 50%; content: ''; top: 50%; width: 1.5rem; height: 1.5rem; transition: background .2s ease; transform: translate(-50%, -50%); border-radius: 50%; }

.radio-el input:checked ~ .radio-el__txt .check { border-color: #FFDA22; transition: border-color .2s ease; }

.radio-el input:checked ~ .radio-el__txt .check:after { background: #FFDA22; }

.radio-el input.error ~ .radio-el__txt .check { border-color: #FF5050; }

.radio-el .decor { border-bottom: 1px solid; }

.radio-el:hover .check { border-color: #808080; }

.radio-el input:disabled ~ .radio-el__txt { pointer-events: none; color: #cdcdcd; }

.radio-el input:disabled ~ .radio-el__txt .check { border-color: #e4e4e4; }

.web .checkbox-el__txt:hover:before { transition: border-color .4s ease; border-color: #FFDA22; }

.tab .tab-content { height: 0; overflow: hidden; opacity: 0; transition: opacity .5s ease; }

.link-accordion { display: none; }

.tab.shown .tab-content { height: auto; opacity: 1; }

.tab.shown .link-accordion i { font-size: 130%; }

.accordion-body { display: none; }

.tooltip-wrap { position: relative; margin-left: 8px; font-size: 1.6rem; display: inline-block; }

.tooltip-wrap:hover { z-index: 10000; }

.tooltip-wrap:hover .tooltip-text { display: block; }

.tooltip-icon { width: 2.4rem; height: 2.4rem; border-radius: 50%; background: #FFDA22; line-height: 2.4rem; font-weight: 500; display: inline-block; text-align: center; cursor: pointer; }

.tooltip-text { position: absolute; z-index: 1; display: none; left: 0; top: 100%; padding: 10px; width: 150px; background: #fff; border: 1px solid #B5B5B5; }

@media screen and (max-width: 1439px) { .btn { padding: 0 3.1rem; height: 7rem; line-height: 7rem; } }

@media screen and (max-width: 1365px) { .h1 { font-size: 5rem; }
  .h2 { font-size: 3.5rem; } }

@media screen and (min-width: 1024px) { .tablet-show { display: none !important; } }

@media screen and (max-width: 1023px) { .h1 { font-size: 4.2rem; }
  .wrapper { padding-left: 3rem; padding-right: 3rem; }
  .tablet-hide { display: none !important; }
  h2 { font-size: 2.4rem; margin-bottom: 1rem; margin-top: 4.2rem; }
  .h2 { font-size: 3rem; margin-bottom: 2.9rem; margin-top: 0; }
  .h3 { text-align: center; font-size: 2.8rem; line-height: 3.7rem; margin-left: auto; margin-right: auto; }
  .box-field { margin-bottom: 2.3rem; }
  [type=text], [type=tel], [type=email], [type="password"] { font-size: 1.4rem; padding-left: 2.3rem; padding-right: 2.3rem; height: 5.6rem; } }

@media screen and (max-width: 767px) { h1, .h1 { font-size: 2.4rem; line-height: 130%; max-width: 100%; }
  .h1:after { width: 6.3rem; margin-top: 1.2rem; }
  h2 { font-size: 1.8rem; margin-top: 2rem; margin-bottom: 0.6rem; }
  .h2 { max-width: 100%; font-size: 2.4rem; margin-top: 0; }
  .h2:after { width: 6.3rem; margin-top: 1.2rem; }
  .h1-sm { font-size: 2.4rem; }
  .h2-sm { font-size: 2rem; }
  .h3 { font-size: 2rem; line-height: 2.3rem; padding: 0 0 2.3rem; }
  p { padding: .8rem 0 .9rem; }
  [type=text], [type=tel], [type=email], [type="password"], textarea { font-size: 1.6rem; padding-left: 1.6rem; padding-right: 1.6rem; height: 5rem; }
  textarea { height: 11.2rem; border-radius: .8rem; }
  .wrapper { padding-left: 2.4rem; padding-right: 2.4rem; }
  .mob-hide { display: none !important; }
  .label-placeholder { font-size: 12px; line-height: 15px; }
  .form-control { font-size: 1.6rem; }
  .filled .label-placeholder { font-size: 11px; }
  .btn { font-size: 1.4rem; height: 6rem; line-height: 6rem; padding: 0 1.6rem; }
  .btn i { font-size: .7rem; }
  .btn:hover i { margin-left: 1.8rem; }
  .btn-2 i { margin-left: 1.7rem; }
  .box-field__button { text-align: center; }
  .box-field__button .btn { width: 100%; max-width: 48rem; }
  .link-accordion { padding: 2.3rem 0; font-size: 1.8rem; font-weight: 500; color: #1F1F1F; display: flex; align-items: center; justify-content: space-between; transition: color .4s ease, background .4s ease; }
  .link-accordion i { font-size: 50%; margin-left: 2.5rem; flex-shrink: 0; }
  .tab { border-bottom: 1px solid #B5B5B5; }
  .tab:first-child { border-top: 1px solid #B5B5B5; }
  .tab.shown .tab-content { padding-bottom: 2rem; } }

@media screen and (min-width: 768px) { .mob-show { display: none !important; } }

.header { z-index: 10; position: fixed; left: 0; top: 0; right: 0; z-index: 10001; flex-shrink: 0; background: #1F1F1F; color: #fff; border-bottom: 1px solid #808080; }

.header .wrapper { display: flex; justify-content: space-between; }

.header .wrapper:after { display: none; }

.header__info { font-size: 1.6rem; display: flex; position: relative; }

.header__info a { color: inherit; }

.header__info > * { position: relative; z-index: 1; }

.header__info:before { content: ''; display: block; left: 0; width: 15000px; top: 0; bottom: 0; background: rgba(255, 255, 255, 0.05); box-shadow: inset 0px 9px 28px 0px rgba(255, 255, 255, 0.05); position: absolute; z-index: 0; }

.header__login { display: flex; align-items: center; padding: 1rem 4.1rem 1rem 7.4rem; }

.header__login i { margin-right: 10px; font-size: 2.7rem; margin-top: -.3rem; }

.header__projects { display: flex; align-items: center; padding: 1rem 6.6rem 1rem 3.2rem; }

.header__projects i { margin-right: 1.1rem; margin-top: -.2rem; font-size: 3.2rem; }

.header__login .loginned { display: inline-flex; align-items: center; position: relative; }

.header__login .loginned span { display: flex; max-width: 16.8rem; }

.header__login .logined-icon { color: #FFDA22; position: absolute; left: .6rem; top: -.4rem; font-size: 53%; }

.nav-inner { width: 100%; display: flex; }

.nav-logo { padding: .7rem 0 .6rem; flex-shrink: 0; margin-right: 2rem; min-height: 10rem; display: flex; align-items: center; }

.menu-box { background-color: #1f1f1f; padding: 7rem 0 3rem; position: fixed; left: 0; transform: translateY(-150vh); top: 10.9rem; margin: 0; z-index: 100; width: 100%; bottom: 0; width: 100%; color: #fff; overflow: auto; align-items: flex-start; color: #fff; transition: transform 360ms cubic-bezier(0.5, 0.06, 0.01, 0.99); will-change: right; bottom: 0; background-size: auto 100%; background-position: 100% 100%; background-repeat: no-repeat; }

.menu-box.active { transform: translateY(0); }

.menu-box.active:before { height: 0; }

.menu-box .wrapper { display: block; z-index: 0; }

.menu-box:before { content: ""; position: absolute; bottom: 0; left: 0; z-index: 1; width: 100%; height: 100%; display: block; background-color: #4d4d4c; transition: height 0.85s cubic-bezier(0.5, 0.06, 0.01, 0.99) 0.5s; will-change: height; }

.logo-white { display: none; }

.header-white .logo-white { display: inline-block; }

.header-white .logo-default { display: none; }

.nav-inner { margin: 0 -25px; width: calc(100% + 50px); }

.nav-inner > ul { display: flex; justify-content: space-between; list-style: none; width: 100%; }

.nav-inner > ul > li { margin: 0 25px; position: relative; width: 26%; }

.nav-inner > ul > li:first-child { width: 33%; }

.nav-inner > ul > li:last-child { width: 16%; }

.nav-inner > ul > li:hover .menu-dropdown { display: block; }

.nav-inner > ul > li i { font-size: 50%; display: inline-block; margin-left: 5px; margin-top: 4px; }

.menu-dropdown { max-width: 35rem; }

.menu-dropdown__col { padding: 0 1.5rem; }

.menu-dropdown__title { margin-bottom: .9rem; opacity: 0.5; text-transform: uppercase; }

.menu-dropdown ul { display: block; list-style: none; }

.menu-dropdown li { width: 100%; padding: .4rem 0; margin-bottom: 2.9rem; }

.menu-dropdown ul a { color: inherit; display: inline-flex; line-height: 120%; align-items: center; display: block; vertical-align: top; justify-content: flex-end; transition: color .5s ease, background .5s ease; }

.menu-dropdown ul a span { position: relative; padding-right: 4.3rem; display: inline-block; }

.menu-dropdown ul a span span { display: inline; border-bottom: 1px solid transparent; padding-right: 0; }

.menu-dropdown ul a span span:before { display: none; }

.menu-dropdown ul a span:before { opacity: 0; font-size: 46%; font-family: icomoon; flex-shrink: 0; margin-left: 5px; transition: opacity .5s ease; position: absolute; right: 0; top: 0; }

.menu-dropdown ul a:hover { color: #FFDA22; }

.menu-dropdown ul a:hover span:before { opacity: 1; }

.menu-dropdown ul a:hover span span { border-bottom-color: #FFDA22; }

.menu-dropdown ul a:before { font-family: icomoon; margin-left: 1rem; opacity: 0; font-size: 76%; }

.menu-dropdown ul a li { margin: 0 0 1px; }

.menu-dropdown ul a:hover:before { opacity: 1; }

.menu-dropdown ul .current-menu-item a { font-weight: 700; }

.bars-mob { width: 11.5rem; display: block; cursor: pointer; border-left: 1px solid #808080; position: relative; }

.bars-mob .hamburger { position: absolute; height: 4.4rem; padding: .4rem 0; top: 50%; left: 50%; margin-left: 2.8rem; width: 5.3rem; transform: translate(-50%, -50%); }

.bars-mob .hamburger span { display: block; width: 5.3rem; height: 1px; position: relative; background: #fff; top: 0; left: 0; margin: 0 0 1.6rem; transition: .25s ease-in-out; }

.bars-mob .hamburger span:nth-child(2) { transition-delay: .625s; width: 2.7rem; }

.bars-mob .hamburger span:nth-child(3) { transition-delay: .75s; margin-bottom: 0; }

.ios .bars-mob .hamburger:hover span:nth-child(2) { width: 100%; transition-delay: 0s; }

.ios .bars-mob.active .hamburger:hover span:nth-child(2) { width: 0; }

.bars-mob .cross { position: absolute; height: 4.2rem; width: 4.2rem; margin: 3.2rem 0 0 5.9rem; transform-origin: center center; transform: rotate(45deg); }

.bars-mob .cross span { display: block; background: #fff; transition: .25s ease-in-out; }

.bars-mob .cross span:nth-child(1) { height: 0%; width: 1px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); transition-delay: 0s; }

.bars-mob .cross span:nth-child(2) { width: 0%; height: 1px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); transition-delay: .25s; }

.bars-mob.active .hamburger span { width: 0%; }

.bars-mob.active .hamburger span:nth-child(1) { transition-delay: 0s; }

.bars-mob.active .hamburger span:nth-child(2) { transition-delay: .125s; }

.bars-mob.active .hamburger span:nth-child(3), .bars-mob.active .hamburger:hover span:nth-child(3) { transition-delay: .25s; }

.bars-mob.active .cross span:nth-child(1) { height: 100%; transition-delay: .625s; }

.bars-mob.active .cross span:nth-child(2) { width: 100%; transition-delay: .375s; }

.language-currency { padding: 0 4.6rem; border-left: 1px solid #808080; position: relative; font-weight: 500; z-index: 101; font-size: 2rem; line-height: 1.167; text-transform: uppercase; }

.current-language { font-weight: 500; display: flex; cursor: pointer; align-items: center; color: #fff; height: 100%; position: relative; }

.current-language i { font-size: 43%; margin-left: 1rem; }

.language-currency ul { padding: 0.4rem 0 20px; list-style: none; position: absolute; left: 50%; top: 100%; border: 1px solid #808080; width: 100%; margin-top: -1px; opacity: 0; pointer-events: none; text-align: center; background: #1F1F1F; transform: translate(-50%, 1rem); transition: transform .5s ease, opacity .5s ease; }

.language-currency li { padding: 0.8rem 3.1rem; }

.language-currency a { display: inline-block; border-bottom: 1px solid; transition: border-bottom-color .6s ease; }

.language-currency a:hover { border-bottom-color: transparent; }

.language-currency:hover ul { opacity: 1; transform: translate(-50%, 0); pointer-events: auto; }

.language-currency .curent { display: none; }

.open-header .bars-mob { z-index: 101; }

.open-header .nav-logo { z-index: 101; }

.header-links { display: flex; align-items: center; }

.menu-title { text-transform: uppercase; font-size: 2rem; color: #fff; margin-bottom: 3.4rem; display: block; }

.menu-title a, .menu-title > span { opacity: 0.5; color: inherit; }

.menu-title i { opacity: 0; }

.menu-title a { display: inline-flex; align-items: flex-start; }

.menu-title a:hover { opacity: 1; color: #FFDA22; }

.menu-title a:hover span { opacity: 1; }

.menu-title a:hover i { opacity: 1; }

.projects-count { min-width: 1.6rem; border-radius: 50%; background: #FF5050; color: #fff; font-size: 1.4rem; font-weight: 500; line-height: 1; padding: 1px 3px 0; margin-left: 1.2rem; text-align: center; display: flex; align-items: center; justify-content: center; }

.footers-socials.header-socials { margin-top: 2.8rem; padding: 0; width: 100%; }

.footers-socials.header-socials .socials { justify-content: flex-end; display: flex; }

.footers-socials.header-socials .socials img { max-height: 4.2rem; height: 4.2rem; }

.footers-socials.header-socials .socials__item { margin-left: 4.5rem; margin-right: 0; }

.footers-socials.header-socials .socials__item:first-child { margin-left: 0; }

.header-white .header { background: #fff; border-bottom-color: #B5B5B5; color: #1F1F1F; }

.header-white .bars-mob .hamburger span, .header-white .bars-mob .cross span { background: #1F1F1F; }

.header-white .current-language { color: inherit; }

.header-white .language-currency ul { background: #fff; color: inherit; }

.header-white .menu-box { color: #fff; }

.header-white .search-form-wrap, .header-white .input-search { color: #1F1F1F; }

.header-white .search-form-inner { background: #fff; }

.project-link { display: flex; flex-direction: column; align-items: center; justify-content: center; }

.search-form-wrap { border-bottom: 1px solid transparent; width: 19.2rem; display: flex; color: #fff; justify-content: flex-end; }

.search-form-wrap.opened .search-form-inner { width: 65rem; }

.search-form-wrap.opened .search-form { width: 55rem; }

.search-form-wrap.opened .link-search__text { display: none; }

.search-form-wrap.opened .link-search { width: 4.2rem; }

.search-form-wrap.opened .search-form { width: calc(100% - 4.2rem); }

.search-form-wrap.opened .wrap-search { width: 100%; }

.search-form-wrap.opened .search-form-inner-inner { padding: 0 5rem; }

.search-form-inner { border-left: 1px solid #808080; border-right: 1px solid #808080; background: #1F1F1F; min-width: 100%; flex-shrink: 0; display: flex; transition: width .7s ease; position: relative; }

.search-form-inner-inner { background: rgba(255, 255, 255, 0.05); justify-content: center; display: flex; padding: 0 2rem; transition: padding .7s ease; min-width: 100%; }

.link-search { display: flex; flex-shrink: 0; cursor: pointer; }

.link-search__text { margin-left: .6rem; display: flex; align-items: center; }

.link-search__text span { border-bottom: 1px solid transparent; transition: border-bottom .7s ease; }

.link-search:hover .link-search__text span { border-bottom-color: #fff; }

.search-form { width: 0; overflow: hidden; transition: width .7s ease; display: flex; align-items: center; }

.search-form form { display: flex; align-items: center; width: 100%; }

.search-icon { top: -.2rem; position: relative; width: 3.3rem; display: flex; align-items: center; }

.search-icon i { font-size: 2.5rem; }

.btn-search { border-width: 0; background: transparent; width: 3.3rem; font-size: 1.5rem; color: inherit; width: 8.2rem; height: 6.4rem; transition: background .7s ease, color .7s ease; }

.btn-search:hover { background: #FFDA22; color: #1F1F1F; }

.input-search { width: calc(100% - 8.2rem); border-width: 0; background: transparent; padding: 0; color: #fff; transition: width .7s ease; }

.search-dropdown { position: absolute; left: 0; right: 0; top: 100%; color: #1F1F1F; }

.search-dropdown-inner { background: #fff; margin-top: 10px; }

.search-dropdown .ui-menu { position: static !important; border: 0 !important; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04); padding: 5.5rem 8.5% 40px 11.5%; }

.search-dropdown ul { font-size: 1.6rem; font-weight: 500; list-style: none; width: 100% !important; }

.search-dropdown ul li a { color: #1F1F1F; pointer-events: auto; }

.search-dropdown ul a:not(.btn) { display: inline-block; width: 100%; }

.search-dropdown ul a:not(.btn):hover { color: #FFDA22; }

.search-dropdown li { pointer-events: none; }

.search-dropdown li + li, .search-dropdown .ui-menu .ui-menu-item + .ui-menu .ui-menu-item { margin-top: 2.6rem !important; }

.search-dropdown .ui-menu .ui-menu-item-wrapper { padding: 0 !important; }

.search-dropdown .ui-widget.ui-widget-content { background: transparent !important; }

.search-btn { margin-top: 4rem; }

.btn_search { height: 5rem; line-height: 5rem; font-size: 1.6rem; font-weight: 400; }

.btn_search b { font-weight: 500; }

.btn_search i { width: auto; margin-left: 1rem; }

.btn_search:hover { margin-left: 0; transition: margin-left .5s ease; }

@media screen and (max-width: 1600px) { .menu-box { padding-top: 4rem; } }

@media screen and (max-width: 1599px) { .nav-logo { height: 10rem; display: flex; align-items: center; }
  .menu-box { top: 10rem; } }

@media screen and (max-width: 1280px) { .header__login { padding-left: 5rem; }
  .header__projects { padding-right: 5rem; }
  .language-currency { padding: 0 3.6rem; } }

@media screen and (max-width: 1023px) { .menu-box .wrapper { padding: 0; }
  .nav-logo { padding: 1.1rem 0 0.8rem; height: 4.6rem; }
  .nav-logo img { width: 8.4rem; }
  .menu-box { padding: 0 0 3.5rem; top: 7.1rem; background-size: 84% auto; background-position: 181% 100%; }
  .menu-title { font-size: 1.6rem; margin-bottom: 0; }
  .menu-title a, .menu-title span { opacity: 1; display: flex; align-items: flex-start; justify-content: space-between; }
  .menu-title i { opacity: 1; }
  .nav-inner > ul { width: 100%; }
  .nav-inner > ul > li { border-bottom: 1px solid rgba(181, 181, 181, 0.3); margin: 0; padding: 2.1rem 2.4rem; width: 100%; }
  .nav-inner > ul > li:first-child, .nav-inner > ul > li:last-child { width: 100%; }
  .nav-inner > ul > li a, .nav-inner > ul > li > span > span { color: #fff; padding: 10px 0; display: flex; align-items: center; }
  .nav-inner { margin: 0; width: 100%; }
  .nav-inner > ul { display: block; }
  .nav-inner > ul > li .menu-dropdown .menu-title a, .nav-inner > ul > li .menu-dropdown .menu-title > span { padding: 0; border-bottom-width: 0; opacity: .5; text-align: right; }
  .nav-inner > ul > li .menu-title a { border-bottom-width: 0; }
  .nav-inner > ul > li i { display: inline-block; font-size: 50%; margin: 0 0 0 1rem; padding: 1rem 0; cursor: pointer; }
  .menu-dropdown { margin: 0; position: static; background: transparent; min-width: 0; max-width: 100%; }
  .nav-inner > ul > li:hover .menu-dropdown { display: none; }
  .nav-inner > ul > li.active .menu-title { display: none; }
  .nav-inner > ul > li.active .menu-dropdown { display: block; }
  .nav-inner > ul > li.active .menu-dropdown .menu-title { color: #838EA4; padding: 2.5rem 2.4rem; display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid rgba(181, 181, 181, 0.3); }
  .nav-inner > ul > li.active i { margin-left: 0; }
  .menu-dropdown li { padding: .7rem 2.4rem; margin-bottom: 0; border-bottom: 1px solid rgba(181, 181, 181, 0.3); }
  .menu-dropdown__col { padding: 0; width: 100%; }
  .nav-inner > ul > li .menu-dropdown a { padding: 1rem 0; }
  .header-links-2 .btn-login { color: #fff; }
  .nav-inner > ul > li:last-child a { border-bottom-width: 0; }
  .opened-sub .header-bottom { background: #2E3243; }
  .opened-sub .footers-socials.header-socials, .opened-sub .language-currency { display: none; }
  .opened-sub .nav-inner > ul > li { display: none; background-color: transparent; }
  .opened-sub .nav-inner > ul > li.active { display: block; padding-top: 0; padding-bottom: 0; }
  .opened-sub .nav-inner > ul > li:first-child { border-top-color: transparent; }
  .menu-dropdown-list { display: block; }
  .menu-dropdown { display: none; }
  .menu-dropdown__title { padding: 1.2rem 3rem; font-weight: 700; font-size: 1.4rem; line-height: 1.4rem; border-bottom: 1px solid #515665; color: #515665; }
  .nav-inner > ul > li:hover .menu-title a { color: #fff; }
  .nav-inner > ul > li > .menu-title { -webkit-transition: color .5s ease; -o-transition: color .5s ease; transition: color .5s ease; }
  .nav-inner > ul > li.active > .menu-title { color: #00AEE6; }
  .header__login { padding: 1rem 1rem 1rem 3rem; border-left: 1px solid #808080; order: 1; }
  .bars-mob .hamburger span { width: 3rem; margin: 0 0 .7rem; }
  .bars-mob .hamburger span:nth-child(2) { width: 2rem; }
  .bars-mob.active .hamburger span:nth-child(2) { width: 0; }
  .bars-mob { width: 5.9rem; }
  .bars-mob .hamburger { margin: .7rem 0 0 2rem; }
  .bars-mob .cross { margin: 2.4rem 0 0 3.1rem; height: 2.6rem; width: 2.6rem; }
  .language-currency { margin: 0; padding: 3.1rem 2.4rem 2.5rem; border-width: 0; }
  .language-currency li { display: inline-flex; margin: 0 5rem 0 0; padding: 0.4rem 0.1rem 0.4rem 0; }
  .language-currency li:last-child { margin-right: 0; }
  .language-currency a { opacity: .3; display: inline-flex; align-items: center; justify-content: center; color: #fff; font-size: 2rem; opacity: .3; border-bottom-color: transparent; }
  .language-currency a:hover { opacity: 1; }
  .language-currency ul { position: static; opacity: 1; pointer-events: auto; transform: none; padding: 0; background: none; background: transparent; border-width: 0; }
  .language-currency .curent { color: #fff; opacity: 1; display: inline-block; border-bottom-color: #fff; }
  .language-currency:hover ul { transform: none; }
  .current-language { display: none; }
  .footers-socials.header-socials { margin: 0; border-top: 1px solid rgba(181, 181, 181, 0.3); padding: 2.9rem 2.4rem; width: 100%; }
  .footers-socials.header-socials .socials { justify-content: flex-start; }
  .footers-socials.header-socials .socials__link img { filter: brightness(0) invert(1); max-height: 2.4rem; height: 2.4rem; }
  .footers-socials.header-socials .socials__item { margin-left: 3.5rem; }
  .nav-logo { margin-right: 1.5rem; max-width: 14.7rem; min-height: 7rem; }
  .main-menu { margin: 0; }
  .main-menu a { font-size: 1.8rem; }
  .main-menu li { margin: 0 0 3.6rem; }
  .menu-dropdown ul a { align-items: flex-start; justify-content: flex-start; }
  .menu-dropdown-list span:before { display: none; }
  .menu-dropdown ul a:hover { background: transparent; color: #fff; }
  .header__login span, .header__projects span { display: none; }
  .header__projects .projects-count { display: inline-block; }
  .header__projects { padding: 1rem 1.5rem 1rem 1rem; }
  .header__projects i { margin-right: 0; font-size: 2.9rem; }
  .header__login i { font-size: 2.1rem; }
  .header-white .language-currency { border-width: 0; }
  .header-white .language-currency ul { background: transparent; border-width: 0; text-align: left; }
  .menu-dropdown-2 { margin: 0 -2.4rem; max-width: calc(100% + 4.8rem); }
  .header__login .logined-icon { font-size: 42%; }
  .link-search__text { display: none; }
  .search-form-wrap { order: 2; }
  .header-project-link { order: 3; }
  .bars-mob { order: 4; }
  .search-form-inner { border-right-width: 0; border-left-width: 0; }
  .search-icon { width: auto; }
  .search-icon i { font-size: 2.2rem; }
  .search-form-wrap { width: 7.6rem; padding: 0 1.5rem 0 1rem; }
  .search-form { overflow: auto; position: fixed; left: 0; right: 0; bottom: 0; top: 7.1rem; display: flex; background-color: #1f1f1f; width: 100%; color: #fff; flex-direction: column; justify-content: space-between; transform: translateY(-150%); transition: transform 1s ease; }
  .search-form .menu-title { padding: 2.1rem 2.4rem 2rem 15px; display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid rgba(181, 181, 181, 0.3); }
  .search-form .menu-title i { display: inline-block; font-size: 50%; margin: 0 0 0 1rem; padding: 1rem 0; cursor: pointer; }
  .search-form .menu-title span { opacity: 0.5; text-align: right; }
  .search-form form { padding: 1.9rem 1.9rem 1.9rem 2.4rem; background: rgba(255, 255, 255, 0.15); }
  .search-form .language-currency ul { text-align: left; }
  .search-form .category-title, .search-form .double-code { width: 100%; }
  .search-form-inner-inner { padding: 0; }
  .search-form-wrap.opened .search-form { transform: translateY(0); }
  .search-form-wrap.opened .search-form-inner { width: 5.1rem; }
  .search-form-wrap.opened .search-form-inner-inner { padding: 0; }
  .search-form-wrap.opened .search-form, .search-form-wrap.opened .link-search { width: 100%; }
  .search-form-wrap.opened .link-search { justify-content: center; }
  .input-search { width: calc(100% - 5rem); padding-left: 1.5rem; }
  .search-form [type="text"]:-webkit-autofill, .search-form [type="text"]:-webkit-autofill:hover, .search-form [type="text"]:-webkit-autofill:focus { background: #404040; border-color: #404040; -webkit-text-fill-color: #fff; -webkit-box-shadow: inset 0 0 100px 40px #404040; }
  .search-dropdown { position: static; width: 100%; }
  .search-dropdown .ui-menu { padding: 3.5rem 2.7rem; }
  .search-dropdown-inner { background: rgba(255, 255, 255, 0.2); color: #fff; }
  .search-dropdown li + li { margin-top: 1.9rem; }
  .search-dropdown ul a:not(.btn) { color: #fff; }
  .wrap-search { width: 100%; }
  .header-white .input-search { color: #fff; } }

@media screen and (max-width: 767px) { .header__login .loginned span { display: none; } }

@media screen and (min-width: 1024px) { body:not(.header-white) .search-form [type="text"]:-webkit-autofill, body:not(.header-white) .search-form [type="text"]:-webkit-autofill:hover, body:not(.header-white) .search-form [type="text"]:-webkit-autofill:focus { background: #2a2a2a; border-color: #2a2a2a; -webkit-text-fill-color: #fff; -webkit-box-shadow: inset 0 0 100px 40px #2a2a2a; }
  .header-white .search-form [type="text"]:-webkit-autofill, .header-white .search-form [type="text"]:-webkit-autofill:hover, .header-white .search-form [type="text"]:-webkit-autofill:focus { background: #fff; border-color: #fff; -webkit-text-fill-color: #000; -webkit-box-shadow: inset 0 0 100px 40px #fff; }
  .header-white .input-search::-webkit-input-placeholder { color: #cdcdcd; }
  .header-white .input-search::-moz-placeholder { color: #cdcdcd; }
  .header-white .input-search:-moz-placeholder { color: #cdcdcd; }
  .header-white .input-search:-ms-input-placeholder { color: #cdcdcd; }
  .double-code { display: none; } }

@media screen and (min-width: 1600px) { .header { height: 10rem; }
  .menu-box { top: 10rem; } }

.footer { background: #000; position: relative; z-index: 3; overflow: hidden; flex-shrink: 0; color: #fff; display: flex; flex-direction: column; justify-content: space-between; background: #000; }

.footer .wrapper:after { display: none; }

.footer a { color: inherit; }

.footer .logo img { width: 29.1rem; }

.footer .menu-title { opacity: 1; margin-bottom: 2.4rem; }

.footer .menu-title i { display: none; }

.footer .menu-dropdown li { margin-bottom: 1.9rem; }

.footer .menu-title span { border-bottom: 1px solid transparent; display: inline; }

.footer .menu-title a { display: inline-block; }

.footer .menu-title a:hover span { border-bottom-color: #FFDA22; }

.footer .menu-dropdown ul a span:before { display: none; }

.footer .menu-dropdown ul a span { padding-right: 0; border-bottom: 1px solid transparent; }

.footer .menu-dropdown ul a:hover span { border-bottom-color: #FFDA22; }

.footer .menu-dropdown ul a span:before { display: none; }

.footer-top { padding: 9rem 0 20px; background: #1F1F1F; background-size: auto 100%; background-position: 100% 100%; background-repeat: no-repeat; }

.footer-bottom { padding: 4.5rem 0 4.2rem; font-size: 1.4rem; }

.footer-bottom .wrapper { display: flex; align-items: center; justify-content: space-between; }

.footer-bottom a { color: inherit; }

.footer-bottom a:hover { color: #FFDA22; }

.footers-socials { flex-shrink: 0; width: 25%; }

.footers-socials img { max-height: 2.4rem; transition: filter .6s ease; }

.footers-socials .socials__link:hover img { filter: brightness(0) invert(1); }

.politica { padding: 0 10px 0 0; }

.footer-item { display: flex; margin-bottom: 1.6rem; }

.footer-item i { color: #FFDA22; }

.footer-title { margin-bottom: 16px; }

.footer-title a { font-weight: 600; font-size: 18px; line-height: 21px; font-family: TTFirsNeue, arial, sans-serif; color: #616161; }

.footer-title a:hover { color: #FFDA22; }

.footer-bottom { font-weight: 500; }

.footer-bottom .develop { color: #FFDA22; }

.footers-socials, .link-btm { flex: 1; }

.link-btm { text-align: right; }

@media screen and (max-width: 1023px) { .footer .menu-dropdown { display: block; }
  .footer .nav-inner > ul > li:hover .menu-dropdown { display: block; }
  .footer .menu-title { font-size: 16px; opacity: .5; margin-bottom: 0.4rem; }
  .footer .nav-inner > ul > li { padding: 0; }
  .footer .nav-inner > ul > li i { display: none; }
  .footer .menu-dropdown li { padding: 0; margin-bottom: 0; border-bottom-width: 0; }
  .footer .menu-dropdown-2 { margin: 0; }
  .footer .nav-inner > ul > li { border-bottom-width: 0; margin-bottom: 20px; }
  .link-btm { margin: 2.7rem 0 0; }
  .politica { display: inline-block; }
  .footer-top { padding: 36px 0 0; background-size: 84% auto; background-position: 181% 145%; }
  .footer-bottom .wrapper { flex-wrap: wrap; }
  .copyrights { margin-top: 21px; width: 100%; }
  .footers-socials { width: 100%; margin: 0; padding: 15px 0 15px; }
  .footers-socials .socials__item img { max-height: 3.2rem; }
  .footers-socials .socials__item:last-child { margin-right: 0; } }

@media screen and (max-width: 767px) { .footer-bottom { padding: 1.3rem 0 4.2rem; }
  .footers-socials .socials__item img { max-height: 2.4rem; }
  .socials__item { margin-right: 3.7rem; }
  .copyrights { margin-top: 17px; }
  .link-btm span { display: block; margin-top: .9rem; } }

.tns-outer { padding: 0 !important; }

.tns-outer [hidden] { display: none !important; }

.tns-outer [aria-controls], .tns-outer [data-action] { cursor: pointer; }

.tns-slider { -webkit-transition: all 0s; -moz-transition: all 0s; transition: all 0s; }

.tns-slider > .tns-item { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.tns-horizontal.tns-subpixel { white-space: nowrap; }

.tns-horizontal.tns-subpixel > .tns-item { display: inline-block; vertical-align: top; white-space: normal; }

.tns-horizontal.tns-no-subpixel:after { content: ''; display: table; clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item { float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item { margin-right: -100%; }

.tns-no-calc { position: relative; left: 0; }

.tns-gallery { position: relative; left: 0; min-height: 1px; }

.tns-gallery > .tns-item { position: absolute; left: -100%; -webkit-transition: transform 0s, opacity 0s; -moz-transition: transform 0s, opacity 0s; transition: transform 0s, opacity 0s; }

.tns-gallery > .tns-slide-active { position: relative; left: auto !important; }

.tns-gallery > .tns-moving { -webkit-transition: all 0.25s; -moz-transition: all 0.25s; transition: all 0.25s; }

.tns-autowidth { display: inline-block; }

.tns-lazy-img { -webkit-transition: opacity 0.6s; -moz-transition: opacity 0.6s; transition: opacity 0.6s; opacity: 0.6; }

.tns-lazy-img.tns-complete { opacity: 1; }

.tns-ah { -webkit-transition: height 0s; -moz-transition: height 0s; transition: height 0s; }

.tns-ovh { overflow: hidden; }

.tns-visually-hidden { position: absolute; left: -10000em; }

.tns-transparent { opacity: 0; visibility: hidden; }

.tns-fadeIn { opacity: 1; filter: alpha(opacity=100); z-index: 0; }

.tns-normal, .tns-fadeOut { opacity: 0; filter: alpha(opacity=0); z-index: -1; }

.tns-vpfix { white-space: nowrap; }

.tns-vpfix > div, .tns-vpfix > li { display: inline-block; }

.tns-t-subp2 { margin: 0 auto; width: 310px; position: relative; height: 10px; overflow: hidden; }

.tns-t-ct { width: 2333.3333333%; width: -webkit-calc(100% * 70 / 3); width: -moz-calc(100% * 70 / 3); width: calc(100% * 70 / 3); position: absolute; right: 0; }

.tns-t-ct:after { content: ''; display: table; clear: both; }

.tns-t-ct > div { width: 1.4285714%; width: -webkit-calc(100% / 70); width: -moz-calc(100% / 70); width: calc(100% / 70); height: 10px; float: left; }

.tns-item { display: inline-block; vertical-align: top; white-space: normal; }

.tns-nav { display: none; }

.tns-slider { display: flex; }

.tns-slider .projects__head { pointer-events: none; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; /*height: 100%;*/ min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { vertical-align: top; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow { background: transparent; z-index: 10; transition: color .5s ease,border-color .5s ease; width: 7rem; height: 7rem; padding: 0; font-size: 15px; border: 0; color: #838EA4; z-index: 0; display: flex; align-items: center; justify-content: center; position: relative; z-index: 0; }

.web .slick-arrow:hover { color: #FFDA22; border-color: #FFDA22; position: relative; z-index: 1; }

.slick-next { margin-left: -1px; }

.slick-arrow.slick-hidden { display: none; }

.slick-dots { text-align: center; list-style: none; margin: 66px 100px 0; }

.slick-dots li { display: inline-block; height: 7px; width: 7px; margin-right: 5px; position: relative; padding: 0; cursor: pointer; border: 1px solid transparent; border-radius: 50%; }

.slick-dots button { text-indent: -99999px; font-size: 0; padding: 0; background: #D8D8D8; width: 5px; height: 5px; vertical-align: top; border-radius: 50%; border: 1px solid #D8D8D8; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.slick-dots .slick-active { border: 2px solid #4E3EFE; }

.slick-dots .slick-active button { width: 3px; height: 3px; border-color: #fff; background: #fff; }

.slick-dots .slick-active:after { transform: scale(1); }

.slick-disabled, .slick-disabled:hover { opacity: 0.5; pointer-events: none; }

@media screen and (max-width: 767px) { .slick-arrow { text-align: center; font-size: 1.2rem; height: 5rem; width: 5rem; } }

/* -------------------------------- 

Main Components 

-------------------------------- */
.cd-product-viewer-wrapper { text-align: center; padding: 1.5rem 0; }

.cd-product-viewer-wrapper > div { display: inline-block; }

.cd-product-viewer-wrapper .product-viewer { position: relative; z-index: 1; display: inline-block; overflow: hidden; }

.cd-product-viewer-wrapper img { /* this is the image visible before the image sprite is loaded */ display: block; position: relative; z-index: 1; }

.cd-product-viewer-wrapper.loaded img { opacity: 0; }

.cd-product-viewer-wrapper .product-sprite { position: absolute; z-index: 2; top: 0; left: 0; height: 100%; background-repeat: no-repeat; background-position: center center; background-size: 100%; width: 1600%; opacity: 0; -webkit-transition: opacity 0.3s; -moz-transition: opacity 0.3s; transition: opacity 0.3s; }

.cd-product-viewer-wrapper.loaded .product-sprite { /* image sprite has been loaded */ opacity: 1; cursor: ew-resize; }

.cd-product-viewer-handle { position: relative; z-index: 2; width: 70%; max-width: 300px; border-radius: 50em; margin: 1em auto 3em; height: .4rem; background: #FFDA22; }

.cd-product-viewer-handle .fill { /* this is used to create the loading fill effect */ position: absolute; z-index: 1; left: 0; top: 0; height: 100%; width: 100%; border-radius: inherit; background: #FFDA22; -webkit-transform: scaleX(0); -moz-transform: scaleX(0); -ms-transform: scaleX(0); -o-transform: scaleX(0); transform: scaleX(0); -webkit-transform-origin: left center; -moz-transform-origin: left center; -ms-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center; -webkit-transition: -webkit-transform 0.5s; -moz-transition: -moz-transform 0.5s; transition: transform 0.5s; }

.no-csstransitions .cd-product-viewer-handle .fill { display: none; }

.loaded .cd-product-viewer-handle .fill { /* image sprite has been loaded */ opacity: 0; -webkit-transition: -webkit-transform 0.3s, opacity 0.2s 0.3s; -moz-transition: -moz-transform 0.3s, opacity 0.2s 0.3s; transition: transform 0.3s, opacity 0.2s 0.3s; }

.cd-product-viewer-handle .handle { position: absolute; z-index: 2; display: inline-block; height: 4.4rem; width: 4.4rem; left: 0; top: -2rem; background: #FFDA22 url(../img/cd-arrows.svg) no-repeat center center; border-radius: 50%; box-shadow: 0 0 0 6px rgba(255, 218, 34, 0.3), 0 0 20px rgba(0, 0, 0, 0.2); /* replace text with image */ text-indent: 100%; white-space: nowrap; overflow: hidden; color: transparent; -webkit-transform: translateX(-50%) scale(0); -moz-transform: translateX(-50%) scale(0); -ms-transform: translateX(-50%) scale(0); -o-transform: translateX(-50%) scale(0); transform: translateX(-50%) scale(0); -webkit-transition: box-shadow 0.2s; -moz-transition: box-shadow 0.2s; transition: box-shadow 0.2s; }

.cd-product-viewer-handle .handle:active, .cd-product-viewer-handle .handle:hover { box-shadow: 0 0 0 0 rgba(181, 66, 64, 0), 0 0 20px rgba(0, 0, 0, 0.2); }

.loaded .cd-product-viewer-handle .handle { /* image sprite has been loaded */ -webkit-transform: translateX(-50%) scale(1); -moz-transform: translateX(-50%) scale(1); -ms-transform: translateX(-50%) scale(1); -o-transform: translateX(-50%) scale(1); transform: translateX(-50%) scale(1); -webkit-animation: cd-bounce 0.3s 0.3s; -moz-animation: cd-bounce 0.3s 0.3s; animation: cd-bounce 0.3s 0.3s; -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; animation-fill-mode: both; cursor: ew-resize; }

@-webkit-keyframes cd-bounce { 0% { -webkit-transform: translateX(-50%) scale(0); }
  60% { -webkit-transform: translateX(-50%) scale(1.1); }
  100% { -webkit-transform: translateX(-50%) scale(1); } }

@-moz-keyframes cd-bounce { 0% { -moz-transform: translateX(-50%) scale(0); }
  60% { -moz-transform: translateX(-50%) scale(1.1); }
  100% { -moz-transform: translateX(-50%) scale(1); } }

@keyframes cd-bounce { 0% { -webkit-transform: translateX(-50%) scale(0);
    -moz-transform: translateX(-50%) scale(0);
    -ms-transform: translateX(-50%) scale(0);
    -o-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0); }
  60% { -webkit-transform: translateX(-50%) scale(1.1);
    -moz-transform: translateX(-50%) scale(1.1);
    -ms-transform: translateX(-50%) scale(1.1);
    -o-transform: translateX(-50%) scale(1.1);
    transform: translateX(-50%) scale(1.1); }
  100% { -webkit-transform: translateX(-50%) scale(1);
    -moz-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    -o-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1); } }

.ui-loader { display: none !important; }

@media screen and (max-width: 767px) { .cd-product-viewer-handle .handle { height: 3rem; width: 3rem; top: -1.4rem; background-size: 35px 19px; }
  .cd-product-viewer-wrapper { padding: 0; } }

.section-top { padding-top: 10rem; }

.main-block { margin-top: 14rem; padding-bottom: 2rem; }

.section-text { max-width: 63rem; font-size: 3rem; line-height: 4rem; overflow: hidden; }

.section-text:after { content: ''; display: block; margin-top: 1.5rem; border: 0; height: 0.4rem; width: 9rem; background: #FFDA22; }

.section-text span { display: inline-block; }

.links-cat { margin: 9.4rem -0.4rem 0; display: flex; flex-wrap: wrap; }

.links-cat__item { background: #282828; margin: 0.4rem; width: calc(50% - .8rem); display: flex; align-items: flex-end; position: relative; }

.links-cat__item_big { padding-top: 25.3rem; width: calc(100% - .8rem); }

.links-cat__item:hover { background: var(--bgColor); }

.links-cat__item:hover .links-cat__img { opacity: 0; }

.links-cat__item:hover .links-cat__img_hover { opacity: 1; }

.links-cat__item:hover[style*=fff] .links-cat__title { color: #1F1F1F; }

.links-cat__item:nth-child(1) .links-cat__picture img, .links-cat__item:nth-child(1) .links-cat__picture video, .links-cat__item:nth-child(2) .links-cat__picture img, .links-cat__item:nth-child(2) .links-cat__picture video { width: 100%; height: 100%; object-position: 50% 100%; object-fit: cover; }

.links-cat__img { vertical-align: top; }

.links-cat__picture { position: relative; z-index: 0; width: 100%; text-align: center; }

.links-cat__title { padding: 4.3rem 4.9rem 2rem 4.9rem; font-weight: 500; font-size: 3rem; left: 30px; right: 30px; top: 20px; position: absolute; z-index: 1; color: #fff; display: flex; align-items: center; justify-content: space-between; overflow: hidden; }

.links-cat__title span { display: inline-block; }

.links-cat__title i { margin-left: 20px; flex-shrink: 0; display: inline-block; font-size: 53%; }

.links-cat__img, .links-cat__img_hover { transition: opacity 0.7s; vertical-align: top; object-position: 50% 100%; }

.links-cat__img_hover, .links-cat__img_hover-mob { opacity: 0; position: absolute; left: 50%; bottom: 0; top: 0; transform: translateX(-50%); }

video.links-cat__img_hover { width: 100%; }

@media screen and (max-width: 1366px) { .main-block { margin-top: 9rem; }
  .links-cat__title { padding: 2.3rem 3.9rem 2rem 2.9rem; font-size: 2.3rem; } }

@media screen and (max-width: 1023px) { .section-top { padding-top: 7rem; } }

@media screen and (max-width: 767px) { .section-text { font-size: 2rem; line-height: 2.7rem; }
  .section-text:after { width: 6.3rem; }
  .main-block { margin-top: 3.6rem; }
  .links-cat__item { margin: 0 0 1.6rem; width: 100%; min-height: 18.3rem; }
  .links-cat__item_big { width: 100%; }
  .links-cat { margin: 2.4rem 0 0; }
  .links-cat__item { background: var(--bgColor); }
  .links-cat__item .links-cat__img { opacity: 0; }
  .links-cat__item .links-cat__img_hover, .links-cat__item .links-cat__img_hover-mob { opacity: 1; }
  .links-cat__item .links-cat__img_hover-mob + .links-cat__img_hover { opacity: 0; }
  .links-cat__item:first-child .links-cat__title { color: #1F1F1F; }
  .links-cat__item:nth-child(2) { padding-top: 6rem; }
  .links-cat__item:nth-child(2) .links-cat__picture .links-cat__img_hover-mob { height: auto; width: auto; }
  .links-cat__title { padding: 0; font-size: 1.6rem; left: 2.4rem; right: 2.4rem; top: 2.2rem; }
  .links-cat__item_big { padding-top: 0; } }

.breadcrumbs { margin: 7.3rem 0 5.8rem; }

.breadcrumb li:after { content: '/'; margin: 0 .5rem; }

.section-dark .breadcrumbs { margin: 0 0 5.8rem; }

.breadcrumb { color: rgba(255, 255, 255, 0.7); font-size: 1.6rem; line-height: 2rem; list-style: none; overflow: hidden; text-overflow: ellipsis; width: 100%; white-space: nowrap; }

.breadcrumb li { display: inline-flex; align-items: center; vertical-align: middle; }

.breadcrumb li:first-child { font-size: 2.4rem; }

.breadcrumb li:first-child a { color: #FFDA22; }

.breadcrumb li:first-child a:hover { color: #fff; }

.breadcrumb li:last-child { color: #fff; }

.breadcrumb li:last-child:after { display: none; }

.breadcrumb i { line-height: inherit; }

.breadcrumb a { color: inherit; }

.breadcrumb a:hover { color: #FFDA22; opacity: 1; }

.header-white .breadcrumb { color: #B5B5B5; }

.header-white .breadcrumb li:last-child { color: #1F1F1F; }

.header-white .breadcrumb li:first-child a:hover { color: #1F1F1F; }

.section-subtext { font-weight: 700; }

.categories { margin: 10rem -1.7rem 0; display: flex; flex-wrap: wrap; }

.categories__item { width: calc(50% - 3.4rem); margin: 2.6rem 1.7rem; border: 1px solid #B5B5B5; background: #FCFAF1; position: relative; text-decoration: none; color: #1F1F1F; }

.categories__item:hover { border-color: #FFDA22; }

.categories__item:hover .categories__title span { border-bottom-color: #1F1F1F; }

.categories__item:hover .btn i { margin-left: 5.8rem; }

.categories__item_1 .categories__img { width: 75%; bottom: 23%; }

.categories__item_1 .categories__img img { left: auto; top: 0; right: 0; transform: none; }

.categories__head { padding: 7.8rem 0 0; position: relative; min-height: 48rem; display: flex; flex-direction: column; justify-content: space-between; z-index: 1; overflow: hidden; }

.categories__title { max-width: 50%; padding: 0 0 0 6rem; font-size: 40px; line-height: 1.25; font-weight: 500; z-index: 1; position: relative; }

.categories__title div { padding-bottom: 1px; }

.categories__title span { border-bottom: 1px solid transparent; transition: border-bottom-color .6s ease; }

.categories__img { width: 62%; position: absolute; right: 0; top: 0; bottom: 0; z-index: 0; display: flex; align-items: center; }

.categories__img img { position: absolute; max-height: 100%; max-width: 100%; left: 50%; top: 50%; position: absolute; display: inline-block; transform: translate(-50%, -50%); vertical-align: top; }

.categories_2 .categories__item { background: transparent; }

.categories__item_down .categories__img { width: 74%; }

.categories__item_down .categories__img img { transform: translate(-50%, 0); top: auto; bottom: 0; }

.section-head { padding: 7.5rem 0 40px; min-height: 61.5rem; display: flex; }

.section-head__title { width: 50%; padding-right: 10%; }

.section-head__decor { width: 50%; text-align: center; }

.section-head__decor svg { max-width: 100%; height: auto; }

.section-head .h1 { max-width: 100%; }

.section-head_2 { padding-bottom: 0; min-height: 45.4rem; }

.section-head_2 .section-head__decor { display: flex; align-items: flex-end; justify-content: flex-end; }

.section-head__title-contacts { width: 47rem; }

.section-head__contacts { width: calc(100% - 47rem); justify-content: flex-end; }

.section-dark .breadcrumb li:not(:first-child) a { opacity: .7; }

.section-top-product { position: relative; }

.section-top-product .product-img { padding-top: 10rem; background: none; align-items: flex-end; justify-content: flex-end; }

.section-top-product .product-img img { vertical-align: top; max-height: 100%; }

.section-subcategoies { padding: 10rem 0 10rem; }

.subcategories { margin-bottom: 40px; }

.subcategories__item { margin: 2.6rem 0 7.9rem; border: 1px solid #B5B5B5; position: relative; text-decoration: none; color: #1F1F1F; display: flex; flex-wrap: wrap; transition: border-color .6s ease; }

.subcategories__item_reverse { flex-direction: row-reverse; }

.subcategories__item_reverse .subcategories__btn { text-align: right; }

.subcategories__item:hover { border-color: #FFDA22; }

.subcategories__item:hover .subcategories__title span { border-bottom-color: #1F1F1F; }

.subcategories__item:hover .btn i { margin-left: 5.8rem; }

.subcategories__head { width: 57%; min-height: 48rem; display: flex; flex-direction: column; justify-content: space-between; z-index: 1; }

.subcategories__title { font-size: 4rem; line-height: 1.2; font-weight: 500; z-index: 1; overflow: hidden; padding-bottom: 3px; }

.subcategories__title span { border-bottom: 1px solid transparent; transition: border-bottom-color .6s ease; }

.subcategories__img { width: 43%; display: flex; align-items: center; justify-content: center; min-height: 63.1rem; }

.subcategories__img img { max-height: 60rem; }

.subcategories__category { color: #B5B5B5; font-size: 16px; font-weight: 500; margin-bottom: 10px; overflow: hidden; text-transform: uppercase; }

.subcategories__text { margin-top: 15px; line-height: 1.5; }

.subcategories__description { padding: 6.2rem 14%; display: flex; flex-direction: column; justify-content: center; flex-grow: 10; }

.subcategories__btn { margin-top: 25px; }

.section-product-head { position: relative; }

.section-product-head .wrapper { z-index: 1; }

.product-head { width: 50%; padding: 4rem 0 4rem; }

.product-head__title { margin-top: 10rem; }

.product-head .h1 { max-width: 100%; }

.tovar-info { margin-top: 2.8rem; font-weight: 300; }

.tovar-info__val { font-weight: 700; }

.product-img { width: 49%; position: absolute; right: 0; top: 0; bottom: 0; z-index: 1; display: flex; align-items: center; justify-content: center; }

.product-img img { max-height: 100%; }

.product-img_3d { background: transparent; }

.btn_add-project { margin-top: 5.4rem; min-width: 40.4rem; max-width: 100%; padding: 0 1.9rem; }

.btn_add-project:hover i { margin-left: 3.8rem; }

.section-product-about { padding: 8.4rem 0 8.8rem; }

.product-about { display: flex; flex-wrap: wrap; border-top: 1px solid #B5B5B5; }

.menu-navs { width: 23.5%; padding-top: 4.9rem; border-right: 1px solid #B5B5B5; padding-right: 4rem; font-size: 2.4rem; }

.menu-navs a { padding: 1rem 0; margin-bottom: 1.8rem; display: block; color: #B5B5B5; }

.menu-navs a:hover { color: #FFDA22; }

.menu-navs a.active { pointer-events: none; color: #1F1F1F; }

.menu-navs a:last-child { margin-bottom: 0; }

.product-about-tabs { width: 76.5%; padding-left: 4.5%; padding-top: 4.5rem; line-height: 1.5; }

.product-about-tabs ul { list-style: none outside; }

.product-about-tabs ul li { position: relative; padding-left: 17px; }

.product-about-tabs ul li:before { content: ''; display: block; background: #1F1F1F; width: 3px; height: 3px; border-radius: 50%; position: absolute; left: 0; top: 1.2rem; }

.product-about-tabs ul, .product-about-tabs ol { margin-left: 17px; }

.product-about-tabs li { margin-bottom: 10px; }

.section-my-project { padding-top: 40px; }

.cart-wrap { max-width: 142.8rem; margin: 0 auto; }

.cart { font-size: 1.6rem; border: 1px solid #B5B5B5; }

.cart__item { border-bottom: 1px solid #B5B5B5; display: flex; }

.cart__img { background: #FCFAF1; width: 17%; display: flex; align-items: center; justify-content: center; }

.cart__img img { max-height: 16.3rem; }

.cart__details { width: 83%; padding: 2.8rem 3.5rem; display: flex; justify-content: space-between; }

.cart__remove { display: inline-flex; align-items: center; font-size: 1.6rem; font-weight: 500; cursor: pointer; padding: 9px 19px; border: 1px solid #1F1F1F; transition: background .6s ease; }

.cart__remove i { margin-right: 15px; }

.cart__remove:hover { background: #FFDA22; }

.cart__head { padding-right: 3rem; width: 46%; }

.cart__title { color: #1F1F1F; font-size: 1.8rem; font-weight: 500; }

.cart__number { margin-top: 10px; }

.cart__details-list { margin-top: 15px; }

.cart__action { width: 20%; text-align: right; }

.cart__detail { display: flex; }

.cart__detail + .cart__detail { margin-top: 10px; }

.cart__detail-label { margin-right: 1rem; }

.cart__date { padding-right: 4rem; width: 34%; }

.cart__date-value { margin-top: 5px; color: #808080; }

.cart__status { padding: .9rem 1.8rem; font-size: 1.6rem; background: #B5B5B5; border-radius: .8rem; flex-shrink: 0; white-space: nowrap; display: inline-block; }

.cart__status_send { background: #FFDA22; border-radius: 8px; }

.cart__status_order { background: #3AC88C; }

.cart__status_closed { background: #3ab0c8; }

.cart__status_done { background: #51c83a; }

.cart-date { color: #808080; font-size: 1.6rem; margin-bottom: 15px; }

.cart-btn { margin-top: 40px; }

.cart-remove-alert { display: none; margin-bottom: 3.6rem; }

.alert-block { background: #3AC88C; padding: 1.8rem 2.9rem; font-size: 1.6rem; display: flex; align-items: center; }

.alert-block i { margin-right: 15px; }

.alert-block_warning { background: #FF5050; color: #fff; }

.alert-block_error { background: #FCF7B6; }

.cart-wrap-order { display: flex; }

.cart-wrap-order .cart-wrap { width: 60%; margin-left: 5%; }

.cart-order { width: 35%; }

.cart-order .btn { width: 100%; }

.cart-order-done { display: flex; }

.cart-order-done__icon { width: 40%; padding-left: 5%; display: flex; align-items: center; justify-content: center; }

.cart-order-done__icon svg { height: auto; max-width: 100%; }

.cart-order-done__alert { width: 60%; }

.cart-order-done__alert .btn { width: 40.4rem; max-width: 100%; }

.cart-order-done__alert-text a { color: #1F1F1F; border-bottom: 1px solid; }

.cart-order-done__alert-text a:hover { border-bottom-color: transparent; }

.cart-order-done__btn { margin-top: 35px; }

.section-my-project_done { padding-bottom: 50px; }

.section-my-account { padding: 15.3rem 0 13.5rem; }

.my-account { margin-top: 12.9rem; display: flex; flex-wrap: wrap; border-top: 1px solid #B5B5B5; }

.my-account-tabs { padding: 6.2rem 0 0 4.5%; border-left: 1px solid #B5B5B5; margin-left: -1px; width: 76.5%; line-height: 1.5; }

.my-account-tabs .cart { margin-bottom: 37px; padding-bottom: 0; border-bottom: 1px solid #B5B5B5; }

.my-account-tabs .cart:last-child { padding-bottom: 0; }

.my-account-tabs .alert-block { margin-bottom: 30px; }

.my-account-tabs .box-field__label { margin-bottom: 1.3rem; }

.my-account-form-password-wrap { margin-top: 0; border-top: 1px solid #B5B5B5; padding-top: 3rem; }

.show-password { position: absolute; font-size: 24px; color: #000; right: 5px; display: inline-block; top: 50%; cursor: pointer; padding: 5px; transform: translateY(-50%); z-index: 1; transition: color 0.3s ease; }

.box-field__input { position: relative; }

.my-account-form { margin: 0 -1.8rem; display: flex; flex-wrap: wrap; }

.my-account-form .box-field { padding: 0 1.8rem; width: 50%; }

.my-account-form .box-field__label { color: #808080; }

.my-account-form-password { margin: 0 -1.8rem; display: flex; flex-wrap: wrap; }

.my-account-form-password .box-field { padding: 0 1.8rem; width: 33.333%; }

.my-account-form-sbmit { margin-top: 25px; }

.btn-submit-my { width: 36rem; max-width: 100%; }

.my-account-form-password-title { margin-bottom: 30px; font-size: 3.2rem; font-weight: 500; }

.box-field__hidden { display: none; }

.box-field__hidden.shown-input { display: flex; align-items: center; }

.box-field__hidden input { width: 100%; height: 6.4rem; }

.box-field__hidden .jq-selectbox__select { height: 6.4rem; padding-top: 2rem; }

.box-field__hidden .jq-selectbox__trigger-arrow { top: 2rem; }

.shown-input .js-info-value { width: 100%; }

.accept-change { background: #FFDA22; width: 8.2rem; height: 6.4rem; margin-left: 1.6rem; display: flex; align-items: center; justify-content: center; cursor: pointer; }

.accept-change:hover { font-size: 87%; }

.box-field__info { display: flex; align-items: center; }

.change-info { flex-shrink: 0; margin-left: 15px; cursor: pointer; transition: color .5s ease; }

.change-info:hover { color: #808080; }

.login .alert-block { margin-bottom: 40px; }

.login-form { margin: 0 -1.8rem; display: flex; flex-wrap: wrap; }

.login-form .box-field { width: 100%; padding: 0 1.8rem; }

.login-form .box-field_half { width: 50%; }

.back-arrow { margin-bottom: 40px; }

.back-arrow__link { color: #1F1F1F; display: inline-flex; align-items: center; }

.back-arrow__link i { margin-right: 10px; font-size: 55%; transition: margin-right .5s ease; }

.back-arrow__link:hover i { margin-right: 25px; }

.section-product-about-2 { background: #FCFAF1; padding: 8.5rem 0 10.8rem; }

.product-about-2 { display: flex; flex-wrap: wrap; }

.product-about-2__left { padding-right: 8%; width: 50%; }

.product-about-2__right { padding-left: 2rem; width: 50%; }

.product-about-2__label { font-size: 4rem; font-weight: 500; line-height: 1.275; margin-bottom: 1.1rem; overflow: hidden; }

.product-about-2__value p:first-child { padding-top: 0; }

.product-about-2__value p:last-child { padding-bottom: 0; }

.product-about-2 ul { margin-top: .7rem; list-style: none outside; }

.product-about-2 ul li { position: relative; padding-left: 17px; }

.product-about-2 ul li:before { content: ''; display: block; background: #1F1F1F; width: 3px; height: 3px; border-radius: 50%; position: absolute; left: 0; top: 1.2rem; }

.product-about-2 ul, .product-about-2 ol { margin-left: 17px; }

.product-about-2 li { margin-bottom: 1.2rem; }

.product-about-2__item { max-width: 74rem; }

.product-about-2__item + .product-about-2__item { margin-top: 5.7rem; }

.package-service__btn { margin-top: 4rem; }

.section-blog { padding: 2rem 0 50px; }

.projects-nav { margin-bottom: 5rem; }

.projects-nav ul { list-style: none; display: flex; align-items: center; flex-wrap: wrap; }

.projects-nav li { margin: 0 2.4rem 1.5rem 0; }

.projects-nav li:last-child { margin-right: 0; }

.projects-nav a { display: inline-block; padding: .9rem 1.9rem; background: #E3E3E3; color: #1F1F1F; transition: color .5s ease, background .5s ease; }

.projects-nav a.active, .projects-nav a:hover { color: #fff; background: #1F1F1F; }

.blog-card a { color: #1F1F1F; }

.blog-card__content { color: inherit; }

.blog-card__info { display: flex; align-items: center; flex-wrap: wrap; margin-bottom: 2.8rem; }

.blog-card__date { color: #B5B5B5; font-size: 1.6rem; line-height: 1.2; border-right: 1px solid #FFDA22; padding-right: 3.6rem; }

.blog-card__category { color: #B5B5B5; font-size: 1.6rem; line-height: 1.2; text-transform: uppercase; border-right: 1px solid #FFDA22; padding-right: 1.6rem; padding-left: 1.6rem; }

.blog-card__title { font-weight: 500; font-size: 2.4rem; line-height: 1.2; padding-bottom: 1px; }

.blog-card__title a { border-bottom: 1px solid transparent; transition: border-bottom-color .5s ease; }

.blog-card__img { min-height: 11rem; }

.blog-card__img img { width: 100%; vertical-align: top; border-radius: 1.6rem 1.6rem 0px 0px; }

.blog-card__details { height: 6rem; font-size: 2rem; padding: 0 1.9rem; position: absolute; z-index: 1; background: #1F1F1F; color: #fff; display: flex; align-items: center; justify-content: center; right: 0; bottom: 0; width: max-content; overflow: hidden; transition: background .5s linear, color .5s linear; }

.blog-card__details i { font-size: 40%; margin-left: .6rem; transition: margin-left .5s linear; }

.blog-card__top { display: block; margin-bottom: 2.4rem; position: relative; }

.blog-card:hover .blog-card__title a { border-bottom-color: #1F1F1F; }

.blog-card:hover .blog-card__details { background: #FFDA22; color: #1F1F1F; }

.blog-card:hover .blog-card__details i { margin-left: 2.6rem; }

.blog-list { margin: 0 -1.8rem; display: flex; flex-wrap: wrap; }

.blog-list .blog-card { padding: 0 1.8rem; margin: 0 0 5rem; width: 33.333%; }

.article-info { margin-bottom: 30px; display: flex; align-items: center; flex-wrap: wrap; overflow: hidden; }

.article-info__info { display: flex; align-items: center; flex-wrap: wrap; margin-bottom: 15px; }

.article-info__date { color: #B5B5B5; font-size: 1.6rem; line-height: 1.2; border-right: 1px solid #FFDA22; padding-right: 3.6rem; }

.article-info__category { color: #B5B5B5; font-size: 1.6rem; line-height: 1.2; text-transform: uppercase; border-right: 1px solid #FFDA22; padding-right: 1.6rem; padding-left: 1.6rem; }

.block-img-3 { padding: 6.2rem 0 3rem; margin: 0 -2.5rem; display: flex; }

.block-img-3 figure { padding: 0 2.5rem; width: 50%; text-align: center; }

.block-img-3 h2 { margin-top: 0; }

figcaption { font-size: 1.6rem; text-align: center; margin-top: 1.3rem; }

.block-img-4 { padding: 5.2rem 0 3rem; margin: 0 -2.5rem; line-height: 1.5; display: flex; }

.block-img-4__img { padding: 0 2.5rem; width: 41%; }

.block-img-4__picture { display: inline-block; overflow: hidden; background: #1F1F1F; }

.block-img-4__picture img { vertical-align: top; }

.block-img-4__description { padding: 0 2.5rem; width: 59%; }

.block-img-4__description p:first-child { padding-top: 0; }

.block-img-4__description p:last-child { padding-bottom: 0; }

.block-img-4 h2 { margin-top: 0; }

.block-img-4 img { border-radius: .4rem; }

.block-img-4__block { padding-right: 12%; }

.block-img-4__block img { margin-bottom: 2.9rem; }

.block-img-4_reverse { flex-direction: row-reverse; }

.block-img-4_reverse .block-img-4__block { padding-right: 0; padding-left: 12%; }

.block-img-4 .info-block { margin-top: 4.6rem; line-height: 1.3; }

.article-wrap-slider { padding: 3.5rem 0 2rem; }

.article-inner-slider { margin: 0 -1.8rem; }

.article-inner-slider__item { text-align: center; padding: 0 1.8rem 1px; }

.article-inner-slider-navs { margin-top: 3.2rem; display: flex; align-items: center; justify-content: center; }

.article-inner-slider-navs .slick-prev { margin-right: 15px; }

.article-inner-slider-navs .slick-next { margin-left: 15px; }

.article { width: 113.5rem; max-width: 100%; margin: -2.5rem auto 0; line-height: 1.4; }

.article h1 { font-size: 4rem; margin-bottom: 5.8rem; }

.article ul { list-style: none outside; }

.article ol, .article ul { margin: .2rem 0 0 1.2rem; }

.article ol li, .article ul li { margin-bottom: 1.4rem; }

.article ul li { position: relative; padding-left: 17px; }

.article ul li:before { content: ''; display: block; background: #1F1F1F; width: 3px; height: 3px; border-radius: 50%; position: absolute; left: 0; top: 1.2rem; }

.article blockquote { margin: 3rem 0 3.5rem; font-size: 2.4rem; padding: 3.4rem 4rem 4rem 5.7rem; background: #FCFAF1; border-left: 1.2rem solid #FFDA22; }

.article picture { margin: 3rem 0 5.7rem; display: block; }

.article table { width: 100%; table-layout: fixed; text-align: center; }

.article .table-wrap { margin: 7.5rem 0 3.5rem; overflow: auto; }

.article th { font-weight: 500; vertical-align: middle; }

.article tr:first-child th { background: #FCFAF1; }

.article th, .article td { border-right: 1px dashed #CDCDCD; padding: 1.7rem 1.7rem 1.7rem; vertical-align: middle; }

.article th:last-child, .article td:last-child { border-right-width: 0; }

.article td { padding: 1.7rem 1.7rem 1.7rem; }

.article tr:nth-child(odd) td { background: #FCFAF1; }

.article .video_wrap { margin: 8rem 0 6.8rem; }

body:not(.content-loaded) .js-anim-decor { opacity: 0; }

.styled option:first-child { display: none; }

.content-loaded .js-anim-decor path, .content-loaded .js-anim-decor circle, .content-loaded .js-anim-decor line, .content-loaded .js-anim-decor rect { stroke-dashoffset: 0; transition: stroke-dashoffset 3.5s .2s; }

.back-arrow { margin: 2.2rem 0 0; color: #1F1F1F; display: inline-flex; align-items: center; }

.back-arrow i { font-size: 75%; margin-right: 2.1rem; overflow: hidden; transition: margin-right .5s ease; display: inline-block; }

.back-arrow:hover i { margin-right: 3.8rem; }

.section-career-list { padding: 8.4rem 0 14.3rem; }

.faq { border-bottom: 1px solid #B5B5B5; }

.faq__title { padding: 4.8rem 0 4.7rem; font-size: 3.2rem; font-weight: 500; display: flex; justify-content: space-between; align-items: center; cursor: pointer; }

.faq__icon { padding: 15px 0 15px 15px; flex-shrink: 0; font-size: 50%; }

.faq__body { display: none; padding-bottom: 3.5rem; }

.faq.active .faq__icon { transform: scale(200%); transform-origin: 82% 42%; }

.faq.active .faq__title { padding-bottom: 3.7rem; border-bottom-color: transparent; }

.section-included { padding: 14.4rem 0 40px; }

.section-included__text { font-weight: 700; margin-bottom: 40px; overflow: hidden; }

.section-included .h2 { max-width: 68rem; }

.included { margin: 7.5rem -40px 0; display: flex; flex-wrap: wrap; }

.included__item { width: 33.333%; padding: 0 40px; border-right: 1px solid #B5B5B5; }

.included__item:nth-child(2) .included__img, .included__item:nth-child(2) .included__title { margin-left: auto; margin-right: auto; }

.included__item:last-child { border-right-width: 0; }

.included__item:last-child .included__img, .included__item:last-child .included__title { margin-left: auto; }

.included__img { background: #1F1F1F; overflow: hidden; margin-bottom: 3.8rem; max-width: 40.4rem; }

.included__img img { width: 100%; vertical-align: top; }

.included__title { overflow: hidden; max-width: 40.4rem; line-height: 1.5; }

.included__title b, .included__title strong { font-weight: 600; }

.section-head__btn-send { margin-top: 5.2rem; overflow: hidden; }

.btn-send { min-width: 25.8rem; }

.section-head_contacts { padding-bottom: 22.5rem; flex-wrap: wrap; min-height: 0; }

.section-head_contacts .breadcrumbs { width: 100%; }

.contact-items { font-size: 2.4rem; padding-top: .9rem; font-weight: 500; display: flex; justify-content: flex-end; }

.contact-item { margin-left: 7.5%; display: flex; line-height: 135%; opacity: 0; transform: translateY(5rem); }

.contact-item a { color: inherit; }

.contact-item__title + .contact-item__title { margin-top: 1.4rem; }

.content-loaded .contact-item { opacity: 1; transform: translateY(0); transition: transform 0.9s ease .1s, opacity 0.9s ease .1s; }

.content-loaded .contact-item:nth-child(2) { transition-delay: .2s; }

.content-loaded .contact-item:nth-child(3) { transition-delay: .3s; }

.contact-icon { color: #FFDA22; flex-shrink: 0; margin-right: 4rem; font-size: 142%; }

.section-contacts { background: #FCFAF1; }

.contacts-main { display: flex; align-items: flex-start; justify-content: center; }

.contacts-list { margin-top: -11.3rem; padding: 1.6rem 4rem 6rem 5.2rem; background: #fff; width: 40%; }

.contacts-list .faq { border-bottom: 1px solid #B5B5B5; }

.contacts-list .faq__title { padding: 2.8rem 0 2.8rem; font-size: 2.4rem; border-bottom-width: 0; }

.contacts-list .faq__icon { font-size: 60%; }

.contacts-map { margin-top: -11.3rem; padding-left: 40px; width: 60%; }

.map { width: 100%; height: 59.3rem; }

.hero-image { overflow: hidden; background: #1F1F1F; border-radius: 1.6rem; }

.hero-image img { vertical-align: top; border-radius: 1.6rem; }

.product-img__slider { background: none; }

.product-slider .slick-track { display: flex; }

.product-slider .slick-slide { display: flex !important; flex-direction: column; }

.product-slider .slick-slide > *, .product-slider .slick-slide > * > * { display: flex; flex-direction: column; flex: 1 1 100%; }

.product-slider .slick-slide > * > * { display: flex !important; align-items: center; justify-content: center; background: #FCFAF1; }

.product-slider:not(.slick-initialized) { display: flex; }

.error-page { padding-bottom: 6rem; display: flex; flex-wrap: wrap; }

.error-page__head { width: 41%; }

.error-page__img { width: 59%; padding-left: 4rem; margin-top: -9rem; }

.error-page .h1 { line-height: 1.05; max-width: 100%; }

.text-error { line-height: 1.5; margin-top: 5.1rem; }

.btn-error { margin-top: 5.3rem; }

.box-captcha .form-group { display: flex; align-items: center; flex-shrink: 0; position: relative; }

.box-captcha input { flex-grow: 10; }

.box-captcha img { margin-right: 3rem; border: 1px solid #B5B5B5; }

.box-captcha .help-block { position: absolute; left: 0; right: 0; top: 100%; }

.product-slider:not(.slick-initialized) { opacity: 0; }

.more-list { display: none; }

.section-search { padding: 7.7rem 0 13.3rem; }

.highlight { background: #FFF685; }

.h2_search { font-weight: 400; color: #808080; font-size: 1.6rem; }

.search-list__item { display: flex; }

.search-list__item + .search-list__item { margin-top: 3.6rem; }

.search-list__item:hover { color: #1F1F1F; }

.search-list__item:hover .search-list__title { color: #5F01D2; }

.search-list__img { width: 16.6rem; height: 16.6rem; flex-shrink: 0; margin-right: 2.4rem; }

.search-list__img img { vertical-align: top; height: 100%; width: 100%; object-fit: cover; object-position: 50% 50%; vertical-align: top; }

.search-list__title { font-size: 2.4rem; font-weight: 500; transition: color .6s ease; }

.search-list__text { margin-top: 2.7rem; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; line-height: 150%; }

.search-list a { color: inherit; }

.search-list .highlight { color: inherit; }

.search-list + .box-paging { margin-top: 6.2rem; }

@media screen and (max-width: 1599px) { .categories__head { padding: 6rem 0 0; }
  .categories__title { font-size: 3.1rem; max-width: 52%; padding: 0 0 0 4.4rem; }
  .contact-items { font-size: 2.2rem; }
  .contact-item { margin-left: 7%; }
  .contact-icon { margin-right: 2.8rem; }
  .section-contacts { padding-bottom: 12rem; }
  .section-my-account { padding: 11.3rem 0 10.5rem; }
  .my-account { margin-top: 7.9rem; }
  .error-page__img { margin-top: -7rem; } }

@media screen and (max-width: 1439px) { .categories__title { font-size: 2.7rem; }
  .back-arrow { margin-top: 0; }
  .back-arrow i { font-size: 64%; margin-right: 1.6rem; }
  .article { margin-top: 3.8rem; }
  .section-subcategoies { padding: 7rem 0 7rem; } }

@media screen and (max-width: 1366px) { .categories__head { padding: 5.8rem 0 0; }
  .categories__title { font-size: 2.5rem; max-width: 47%; padding: 0 0 0 3.1rem; }
  .subcategories__description { padding: 30px 4.4rem; }
  .menu-navs { font-size: 2rem; }
  .block-img-4__block { padding-right: 7%; }
  .block-img-4_reverse .block-img-4__block { padding-right: 0; padding-left: 7%; }
  .info-block { font-size: 2.6rem; }
  .contacts-list .faq__title { font-size: 2.2rem; padding: 2.1rem 0; }
  .categories { margin-top: 7rem; }
  .categories__head { padding: 6.8rem 0 0; min-height: 39rem; }
  .section-career-list { padding: 6.4rem 0 9.3rem; }
  .career-list__aside { padding-top: 3.5rem; }
  .faq__title { padding: 3.8rem 0 3.7rem; }
  .section-included { padding: 9.4rem 0 3rem; }
  .categories_2 .categories__head { min-height: 30rem; } }

@media screen and (max-width: 1365px) { .section-head_contacts { padding-bottom: 18.5rem; }
  .contact-items { display: block; }
  .contact-item { margin: 0 0 3.5rem; }
  .contacts-list { padding: 1.6rem 4rem 5rem 4.2rem; width: 45%; }
  .contacts-map { width: 55%; } }

@media screen and (max-width: 1280px) { .menu-navs { font-size: 1.8rem; }
  .cart-wrap-order { display: block; }
  .cart-wrap-order .cart-wrap { width: 100%; margin: 4rem 0 0; }
  .cart-order { width: 100%; }
  .blog-card__title { font-size: 2rem; }
  .cart__details { flex-wrap: wrap; }
  .cart__head { width: 66.66%; }
  .cart__date { width: 33.33%; padding-right: 0; }
  .cart__action { margin-top: 2.5rem; width: 100%; }
  .faq__title { font-size: 2.8rem; }
  .contact-items { font-size: 2rem; } }

@media screen and (max-width: 1023px) { .breadcrumbs { margin: 4.3rem 0 3.8rem; }
  .categories__title { font-size: 2rem; padding: 0 0 0 2.3rem; }
  .categories__head { padding: 3.4rem 0 0; }
  .cart__details { flex-wrap: wrap; }
  .cart__action { margin-top: 2.5rem; width: 100%; }
  .my-account-form-password .box-field, .my-account-form .box-field { width: 100%; }
  .blog-list .blog-card { width: 50%; }
  .included__item { width: 50%; margin-bottom: 4rem; }
  .included__item:last-child { border-right-width: 0; }
  .faq__title { font-size: 2.4rem; }
  .section-head_contacts { padding-bottom: 13.5rem; }
  .contacts-main { display: block; position: relative; top: -8rem; }
  .contacts-list { width: 100%; margin-top: 0; }
  .contacts-map { margin: 1.6rem 0 0; width: 100%; padding: 0; }
  .block-img-4 { display: block; padding: 1.5rem 0; margin: 0; }
  .block-img-4__description { padding: 0 0 .5rem; width: 100%; }
  .block-img-4 .block-img-4__img { margin-top: 30px; padding: 0; width: 100%; }
  .block-img-4 .block-img-4__block { padding-right: 0; }
  .block-img-4_reverse { display: flex; flex-direction: column-reverse; }
  .block-img-4_reverse .block-img-4__block { padding-right: 0; }
  .block-img-4_reverse .block-img-4__img { margin-top: .9rem; }
  .block-img-4_reverse .block-img-4__description { margin-top: 1rem; }
  .blog-card__title { font-size: 1.8rem; }
  .blog-card__details { height: 4rem; font-size: 1.6rem; }
  .projects-nav { margin-bottom: 2.8rem; }
  .blog-list .blog-card { margin: 0 0 4.4rem; }
  .blog-card__details i { margin-left: 1.6rem; }
  .blog-card__top { margin-bottom: 1.8rem; }
  .blog-card__info { margin-bottom: 1.8rem; }
  .product-head .h1 { font-size: 3.8rem; }
  .product-img { width: 47%; }
  .product-about-tabs { padding-left: 3.5%; }
  .error-page__img { margin-top: -1rem; }
  .text-error { margin-top: 3.1rem; }
  .btn-error { margin-top: 3.2rem; }
  .search-list__title { font-size: 2rem; } }

@media screen and (max-width: 767px) { .btn_add-project { min-width: 0; width: 100%; }
  .categories__title { font-size: 1.8rem; max-width: 50%; padding: 0 0 0 2.3rem; }
  .categories__img, .categories__item_down .categories__img { width: 44%; }
  .categories_2 .categories__head { min-height: 24rem; }
  .section-head { min-height: 0; padding-top: 2.5rem; display: block; }
  .section-head__title { width: 100%; padding-right: 0; }
  .section-head__decor { width: 100%; }
  .subcategories__item { flex-direction: column-reverse; }
  .subcategories__head { width: 100%; min-height: 0; }
  .subcategories__item { margin: 2.6rem 0 2.4rem; }
  .subcategories__description { padding: 2.7rem 2.4rem 1.4rem; }
  .subcategories__img { width: 100%; height: 25.3rem; min-height: 25.3rem; }
  .subcategories__img img { max-height: 95%; }
  .subcategories__title { font-size: 1.8rem; }
  .subcategories__text { font-size: 1.6rem; line-height: 130%; }
  .subcategories__btn { margin-top: 0; text-align: left; }
  .section-product-head { padding: 0 0 30px; }
  .product-img { position: static; margin-top: 1.1rem; width: 100%; padding: 2.3rem 2rem; }
  .product-img img { max-height: 25rem; }
  .product-img .slider-thumbs img { max-height: 90%; }
  .product-head { width: 100%; min-height: 0; padding: 0 0 3rem; }
  .product-head .btn { margin-top: 3.8rem; height: 5rem; line-height: 5rem; }
  .product-head .h1 { font-size: 2.3rem; margin-bottom: 2rem; }
  .tovar-info { margin-top: 2rem; }
  .product-head__title { margin-top: 4rem; }
  .product-about { border-top-width: 0; }
  .main-section-inner { padding-top: 7rem; }
  .breadcrumbs { margin: 2.3rem 0 5.1rem; }
  .breadcrumb { font-size: 1.4rem; }
  .breadcrumb i { font-size: 2.1rem; }
  .section-product-about { padding: .7rem 0 1.1rem; }
  .cart { border-width: 0; font-size: 1.4rem; }
  .cart__item { display: block; margin-bottom: 1.6rem; border: 1px solid #B5B5B5; }
  .cart__img img { max-height: 148px; }
  .cart__img { width: 100%; }
  .cart__details { padding: 1.6rem 1.7rem; width: 100%; }
  .cart__status { padding: 0.8rem 1.3rem; font-size: 1.3rem; top: 1.9rem; left: 1.7rem; }
  .cart__head { padding-right: 0; width: 100%; }
  .cart__number { margin-top: 1.2rem; }
  .cart__details-list { margin-top: .4rem; }
  .cart__date { padding-right: 0; width: 100%; }
  .cart__remove { width: 100%; justify-content: center; }
  .cart__title { font-size: 1.6rem; }
  .cart-order-done { display: block; }
  .cart-order-done__icon { width: 100%; padding-left: 0; margin-top: 3.6rem; justify-content: flex-start; }
  .cart-order-done__icon svg { width: 14.4rem; }
  .cart-order-done__alert { width: 100%; }
  .cart-order-done__btn { text-align: center; }
  .my-account-tabs { padding: 20px 0 0; border-left: 0; margin-left: 0; width: 100%; }
  .my-account { border-width: 0; }
  .menu-navs { width: 100%; padding-top: 0; border-right: 0; }
  .login-form .box-field_half { width: 100%; }
  .section-blog { padding: 2px 0 40px; }
  .blog-list .blog-card { width: 100%; }
  .blog-card__title { font-size: 1.8rem; }
  .projects-nav li { margin: 0 1.6rem 2rem 0; }
  .projects-nav { margin-bottom: 20px; }
  .product-about-2 { display: flex; flex-wrap: wrap; flex-direction: column-reverse; line-height: 2.1rem; }
  .product-about-2__left { padding-right: 0; border-right-width: 0; margin-right: 0; width: 100%; }
  .product-about-2__right { margin-bottom: 20px; border-bottom: 1px solid #B5B5B5; padding: 0 0 20px; border-left-width: 0; width: 100%; display: flex; flex-direction: column-reverse; }
  .product-about-2__label, .product-about-2 .h2-sm { font-size: 2.4rem; font-weight: 500; line-height: 3.1rem; margin-bottom: 1rem; }
  .section-product-about-2 { padding: 4rem 0 4rem; }
  .product-about-2__item + .product-about-2__item { margin: 0 0 1.7rem; }
  .block-img-3 { display: block; padding: 3rem 0 2rem; margin: 0; }
  .block-img-3 figure { padding: 0; width: 100%; }
  .block-img-3 figure + figure { margin-top: 25px; }
  figcaption { font-size: 1.4rem; margin-top: 1.1rem; }
  .article-main .breadcrumbs { margin-bottom: 3.1rem; }
  .article { margin-top: 3.8rem; line-height: 1.3; }
  .article h1 { font-size: 2rem; margin-bottom: 1.8rem; }
  .article picture { margin: 3rem 0 2.2rem; }
  .article ol, .article ul { margin: .7rem 0 0 1.0rem; }
  .article ol li, .article ul li { margin-bottom: .8rem; }
  .article blockquote { margin: 2.5rem 0 3.5rem; font-size: 1.6rem; padding: .6rem 1.2rem .6rem 2rem; }
  .article .table-wrap { margin: 30px 0 15px; }
  .article .table-wrap table { width: 150%; }
  .article-info { margin-bottom: 3.9rem; }
  .article-wrap-slider { padding: 1.5rem 0 1rem; }
  .article-inner-slider-navs { margin-top: 2.2rem; justify-content: flex-start; }
  .article .video_wrap { margin: 3rem 0 2.8rem; }
  .block-img-4_reverse .block-img-4__block { padding-right: 0; padding-left: 0; margin-top: 15px; }
  .included { margin: 0; }
  .included__item { width: 100%; border-right-width: 0; padding: 0; margin-bottom: 4rem; }
  .faq__title { font-size: 1.8rem; }
  .section-head__contacts { width: 100%; margin-top: 4rem; }
  .contact-items { font-size: 1.8rem; }
  .contact-icon { margin-right: 2.3rem; }
  .contact-item { margin: 0 0 2.3rem; }
  .contact-item__title + .contact-item__title { margin-top: 0; }
  .section-head_contacts { padding-bottom: 15.8rem; }
  .contacts-list .faq__title { font-size: 1.8rem; padding: 1.5rem 0 1.5rem; }
  .contacts-main { top: -10.5rem; }
  .contacts-list { padding: 2.2rem 2rem 4.5rem; }
  .section-contacts { margin-bottom: -7.3rem; }
  .faq__title { padding: 2.6rem 0 2.5rem; }
  .faq.active .faq__title { padding-bottom: 2.5rem; }
  .section-career-list { padding: 3.8rem 0 4rem; }
  .faq__body { padding-bottom: 2rem; margin-right: -6px; }
  .faq__body b { letter-spacing: -.03em; }
  .block-img-4__description { line-height: 1.3; }
  .block-img-4 .info-block { margin-top: 2.8rem; }
  .product-slider-wrap { display: block; width: 100%; }
  .product-slider__item { display: flex; background: #FCFAF1; height: 30rem; max-height: 30rem; }
  .slider-thumbs { margin: 0 -.8rem 1rem .8rem; }
  .slider-thumbs .slick-slide { padding-right: .8rem; }
  .slider-thumbs .slick-arrow { position: absolute; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; width: 6rem; height: auto; transform: none; z-index: 2; }
  .slider-thumbs .slick-prev { left: 0; }
  .slider-thumbs .slick-next { right: 0; }
  .slider-thumbs__item { background: #FCFAF1; height: 8.2rem; text-align: center; }
  .web .slider-thumbs .slick-arrow:hover { position: absolute; }
  .section-my-account { padding: 5.3rem 0 1.2rem; }
  .section-my-account .h1 { margin-bottom: 1.4rem; }
  .my-account { margin-top: 0; }
  .my-account .show-password { font-size: 3.4rem; right: 1.3rem; }
  .my-account-form .box-field__label { margin-bottom: 0.6rem; }
  .my-account-form .box-field { margin-bottom: 2.1rem; }
  .box-field__hidden.shown-input { display: block; }
  .box-field__hidden input { width: 100%; height: 5rem; }
  .box-field__hidden .jq-selectbox__select { height: 5rem; padding-top: 1.3rem; }
  .box-field__hidden .jq-selectbox__trigger-arrow { top: 1.5rem; width: 1rem; height: 1rem; }
  .accept-change { width: 100%; height: 5rem; margin: 1.5rem 0 0; }
  .my-account-form-password-title { margin-bottom: 2.1rem; font-size: 2rem; }
  .cart-btn .btn { width: 100%; }
  .subcategories__category { font-size: 1.2rem; margin-bottom: 2.7rem; }
  .breadcrumb { white-space: normal; overflow: visible; }
  .categories__item_1 .categories__img { width: 69%; bottom: 20%; }
  .section-top-product { height: 64rem; display: flex; flex-direction: column; justify-content: space-between; }
  .section-top-product .product-img { padding-top: 0; padding-bottom: 0; align-items: center; height: 35rem; }
  .error-page { padding-top: 4.3rem; padding-bottom: 7.5rem; }
  .error-page .text-error { display: none; }
  .error-page__img { margin-top: 0; width: auto; margin-right: 2rem; padding: 3.3rem 0 0; }
  .error-page__head { width: 100%; }
  .btn-error { margin-top: 5.5rem; }
  .search-list { max-width: 40rem; margin: 0 auto; }
  .search-list__title { font-size: 2rem; }
  .search-list__item { display: block; }
  .search-list__img { margin: 0 0 2.3rem; width: 100%; position: relative; height: auto; }
  .search-list__img:before { content: ''; display: block; padding: 100% 0 0; }
  .search-list__img img { position: absolute; left: 0; top: 0; }
  .search-list + .box-paging { margin-top: 4rem; }
  .section-search { padding: 3.3rem 0 2.3rem; }
  .search-list__text { margin-top: 1.7rem; line-height: 187.5%; }
  .search-list__item + .search-list__item { margin-top: 3.8rem; } }

@media screen and (min-width: 768px) { .cart__item:last-child { border-bottom: 0; }
  .product-slider-wrap { display: flex; width: 100%; position: relative; }
  .product-slider-wrap img { vertical-align: top; }
  .slider-thumbs { width: 28%; padding-right: 3.5rem; position: absolute; left: 0; top: 0; bottom: -10px; }
  .slider-thumbs img { max-height: 100%; }
  .slider-thumbs__item { position: relative; background: #FCFAF1; cursor: pointer; }
  .slider-thumbs .slick-slide { padding-bottom: 10px; height: 17.7rem; display: flex !important; }
  .slider-thumbs .slick-slide > *, .slider-thumbs .slick-slide > * > * { display: flex; flex-direction: column; flex: 1 1 100%; height: 100%; }
  .slider-thumbs .slick-slide > * > * { display: flex !important; align-items: center; justify-content: center; }
  .slider-thumbs .slick-slide img { height: calc(100% - 10rem); }
  .slider-thumbs .slick-arrow { position: absolute; left: 0; right: 0; display: flex; align-items: center; justify-content: center; width: calc(100% - 3.8rem); height: 5rem; transform: none; z-index: 2; }
  .slider-thumbs .slick-prev { top: 0; }
  .slider-thumbs .slick-next { bottom: 1rem; }
  .web .slider-thumbs .slick-arrow:hover { position: absolute; }
  .product-slider { margin-left: 28%; width: 72%; }
  .product-img { width: 56.5%; }
  .product-head { width: 40%; }
  .product-slider__item { height: 51.9rem; min-height: 51.9rem; }
  .menu-navs_sm { font-size: 1.5rem; padding-right: 2rem; }
  .categories_2 .categories__img { width: 46%; } }

@media screen and (min-width: 1024px) { .product-slider__item { height: 63.9rem; min-height: 63.9rem; }
  .section-product-head { min-height: 77.9rem; }
  .slider-thumbs .slick-slide { height: 21.7rem; }
  .categories_2 .categories__img { width: 49%; } }

@media screen and (min-width: 1367px) { .categories_2 .categories__img { width: 49%; } }

@media screen and (min-width: 1440px) { .product-slider__item { height: 77.9rem; min-height: 77.9rem; }
  .slider-thumbs .slick-slide { height: 26.4rem; }
  .categories_2 .categories__img { width: 49%; } }

@media screen and (min-width: 1600px) { .menu-navs_sm { font-size: 1.6rem; } }

@media screen and (min-width: 1700px) { .menu-navs_sm { font-size: 1.7rem; } }

@media screen and (min-width: 1800px) { .menu-navs_sm { font-size: 1.8rem; } }

.section-login { display: flex; padding: 10rem 0 0; }

.section-login__decor { padding: 40px; background: #1F1F1F; width: 50%; display: flex; align-items: center; justify-content: center; }

.section-login__content { width: 50%; }

.btn_login { width: 100%; }

.login { max-width: 72.6rem; padding: 5rem 3rem 5rem 15%; }

.login .back-arrow { margin: 0 0 14.5rem; }

.forgot-password-text { margin-top: -2rem; margin-bottom: 4rem; font-size: 1.8rem; max-width: 47rem; }

.login-links { margin-top: 5.4rem; font-size: 1.8rem; color: #808080; max-width: 38rem; }

.login-links__item + .login-links__item { margin-top: 2.1rem; }

.login-links__link { color: #1F1F1F; border-bottom: 1px solid; }

.login-links__link:hover { color: #FFDA22; }

.box-field__login { margin: 2.5rem 0 0; }

@media screen and (max-width: 1280px) { .login { padding-left: 4rem; } }

@media screen and (max-width: 767px) { .section-login { display: block; padding-top: 7rem; }
  .section-login__decor { width: 100%; padding: .9rem 2.4rem 3.2rem; height: 26.5rem; justify-content: flex-start; }
  .section-login__decor svg { max-height: 62%; width: auto; }
  .section-login__content { width: 100%; }
  .login { padding: 4.2rem 2.4rem 3.6rem; }
  .login-links { margin-top: 4rem; font-size: 1.6rem; }
  .login-title { font-size: 2rem; }
  .box-field__login { margin: 0.3rem 0 0; }
  .box-field__login .btn { height: 5rem; line-height: 5rem; font-size: 1.6rem; }
  .login-links__item + .login-links__item { margin-top: 2.4rem; }
  .login .back-arrow { margin: 0 0 3.8rem; }
  .forgot-password-text { margin-top: -1rem; margin-bottom: 2.5rem; font-size: 1.6rem; line-height: 1.3; } }

@media screen and (min-width: 768px) { .animated { -webkit-animation-duration: .7s; animation-duration: .7s; -webkit-animation-fill-mode: both; animation-fill-mode: both; } }

@-webkit-keyframes fadeIn { from { opacity: 0; }
  to { opacity: 1; } }

@keyframes fadeIn { from { opacity: 0; }
  to { opacity: 1; } }

.fadeIn { -webkit-animation-name: fadeIn; animation-name: fadeIn; }

@-webkit-keyframes fadeInLeft { from { opacity: 0;
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft { from { opacity: 0;
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft { -webkit-animation-name: fadeInLeft; animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftShort { from { opacity: 0;
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeftShort { from { opacity: 0;
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeftShort { -webkit-animation-name: fadeInLeftShort; animation-name: fadeInLeftShort; }

@-webkit-keyframes fadeInRight { from { opacity: 0;
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight { from { opacity: 0;
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight { -webkit-animation-name: fadeInRight; animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightShort { from { opacity: 0;
    -webkit-transform: translate3d(30px, 0, 0);
    transform: translate3d(30px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightShort { from { opacity: 0;
    -webkit-transform: translate3d(30px, 0, 0);
    transform: translate3d(30px, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRightShort { -webkit-animation-name: fadeInRightShort; animation-name: fadeInRightShort; }

@-webkit-keyframes fadeInRightLong { from { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  35% { opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40% { opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightLong { from { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  35% { opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40% { opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRightLong { -webkit-animation-name: fadeInRightLong; animation-name: fadeInRightLong; }

@-webkit-keyframes fadeInUp { from { opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp { from { opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp { -webkit-animation-name: fadeInUp; animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpShort { from { opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpShort { from { opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUpShort { -webkit-animation-name: fadeInUpShort; animation-name: fadeInUpShort; }

@-webkit-keyframes fadeInDown { from { opacity: 0;
    -webkit-transform: translate3d(0, -60px, 0);
    transform: translate3d(0, -60px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown { from { opacity: 0;
    -webkit-transform: translate3d(0, -60px, 0);
    transform: translate3d(0, -60px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown { -webkit-animation-name: fadeInDown; animation-name: fadeInDown; }

.page-osnova .h1:after, .page-osnova .h2:after { background: #1BF0CF; }

.page-osnova .btn { background: #1BF0CF; border-radius: 5rem; }

.page-osnova .btn:disabled { background: #E3E3E3; }

.page-osnova [type="text"], .page-osnova [type="tel"], .page-osnova [type="email"], .page-osnova [type="password"], .page-osnova textarea { border-radius: 5rem; }

.page-osnova:not(.header-white) .header { background-image: none; background-color: transparent; }

.page-osnova:not(.header-white) .search-form-inner { border-left-color: #B5B5B5; border-right-color: #B5B5B5; background: rgba(255, 255, 255, 0.05); }

.page-osnova:not(.header-white) .header__info:before { left: 19.2rem; }

.page-osnova .section-dark, .page-osnova:not(.header-white) .header.fixed { background: linear-gradient(270deg, #9251ed 0%, #5415bc 100%); }

.page-osnova .section-consultation [type="text"], .page-osnova .section-consultation [type="tel"], .page-osnova .section-consultation [type="email"], .page-osnova .section-consultation [type="password"], .page-osnova .section-consultation textarea, .page-osnova .section-dark [type="text"], .page-osnova .section-dark [type="tel"], .page-osnova .section-dark [type="email"], .page-osnova .section-dark [type="password"], .page-osnova .section-dark textarea { color: #fff; border-radius: 5rem; border: 1px solid #FFF; background: rgba(255, 255, 255, 0.4); }

.page-osnova .section-consultation [type="text"]::-webkit-input-placeholder, .page-osnova .section-consultation [type="tel"]::-webkit-input-placeholder, .page-osnova .section-consultation [type="email"]::-webkit-input-placeholder, .page-osnova .section-consultation [type="password"]::-webkit-input-placeholder, .page-osnova .section-consultation textarea::-webkit-input-placeholder, .page-osnova .section-dark [type="text"]::-webkit-input-placeholder, .page-osnova .section-dark [type="tel"]::-webkit-input-placeholder, .page-osnova .section-dark [type="email"]::-webkit-input-placeholder, .page-osnova .section-dark [type="password"]::-webkit-input-placeholder, .page-osnova .section-dark textarea::-webkit-input-placeholder { color: #fff; }

.page-osnova .section-consultation [type="text"]::-moz-placeholder, .page-osnova .section-consultation [type="tel"]::-moz-placeholder, .page-osnova .section-consultation [type="email"]::-moz-placeholder, .page-osnova .section-consultation [type="password"]::-moz-placeholder, .page-osnova .section-consultation textarea::-moz-placeholder, .page-osnova .section-dark [type="text"]::-moz-placeholder, .page-osnova .section-dark [type="tel"]::-moz-placeholder, .page-osnova .section-dark [type="email"]::-moz-placeholder, .page-osnova .section-dark [type="password"]::-moz-placeholder, .page-osnova .section-dark textarea::-moz-placeholder { color: #fff; }

.page-osnova .section-consultation [type="text"]:-moz-placeholder, .page-osnova .section-consultation [type="tel"]:-moz-placeholder, .page-osnova .section-consultation [type="email"]:-moz-placeholder, .page-osnova .section-consultation [type="password"]:-moz-placeholder, .page-osnova .section-consultation textarea:-moz-placeholder, .page-osnova .section-dark [type="text"]:-moz-placeholder, .page-osnova .section-dark [type="tel"]:-moz-placeholder, .page-osnova .section-dark [type="email"]:-moz-placeholder, .page-osnova .section-dark [type="password"]:-moz-placeholder, .page-osnova .section-dark textarea:-moz-placeholder { color: #fff; }

.page-osnova .section-consultation [type="text"]:-ms-input-placeholder, .page-osnova .section-consultation [type="tel"]:-ms-input-placeholder, .page-osnova .section-consultation [type="email"]:-ms-input-placeholder, .page-osnova .section-consultation [type="password"]:-ms-input-placeholder, .page-osnova .section-consultation textarea:-ms-input-placeholder, .page-osnova .section-dark [type="text"]:-ms-input-placeholder, .page-osnova .section-dark [type="tel"]:-ms-input-placeholder, .page-osnova .section-dark [type="email"]:-ms-input-placeholder, .page-osnova .section-dark [type="password"]:-ms-input-placeholder, .page-osnova .section-dark textarea:-ms-input-placeholder { color: #fff; }

.page-osnova textarea { border-radius: 1.6rem; }

.page-osnova .section-top { background-image: url(../img/header-bg.svg), linear-gradient(270deg, #9251ed 0%, #5415bc 100%); background-position: 100% 0%; background-repeat: no-repeat, no-repeat; background-size: auto 100%, 100% 100%; }

.page-osnova .product-head { width: 50%; }

.page-osnova .product-head .h1 { overflow: visible; }

.page-osnova .language-currency, .page-osnova .header__info, .page-osnova .bars-mob { border-left-color: #B5B5B5; }

.page-osnova .subcategories__item { border-radius: 1.6rem; background: #F8F8F8; border-color: #F8F8F8; overflow: hidden; }

.page-osnova .subcategories__head { width: 57.5%; padding-bottom: 4rem; justify-content: center; }

.page-osnova .subcategories__description { padding-bottom: 0; }

.page-osnova .subcategories__btn { padding: 0 6.4rem; }

.page-osnova .subcategories__img { padding: 6.5rem; background: #e7d4ff; width: 42.5%; }

.page-osnova .subcategories__item_reverse .subcategories__btn { text-align: left; }

.page-osnova .subcategories_2 .subcategories__img { background: #C9C9E8; }

.page-osnova .section-top-middle { background-image: url(../img/header-bg.svg), linear-gradient(270deg, #5161ed 0%, #2b298f 100%); }

.page-osnova .section-product-head { color: #fff; }

.page-osnova .section-product-head .wrapper { z-index: 1; }

.page-osnova .section-head { padding: 4rem 0 7.3rem; }

.page-osnova .product-img { background: none; z-index: 0; }

.page-osnova .product-head .h1:after { background: #fff; }

.page-osnova .checkbox-el .check { border-radius: .8rem; background: #FCFCFC; }

.page-osnova .checkbox-el input:checked ~ .checkbox-el__txt .check { background: #1BF0CF; }

.page-osnova .section-top.main-section-inner, .page-osnova .section-top.article-main { background: none; }

.page-osnova .projects-nav a { border-radius: .8rem; }

.page-osnova .projects-nav a.active, .page-osnova .projects-nav a:hover { background: #5F01D2; }

.page-osnova .hero-image, .page-osnova .hero-image img, .page-osnova .video_wrap img, .page-osnova .video_wrap { border-radius: 1.6rem; }

.page-osnova .video_wrap { max-width: 142.8rem; margin-left: auto; margin-right: auto; }

.page-osnova .article-inner-slider__item img { border-radius: 1.6rem; }

.page-osnova .article blockquote { border-radius: 1.6rem; background: #F8F8F8; border: 0; padding: 2.9rem 9% 2.9rem 7.4rem; position: relative; }

.page-osnova .article blockquote:before { position: absolute; left: 0; top: 4.1rem; line-height: 1; transform: translateX(-50%); font-size: 5.7rem; font-family: icomoon; color: #5f01d2; }

.page-osnova .section-subcategoies { padding-bottom: 2rem; }

.page-osnova .section-subcategoies .subcategories__item:last-child { margin-bottom: 0; }

.page-osnova .section-subcategoies + .section-exellences { padding-top: 6rem; }

.page-osnova:not(.header-white) .opened .search-form-inner-inner { background: #874be2; }

.page-osnova:not(.header-white).page-osnova-dark .opened .search-form-inner-inner { background: #545ccd; }

@media screen and (max-width: 1365px) { .page-osnova .article blockquote { font-size: 2.1rem; } }

@media screen and (max-width: 1023px) { .page-osnova .article blockquote { padding: 2.5rem 7% 2.5rem 5.4rem; }
  .page-osnova .article blockquote:before { font-size: 4rem; }
  .page-osnova:not(.header-white) .search-form-inner-inner, .page-osnova:not(.header-white) .search-form-inner { background: transparent; }
  .page-osnova:not(.header-white) .header__info:before { left: 0; } }

@media screen and (max-width: 767px) { .page-osnova .subcategories__head, .page-osnova .subcategories__img { width: 100%; }
  .page-osnova .subcategories__img { padding: 2rem; }
  .page-osnova .article blockquote { font-size: 1.6rem; padding: 1rem 2rem .8rem 3.9rem; }
  .page-osnova .article blockquote:before { font-size: 3rem; top: 1.8rem; left: .5rem; }
  .page-osnova .product-head__title { margin-top: 7rem; }
  .page-osnova .product-img { margin-top: 2.1rem; }
  .page-osnova .section-product-about { padding: 4rem 0 1.1rem; }
  .page-osnova .checkbox-el__txt { align-items: flex-start; }
  .page-osnova .checkbox-el .check { margin-right: 2.5rem; }
  .page-osnova .checkbox-el__txt { line-height: 2rem; }
  .page-osnova .box-field_project-checkbox { margin-bottom: 2.6rem; min-height: 0; }
  .page-osnova .section-top { background-image: url(../img/header-bg_mob.svg), linear-gradient(270deg, #9251ed 0%, #5415bc 100%); }
  .page-osnova .subcategories__btn { padding: 0; text-align: center; }
  .page-osnova .product-head { width: 100%; }
  .page-osnova .breadcrumb li { flex-wrap: wrap; }
  .page-osnova .subcategories__item_reverse .subcategories__btn { text-align: center; } }

@media screen and (min-width: 1023px) { .page-osnova .section-product-head { height: 45.4rem; min-height: 0; }
  .page-osnova .section-head { min-height: 40.4rem; }
  .page-osnova .product-img { width: 53.5%; padding-right: 4.2%; }
  .page-osnova .product-head__title { margin-top: 6rem; }
  .page-osnova .breadcrumbs { margin: 3.3rem 0 0; }
  .page-osnova .subcategories__item { margin-bottom: 6rem; }
  .page-osnova .section-head__title { width: 59%; } }

@media screen and (min-width: 1024px) { .page-osnova:not(.header-white) .search-form [type="text"]:-webkit-autofill, .page-osnova:not(.header-white) .search-form [type="text"]:-webkit-autofill:hover, .page-osnova:not(.header-white) .search-form [type="text"]:-webkit-autofill:focus { background: #874be2; border-color: #874be2; -webkit-text-fill-color: #fff; -webkit-box-shadow: inset 0 0 100px 40px #874be2; }
  .page-osnova:not(.header-white) .search-form [type="text"]::-webkit-input-placeholder { color: #fff; }
  .page-osnova:not(.header-white) .search-form [type="text"]::-moz-placeholder { color: #fff; }
  .page-osnova:not(.header-white) .search-form [type="text"]:-moz-placeholder { color: #fff; }
  .page-osnova:not(.header-white) .search-form [type="text"]:-ms-input-placeholder { color: #fff; }
  .page-osnova:not(.header-white).page-osnova-dark .search-form [type="text"]:-webkit-autofill, .page-osnova:not(.header-white).page-osnova-dark .search-form [type="text"]:-webkit-autofill:hover, .page-osnova:not(.header-white).page-osnova-dark .search-form [type="text"]:-webkit-autofill:focus { border-color: #545ccd; background: #545ccd; -webkit-text-fill-color: #fff; -webkit-box-shadow: inset 0 0 100px 40px #545ccd; } }

@media screen and (min-width: 1600px) { .page-osnova .product-head .h1-md { font-size: 7rem; }
  .page-osnova .section-product-about { padding: 14.4rem 0 14.8rem; }
  .page-osnova .section-video-product { padding: 3.1rem 0 3rem; }
  .page-osnova .section-create-project { padding: 5rem 0 5.2rem; }
  .page-osnova .subcategories__item { margin-bottom: 8rem; }
  .page-osnova .page-osnova .subcategories__item { min-height: 63rem; }
  .page-osnova .subcategories__description { padding: 31px 14% 40px; } }

@media screen and (min-width: 1800px) { .page-osnova .product-head .h1-md { font-size: 8rem; } }

.page-osnova-dark .section-top { background-image: url(../img/middle-bg.svg), linear-gradient(270deg, #5161ed 0%, #2b298f 100%); background-position: 100% 0%; background-size: 70% auto, 100% 100%; }

.page-osnova-dark:not(.header-white) .header.fixed { background-image: linear-gradient(270deg, #5161ed 0%, #2b298f 100%); }

@media screen and (max-width: 767px) { .page-osnova-dark .section-top { background-image: url(../img/middle-bg_mob.svg), linear-gradient(270deg, #5161ed 0%, #2b298f 100%); } }

.blog-list.cases .projects__item { margin: 0 1.8rem 3.6rem; width: calc(33.333% - 3.6rem); flex: 0 0 auto; }

.blog-list.cases .projects__img:before { display: none; }

.blog-list.cases .projects__img img { position: static; height: auto; transform: none; }

.article-info__place { padding: 0 1.5rem; color: #B5B5B5; text-transform: uppercase; line-height: 1.05; font-size: 1.6rem; letter-spacing: .1em; display: inline-block; border-left: 1px solid #5F01D2; border-right: 1px solid #5F01D2; }

.case-about { border-radius: 1.6rem; background: #F8F8F8; padding: 4.8rem 4rem 4rem 4.2rem; display: flex; }

.case-about span.wow { display: inline-block; }

.case-about__head { padding-right: 4rem; width: 45.5%; }

.case-about__description { padding-left: 4rem; width: 55.5%; }

.case-about__text { overflow: hidden; }

.case-about__subtitle { font-weight: 700; overflow: hidden; }

.case-about__label { overflow: hidden; margin-bottom: 1.4rem; }

.case-about__value { font-size: 3rem; font-weight: 500; overflow: hidden; }

.case-about__item { margin-bottom: 4.6rem; }

.case-about__item:last-child { margin-bottom: 0; }

@media screen and (max-width: 1365px) { .case-about__head { padding-right: 3rem; width: 48.5%; }
  .case-about__description { padding-left: 3rem; width: 51.5%; } }

@media screen and (max-width: 1023px) { .blog-list.cases .projects__item { width: calc(33.333% - 3.6rem); } }

@media screen and (max-width: 767px) { .blog-list.cases { margin: 0; }
  .blog-list.cases .projects__item { margin: 0 0 1.6rem; width: 100%; }
  .case-about { display: block; }
  .case-about__head { padding-right: 0; width: 100%; }
  .case-about__description { padding-left: 0; width: 100%; }
  .case-about { padding: 1.6rem 2rem 2rem 1.9rem; }
  .case-about__value { font-size: 2rem; }
  .case-about__label { margin-bottom: .8rem; }
  .case-about__item { margin-bottom: 1.6rem; }
  .case-about__description { padding-left: 0; width: 100%; margin-top: 25px; } }
