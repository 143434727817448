.header {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10001;
  flex-shrink: 0;
  background: #1F1F1F;
  color: #fff;
  border-bottom: 1px solid #808080;

  .wrapper {
    display: flex;
    justify-content: space-between;

    &:after {
      display: none;
    }
  }

  &__info {
    font-size: 1.6rem;
    //border-left: 1px solid #808080;
    display: flex;
    position: relative;

    a {
      color: inherit;
    }

    &>* {
      position: relative;
      z-index: 1
    }

    &:before {
      content: '';
      display: block;
      left: 0;
      width: 15000px;
      top: 0;
      bottom: 0;

      background: rgba(255, 255, 255, 0.05);
      box-shadow: inset 0px 9px 28px 0px rgba(255, 255, 255, 0.05);
      position: absolute;
      z-index: 0
    }

  }

  &__login {
    display: flex;
    align-items: center;
    padding: 1rem 4.1rem 1rem 7.4rem;

    i {
      margin-right: 10px;
      font-size: 2.7rem;
      margin-top: -.3rem;
    }


  }

  &__projects {
    display: flex;
    align-items: center;
    padding: 1rem 6.6rem 1rem 3.2rem;

    i {
      margin-right: 1.1rem;
      margin-top: -.2rem;
      font-size: 3.2rem;
    }
  }
}

.header__login .loginned {
  display: inline-flex;
  align-items: center;
  position: relative;

  span {
    display: flex;
    max-width: 16.8rem
  }
}

.header__login .logined-icon {

  color: $color-active;
  position: absolute;
  left: .6rem;
  top: -.4rem;
  font-size: 53%;
}

.nav-inner {
  width: 100%;
  display: flex;
}


.nav-logo {
  padding: .7rem 0 .6rem;
  flex-shrink: 0;
  margin-right: 2rem;
  min-height: 10rem;
  display: flex;
  align-items: center;
}

.menu-box {
  background-color: #1f1f1f;
  padding: 7rem 0 3rem;
  position: fixed;
  left: 0;
  transform: translateY(-150vh);
  top: 10.9rem;
  margin: 0;
  z-index: 100;
  width: 100%;
  bottom: 0;
  width: 100%;
  color: #fff;
  overflow: auto;

  align-items: flex-start;
  color: #fff;
  transition: transform 360ms cubic-bezier(0.5, 0.06, 0.01, 0.99);
  will-change: right;
  bottom: 0;

  background-size: auto 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;

  &.active {
    transform: translateY(0);

    &:before {
      height: 0;
    }
  }

  .wrapper {
    display: block;
    z-index: 0
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #4d4d4c;
    transition: height .85s cubic-bezier(0.5, 0.06, 0.01, 0.99) 0.5s;
    will-change: height;
  }

}

.logo-white {
  display: none;
}

.header-white {
  .logo-white {
    display: inline-block;
  }

  .logo-default {
    display: none;
  }
}

.nav-inner {
  margin: 0 -25px;
  width: calc(100% + 50px)
}

.nav-inner>ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
}

.nav-inner>ul>li {
  margin: 0 25px;
  position: relative;
  width: 26%;

  &:first-child {
    width: 33%;
  }

  &:last-child {
    width: 16%;
  }
}


.nav-inner>ul>li:hover .menu-dropdown {
  display: block
}

.nav-inner>ul>li i {
  font-size: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 4px;
}

.menu-dropdown {
  max-width: 35rem
}

.menu-dropdown__col {
  padding: 0 1.5rem
}

.menu-dropdown__title {
  margin-bottom: .9rem;
  opacity: 0.5;
  text-transform: uppercase;
}

.menu-dropdown ul {
  display: block;
  list-style: none;
}


.menu-dropdown li {
  width: 100%;
  padding: .4rem 0;
  margin-bottom: 2.9rem
}

.menu-dropdown ul a {
  color: inherit;
  display: inline-flex;
  line-height: 120%;
  align-items: center;
  display: block;
  vertical-align: top;
  justify-content: flex-end;
  transition: color .5s ease, background .5s ease;

  span {
    @extend .icon-arrow;
    position: relative;
    padding-right: 4.3rem;
    display: inline-block;

    span {
      display: inline;
      border-bottom: 1px solid transparent;
      padding-right: 0;

      &:before {
        display: none;
      }
    }

    &:before {
      opacity: 0;
      font-size: 46%;
      font-family: icomoon;
      flex-shrink: 0;
      margin-left: 5px;
      transition: opacity .5s ease;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.menu-dropdown ul a:hover {
  color: $color-active;

  span:before {
    opacity: 1;
  }

  span span {
    border-bottom-color: $color-active
  }
}

.menu-dropdown ul a:before {
  font-family: icomoon;
  margin-left: 1rem;
  opacity: 0;
  font-size: 76%
}

.menu-dropdown ul a li {
  margin: 0 0 1px
}


.menu-dropdown ul a:hover:before {
  opacity: 1
}

.menu-dropdown ul .current-menu-item a {
  font-weight: 700
}


.bars-mob {
  width: 11.5rem;
  display: block;
  cursor: pointer;
  border-left: 1px solid #808080;
  position: relative;

  .hamburger {
    position: absolute;
    height: 4.4rem;
    padding: .4rem 0;
    top: 50%;
    left: 50%;
    margin-left: 2.8rem;
    width: 5.3rem;

    transform: translate(-50%, -50%);

    span {
      display: block;
      width: 5.3rem;
      height: 1px;
      position: relative;
      background: #fff;
      top: 0;
      left: 0;
      margin: 0 0 1.6rem;

      transition: .25s ease-in-out;

      &:nth-child(1) {
        // transition-delay:.5s;
      }

      &:nth-child(2) {
        transition-delay: .625s;
        width: 2.7rem
      }

      &:nth-child(3) {
        transition-delay: .75s;
        margin-bottom: 0
      }
    }
  }

}

.ios {
  .bars-mob .hamburger:hover span:nth-child(2) {
    width: 100%;
    transition-delay: 0s;
  }

  .bars-mob.active .hamburger:hover span:nth-child(2) {
    width: 0;
  }
}

.bars-mob .cross {
  position: absolute;
  height: 4.2rem;
  width: 4.2rem;
  margin: 3.2rem 0 0 5.9rem;
  transform-origin: center center;
  transform: rotate(45deg);
}

.bars-mob .cross span {
  display: block;
  background: #fff;
  transition: .25s ease-in-out;
}

.bars-mob .cross span:nth-child(1) {
  height: 0%;
  width: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-delay: 0s;
}

.bars-mob .cross span:nth-child(2) {
  width: 0%;
  height: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-delay: .25s;
}

.bars-mob.active .hamburger span {
  width: 0%;
}

.bars-mob.active .hamburger span:nth-child(1) {
  transition-delay: 0s;
}

.bars-mob.active .hamburger span:nth-child(2) {
  transition-delay: .125s;
}

.bars-mob.active .hamburger span:nth-child(3),
.bars-mob.active .hamburger:hover span:nth-child(3) {
  transition-delay: .25s;
}

.bars-mob.active .cross span:nth-child(1) {
  height: 100%;
  transition-delay: .625s;
}

.bars-mob.active .cross span:nth-child(2) {
  width: 100%;
  transition-delay: .375s;
}

.language-currency {
  padding: 0 4.6rem;
  border-left: 1px solid #808080;
  position: relative;
  font-weight: 500;
  z-index: 101;
  font-size: 2rem;
  line-height: 1.167;
  text-transform: uppercase;
}

.current-language {
  font-weight: 500;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #fff;
  height: 100%;
  position: relative;

  i {
    font-size: 43%;
    margin-left: 1rem;
  }

}

.icon-caret-side {
  @extend .icon-caret
}

.language-currency {
  ul {
    padding: 0.4rem 0 20px;
    list-style: none;
    position: absolute;
    left: 50%;
    top: 100%;
    border: 1px solid #808080;
    width: 100%;
    margin-top: -1px;
    opacity: 0;
    pointer-events: none;
    text-align: center;
    background: #1F1F1F;
    transform: translate(-50%, 1rem);
    transition: transform .5s ease, opacity .5s ease;
  }

  li {
    padding: 0.8rem 3.1rem;
  }

  a {
    display: inline-block;
    border-bottom: 1px solid;
    transition: border-bottom-color .6s ease
  }

  a:hover {
    border-bottom-color: transparent;
  }

  &:hover ul {
    opacity: 1;
    transform: translate(-50%, 0);
    pointer-events: auto;
  }

  .curent {
    display: none;
  }
}


.open-header {
  .bars-mob {
    z-index: 101
  }

  .nav-logo {
    z-index: 101;
  }
}

.header-links {
  display: flex;
  align-items: center;
}

.menu-title {
  text-transform: uppercase;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 3.4rem;
  display: block;

  a,
  &>span {
    opacity: 0.5;
    color: inherit;
  }

  i {
    opacity: 0;
  }
}

.menu-title a {
  display: inline-flex;
  align-items: flex-start;

  &:hover {
    opacity: 1;
    color: $color-active;

    span {
      opacity: 1;
    }

    i {
      opacity: 1
    }
  }
}

.projects-count {
  min-width: 1.6rem;
  border-radius: 50%;
  background: #FF5050;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  padding: 1px 3px 0;
  margin-left: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footers-socials.header-socials {
  margin-top: 2.8rem;
  padding: 0;
  width: 100%;

  .socials {
    justify-content: flex-end;
    display: flex;

    img {
      max-height: 4.2rem;
      height: 4.2rem
    }
  }

  .socials__item {
    margin-left: 4.5rem;
    margin-right: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}

.header-white {
  .header {
    background: #fff;
    border-bottom-color: $line;
    color: #1F1F1F;
  }

  .bars-mob .hamburger span,
  .bars-mob .cross span {
    background: #1F1F1F;
  }

  .current-language {
    color: inherit;
  }

  .language-currency ul {
    background: #fff;
    color: inherit;
  }

  .menu-box {
    color: #fff;
  }

  .search-form-wrap,
  .input-search {
    color: $text-color;
  }

  .search-form-inner {
    background: #fff
  }
}

.project-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-form-wrap {
  border-bottom: 1px solid transparent;
  width: 19.2rem;
  display: flex;
  color: #fff;
  justify-content: flex-end;

  &.opened {
    .search-form-inner {
      width: 65rem
    }

    .search-form {
      width: 55rem
    }

    .link-search__text {
      display: none
    }

    .link-search {
      width: 4.2rem;
    }

    .search-form {
      width: calc(100% - 4.2rem)
    }
     .wrap-search {
      width: 100%
     } 
    .search-form-inner-inner {
      padding: 0 5rem;
    }
  }
}

.search-form-inner {
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
  background: #1F1F1F;
  min-width: 100%;
  flex-shrink: 0;
  display: flex;
  transition: width .7s ease;
  position: relative;
}

.search-form-inner-inner {
  background: rgba(255, 255, 255, 0.05);
  justify-content: center;
  display: flex;
  padding: 0 2rem;
  transition: padding .7s ease;
  min-width: 100%;
}

.link-search {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;

  &__text {
    margin-left: .6rem;
    display: flex;
    align-items: center;

    span {
      border-bottom: 1px solid transparent;
      transition: border-bottom .7s ease;
    }
  }

  &:hover {
    .link-search__text span {
      border-bottom-color: #fff
    }
  }
}

.search-form {
  width: 0;
  overflow: hidden;
  transition: width .7s ease;
  display: flex;
  align-items: center;

  form {
    display: flex;
    align-items: center;
    width: 100%
  }
}

.search-icon {
  top: -.2rem;
  position: relative;
  width: 3.3rem;
  display: flex;
  align-items: center;

  i {
    font-size: 2.5rem;
  }
}

.btn-search {
  border-width: 0;
  background: transparent;
  width: 3.3rem;
  font-size: 1.5rem;
  color: inherit;
  width: 8.2rem;
  height: 6.4rem;
  transition: background .7s ease, color .7s ease;

  &:hover {
    background: $color-active;
    color: $text-color;
  }
}

.input-search {
  width: calc(100% - 8.2rem);
  border-width: 0;
  background: transparent;
  padding: 0;
  color: #fff;
  transition: width .7s ease;
}

.search-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  color: $text-color;

  &-inner {
    background: #fff;
    margin-top: 10px;
  }

  .ui-menu {
    position: static !important;
    border: 0 !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .04);
    padding: 5.5rem 8.5% 40px 11.5%;
  }

  ul {
    font-size: 1.6rem;
    font-weight: 500;
    list-style: none;
    width: 100% !important;

  li a {
    color: $text-color;
    pointer-events: auto;
  }
  a:not(.btn) {
    display: inline-block;
    width: 100%;
  }
    a:not(.btn):hover {
      color: $color-active;
    }
  }

  li {
    pointer-events: none;
  }


  li+li,
  .ui-menu .ui-menu-item+.ui-menu .ui-menu-item {
    margin-top: 2.6rem !important
  }

  .ui-menu .ui-menu-item-wrapper {
    padding: 0 !important
  }

  .ui-widget.ui-widget-content {
    background: transparent !important;
  }
}

.search-btn {
  margin-top: 4rem;
}

.btn_search {
  height: 5rem;
  line-height: 5rem;
  font-size: 1.6rem;
  font-weight: 400;

  b {
    font-weight: 500;
  }

  i {
    width: auto;
    margin-left: 1rem;
  }

  &:hover {
    margin-left: 0;
    transition: margin-left .5s ease;
  }
}

@media screen and (max-width: 1600px) {
  .menu-box {
    padding-top: 4rem
  }
}


@media screen and (max-width: 1599px) {
  .nav-logo {
    height: 10rem;
    display: flex;
    align-items: center;
  }

  .menu-box {
    top: 10rem;
  }
}

@media screen and (max-width: 1280px) {
  .header__login {
    padding-left: 5rem
  }

  .header__projects {
    padding-right: 5rem
  }

  .language-currency {
    padding: 0 3.6rem;
  }
}

@media screen and (max-width: 1023px) {
  .menu-box .wrapper {
    padding: 0
  }

  .nav-logo {
    padding: 1.1rem 0 0.8rem;
    height: 4.6rem
  }

  .nav-logo img {
    width: 8.4rem;
  }

  .menu-box {
    padding: 0 0 3.5rem;
    top: 7.1rem;
    background-size: 84% auto;
    background-position: 181% 100%;
  }

  .menu-title {
    font-size: 1.6rem;
    margin-bottom: 0
  }

  .menu-title a,
  .menu-title span {
    opacity: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .menu-title i {
    opacity: 1
  }

  .nav-inner>ul {
    width: 100%
  }

  .nav-inner>ul>li {
    border-bottom: 1px solid rgba($line, .3);
    margin: 0;
    padding: 2.1rem 2.4rem;
    width: 100%;

  }

  .nav-inner>ul>li:first-child,
  .nav-inner>ul>li:last-child {
    width: 100%;
  }

  .nav-inner>ul>li a,
  .nav-inner>ul>li>span>span {
    color: #fff;
    padding: 10px 0;
    display: flex;
    align-items: center
  }

  .nav-inner {
    margin: 0;
    width: 100%
  }

  .nav-inner>ul {
    display: block;
  }

  .nav-inner>ul>li .menu-dropdown .menu-title a,
  .nav-inner>ul>li .menu-dropdown .menu-title>span {
    padding: 0;
    border-bottom-width: 0;
    opacity: .5;
    text-align: right;
  }

  .nav-inner>ul>li .menu-title a {
    border-bottom-width: 0
  }

  .nav-inner>ul>li i {
    display: inline-block;
    font-size: 50%;
    margin: 0 0 0 1rem;
    padding: 1rem 0;
    cursor: pointer;
  }

  .menu-dropdown {
    margin: 0;
    position: static;
    background: transparent;
    min-width: 0;
    max-width: 100%
  }


  .nav-inner>ul>li:hover .menu-dropdown {
    display: none
  }

  .nav-inner>ul>li.active .menu-title {
    display: none
  }

  .nav-inner>ul>li.active .menu-dropdown {
    display: block;
  }

  .nav-inner>ul>li.active .menu-dropdown .menu-title {
    color: #838EA4;

    padding: 2.5rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(181, 181, 181, 0.3);
  }

  .nav-inner>ul>li.active i {
    margin-left: 0
  }

  .menu-dropdown li {
    padding: .7rem 2.4rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(181, 181, 181, 0.3);
  }

  .menu-dropdown__col {
    padding: 0;
    width: 100%
  }

  .nav-inner>ul>li .menu-dropdown a {
    padding: 1rem 0
  }

  .header-links-2 .btn-login {
    color: #fff
  }

  .nav-inner>ul>li:last-child a {
    border-bottom-width: 0
  }

  .opened-sub {
    .header-bottom {
      background: #2E3243
    }

    .footers-socials.header-socials,
    .language-currency {
      display: none;
    }
  }

  .opened-sub .nav-inner>ul>li {
    display: none;
    background-color: transparent
  }

  .opened-sub .nav-inner>ul>li.active {
    display: block;
    padding-top: 0;
    padding-bottom: 0
  }

  .opened-sub .nav-inner>ul>li:first-child {
    border-top-color: transparent
  }

  .menu-dropdown-list {
    display: block
  }

  .menu-dropdown {
    display: none;
  }

  .menu-dropdown__title {
    padding: 1.2rem 3rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
    border-bottom: 1px solid #515665;
    color: #515665
  }

  .nav-inner>ul>li:hover .menu-title a {
    color: #fff
  }

  .nav-inner>ul>li>.menu-title {
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
  }

  .nav-inner>ul>li.active>.menu-title {
    color: #00AEE6
  }

  .header__login {
    padding: 1rem 1rem 1rem 3rem;
    border-left: 1px solid #808080;
    order: 1;
  }

  .bars-mob .hamburger span {
    width: 3rem;
    margin: 0 0 .7rem;
  }

  .bars-mob .hamburger span:nth-child(2) {
    width: 2rem
  }

  .bars-mob.active .hamburger span:nth-child(2) {
    width: 0;
  }

  .bars-mob {
    width: 5.9rem;
  }

  .bars-mob .hamburger {

    margin: .7rem 0 0 2rem;
  }

  .bars-mob .cross {
    margin: 2.4rem 0 0 3.1rem;
    height: 2.6rem;
    width: 2.6rem;
  }

  .language-currency {
    margin: 0;
    padding: 3.1rem 2.4rem 2.5rem;

    border-width: 0;

    li {

      display: inline-flex;
      margin: 0 5rem 0 0;
      padding: 0.4rem 0.1rem 0.4rem 0;

      &:last-child {
        margin-right: 0;
      }

    }

    a {
      opacity: .3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 2rem;
      opacity: .3;
      border-bottom-color: transparent;

      &:hover {
        opacity: 1;
      }
    }

    ul {
      position: static;
      opacity: 1;
      pointer-events: auto;
      transform: none;
      padding: 0;
      background: none;
      background: transparent;
      border-width: 0;
    }


    .curent {
      color: #fff;
      opacity: 1;
      display: inline-block;
      border-bottom-color: #fff;
    }

    &:hover ul {
      transform: none
    }
  }


  .current-language {
    display: none;
  }


  .footers-socials.header-socials {
    margin: 0;
    border-top: 1px solid rgba($line, .3);
    padding: 2.9rem 2.4rem;
    width: 100%;

    .socials {
      justify-content: flex-start;
    }

    .socials__link img {

      filter: brightness(0) invert(1);
      max-height: 2.4rem;
      height: 2.4rem;
    }
  }

  .footers-socials.header-socials .socials__item {
    margin-left: 3.5rem;
  }

  .nav-logo {
    margin-right: 1.5rem;
    max-width: 14.7rem;
    min-height: 7rem
  }

  .main-menu {
    margin: 0;
  }

  .main-menu a {
    font-size: 1.8rem;
  }

  .main-menu li {
    margin: 0 0 3.6rem;
  }

  .menu-dropdown ul a {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .menu-dropdown-list span:before {
    display: none;
  }

  .menu-dropdown ul a:hover {
    background: transparent;
    color: #fff;
  }

  .header__login span,
  .header__projects span {
    display: none;
  }

  .header__projects .projects-count {
    display: inline-block;
  }

  .header__projects {
    padding: 1rem 1.5rem 1rem 1rem
  }

  .header__projects i {
    margin-right: 0;
    font-size: 2.9rem
  }

  .header__login i {
    font-size: 2.1rem;
  }

  .header-white {
    .language-currency {
      border-width: 0
    }

    .language-currency ul {
      background: transparent;
      border-width: 0;
      text-align: left
    }
  }

  .menu-dropdown-2 {
    margin: 0 -2.4rem;
    max-width: calc(100% + 4.8rem)
  }

  .header__login .logined-icon {
    font-size: 42%;
  }

  .link-search__text {
    display: none;
  }

  .search-form-wrap {
    order: 2;
  }

  .header-project-link {
    order: 3;
  }

  .bars-mob {
    order: 4;
  }

  .search-form-inner {
    border-right-width: 0;
    border-left-width: 0
  }

  .search-icon {
    width: auto;
  }

  .search-icon i {
    font-size: 2.2rem;
  }

  .search-form-wrap {
    width: 7.6rem;
    padding: 0 1.5rem 0 1rem;
  }

  .search-form {
    overflow: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 7.1rem;
    display: flex;
    background-color: #1f1f1f;
    width: 100%;
    color: #fff;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(-150%);
    transition: transform 1s ease;

    .menu-title {
      padding: 2.1rem 2.4rem 2rem 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(181, 181, 181, 0.3);

      i {
        display: inline-block;
        font-size: 50%;
        margin: 0 0 0 1rem;
        padding: 1rem 0;
        cursor: pointer;
      }

      span {
        opacity: 0.5;
        text-align: right;
      }
    }

    form {
      padding: 1.9rem 1.9rem 1.9rem 2.4rem;
      background: rgba(255, 255, 255, 0.15);
    }

    .language-currency ul {
      text-align: left;
    }

    .category-title,
    .double-code {
      width: 100%
    }
  }

  .search-form-inner-inner {
    padding: 0
  }

  .search-form-wrap.opened {
    .search-form {
      transform: translateY(0);
    }

    .search-form-inner {
      width: 5.1rem
    }

    .search-form-inner-inner {
      padding: 0
    }

    .search-form,
    .link-search {
      width: 100%;
    }

    .link-search {
      justify-content: center;
    }
  }

  .input-search {
    width: calc(100% - 5rem);
    padding-left: 1.5rem
  }

  .search-form {
    [type="text"] {

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        background: #404040;
        border-color: #404040;
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: inset 0 0 100px 40px #404040;
      }

    }
  }

  .search-dropdown {
    position: static;
    width: 100%;

    .ui-menu {
      padding: 3.5rem 2.7rem;
    }
  }

  .search-dropdown-inner {
    background: rgba(#fff, .2);
    color: #fff;
  }

  .search-dropdown li+li {
    margin-top: 1.9rem;
  }

  .search-dropdown ul a:not(.btn) {
    color: #fff;
  }

  .wrap-search {
    width: 100%
  }

  .header-white .input-search {
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .header__login .loginned span {
    display: none
  }
}

@media screen and (min-width: 1024px) {
  body:not(.header-white) {
    .search-form [type="text"] {

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        background: #2a2a2a;
        border-color: #2a2a2a;
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: inset 0 0 100px 40px #2a2a2a;
      }

    }

  }

  .header-white {
    .search-form [type="text"] {

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        background: #fff;
        border-color: #fff;
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: inset 0 0 100px 40px #fff;
      }

    }

  }

  .header-white .input-search {
    &::-webkit-input-placeholder {
      color: #cdcdcd;
    }

    &::-moz-placeholder {
      color: #cdcdcd;

    }

    &:-moz-placeholder {
      color: #cdcdcd;
    }

    &:-ms-input-placeholder {
      color: #cdcdcd;
    }
  }
  .double-code {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .header {
    height: 10rem
  }

  .menu-box {
    top: 10rem;
  }
}
