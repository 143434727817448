.page-osnova {

	.h1:after,
	.h2:after {
		background: $color-active-osnova;
	}

	.btn {
		background: $color-active-osnova;
		border-radius: 5rem;
	}

	.btn:disabled {
		background: #E3E3E3;
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="password"],
	textarea {
		border-radius: 5rem;
	}

	&:not(.header-white) {
		.header {
			background-image: none;
			background-color: transparent;
		}

		.search-form-inner {
			border-left-color: #B5B5B5;
			border-right-color: #B5B5B5;
			background: rgba(255, 255, 255, 0.05);
		}

		.header__info:before {
			left: 19.2rem;
		}


	}

	.section-dark,
	&:not(.header-white) .header.fixed {
		background: linear-gradient(270deg, rgba(146, 81, 237, 1) 0%, rgba(84, 21, 188, 1) 100%);
	}

	.section-consultation,
	.section-dark {

		[type="text"],
		[type="tel"],
		[type="email"],
		[type="password"],
		textarea {
			color: #fff;
			border-radius: 5rem;
			border: 1px solid #FFF;
			background: rgba(255, 255, 255, 0.40);

			&::-webkit-input-placeholder {
				color: #fff;
			}

			&::-moz-placeholder {
				color: #fff;

			}

			&:-moz-placeholder {
				color: #fff;
			}

			&:-ms-input-placeholder {
				color: #fff;
			}
		}

	}

	textarea {
		border-radius: 1.6rem
	}

	.section-top {
		background-image: url(../img/header-bg.svg), linear-gradient(270deg, rgba(146, 81, 237, 1) 0%, rgba(84, 21, 188, 1) 100%);
		background-position: 100% 0%;
		background-repeat: no-repeat, no-repeat;
		background-size: auto 100%, 100% 100%
	}

	.product-head {
		width: 50%;

		.h1 {
			overflow: visible;
		}
	}

	.language-currency,
	.header__info,
	.bars-mob {
		border-left-color: #B5B5B5
	}

	.subcategories__item {
		border-radius: 1.6rem;
		background: #F8F8F8;
		border-color: #F8F8F8;
		overflow: hidden;
	}

	.subcategories__head {
		width: 57.5%;
		padding-bottom: 4rem;
		justify-content: center;
	}

	.subcategories__description {
		padding-bottom: 0
	}

	.subcategories__btn {
		padding: 0 6.4rem;
	}

	.subcategories__img {
		padding: 6.5rem;
		background: rgba(231, 212, 255, 1);
		width: 42.5%
	}

	.subcategories__item_reverse .subcategories__btn {
		text-align: left;
	}

	.subcategories_2 {
		.subcategories__img {
			background: #C9C9E8
		}
	}

	.section-top-middle {
		background-image: url(../img/header-bg.svg), linear-gradient(270deg, rgba(81, 97, 237, 1) 0%, rgba(43, 41, 143, 1) 100%);
	}

	.section-product-head {
		color: #fff;

		.wrapper {
			z-index: 1
		}
	}

	.section-head {
		padding: 4rem 0 7.3rem;
	}

	.product-img {
		background: none;
		z-index: 0
	}

	.product-head {
		.h1:after {
			background: #fff;
		}
	}

	.checkbox-el .check {
		border-radius: .8rem;
		background: #FCFCFC;
	}

	.checkbox-el input:checked~.checkbox-el__txt .check {
		background: $color-active-osnova;
	}

	.section-top.main-section-inner,
	.section-top.article-main {
		background: none
	}

	.projects-nav {
		a {
			border-radius: .8rem;

			&.active,
			&:hover {
				background: #5F01D2
			}
		}
	}

	.hero-image,
	.hero-image img,
	.video_wrap img,
	.video_wrap {
		border-radius: 1.6rem
	}

	.video_wrap {
		max-width: 142.8rem;
		margin-left: auto;
		margin-right: auto;
	}

	.article-inner-slider__item {
		img {
			border-radius: 1.6rem
		}
	}

	.article blockquote {
		border-radius: 1.6rem;
		background: #F8F8F8;
		border: 0;
		padding: 2.9rem 9% 2.9rem 7.4rem;
		position: relative;
		@extend .icon-quotes;

		&:before {
			position: absolute;
			left: 0;
			top: 4.1rem;
			line-height: 1;
			transform: translateX(-50%);
			font-size: 5.7rem;
			font-family: icomoon;
			color: #5f01d2;
		}
	}

	.section-subcategoies {
		padding-bottom: 2rem;

		.subcategories__item:last-child {
			margin-bottom: 0;
		}
	}

	.section-subcategoies+.section-exellences {
		padding-top: 6rem;
	}

		&:not(.header-white) {
			.opened .search-form-inner-inner {
				background: #874be2;
			}

			&.page-osnova-dark {
				.opened .search-form-inner-inner {
					background: #545ccd;
				}
			}

		}
	@media screen and (max-width: 1365px) {
		.article blockquote {
			font-size: 2.1rem
		}


	}

	@media screen and (max-width: 1023px) {
		.article blockquote {
			padding: 2.5rem 7% 2.5rem 5.4rem;

			&:before {
				font-size: 4rem
			}
		}

		&:not(.header-white) {
			.search-form-inner-inner, .search-form-inner {
				background: transparent;
			}

			.header__info:before {
				left: 0;
			}
		}
	}

	@media screen and (max-width: 767px) {

		.subcategories__head,
		.subcategories__img {
			width: 100%;
		}

		.subcategories__img {
			padding: 2rem;
		}

		.article blockquote {
			font-size: 1.6rem;
			padding: 1rem 2rem .8rem 3.9rem;
		}

		.article blockquote:before {
			font-size: 3rem;
			top: 1.8rem;
			left: .5rem;
		}

		.product-head__title {
			margin-top: 7rem;
		}

		.product-img {
			margin-top: 2.1rem;
		}

		.section-product-about {
			padding: 4rem 0 1.1rem;
		}

		.checkbox-el__txt {
			align-items: flex-start;
		}

		.checkbox-el .check {
			margin-right: 2.5rem
		}

		.checkbox-el__txt {
			line-height: 2rem;
		}

		.box-field_project-checkbox {
			margin-bottom: 2.6rem;
			min-height: 0;
		}

		.section-top {
			background-image: url(../img/header-bg_mob.svg), linear-gradient(270deg, rgba(146, 81, 237, 1) 0%, rgba(84, 21, 188, 1) 100%);
		}

		.subcategories__btn {
			padding: 0;
			text-align: center;
		}

		.product-head {
			width: 100%;
		}

		.breadcrumb li {
			flex-wrap: wrap;
		}

		.subcategories__item_reverse .subcategories__btn {
			text-align: center;
		}
	}

	@media screen and (min-width: 1023px) {
		.section-product-head {
			height: 45.4rem;
			min-height: 0
		}

		.section-head {
			min-height: 40.4rem
		}

		.product-img {
			width: 53.5%;
			padding-right: 4.2%;
		}

		.product-head__title {
			margin-top: 6rem;
		}

		.breadcrumbs {
			margin: 3.3rem 0 0;
		}

		.subcategories__item {
			margin-bottom: 6rem
		}

		.section-head__title {
			width: 59%;
		}
	}

	@media screen and (min-width: 1024px) {
		&:not(.header-white) {
			.search-form [type="text"] {

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					background: #874be2;
					border-color:  #874be2;
					-webkit-text-fill-color: #fff;
					-webkit-box-shadow: inset 0 0 100px 40px #874be2;
				}
				&::-webkit-input-placeholder {
					color: #fff;
				}

				&::-moz-placeholder {
					color: #fff;

				}

				&:-moz-placeholder {
					color: #fff;
				}

				&:-ms-input-placeholder {
					color: #fff;
				}
			}

			&.page-osnova-dark {
				.search-form [type="text"] {

					&:-webkit-autofill,
					&:-webkit-autofill:hover,
					&:-webkit-autofill:focus {
						border-color: #545ccd;
						background: #545ccd;
						-webkit-text-fill-color: #fff;
						-webkit-box-shadow: inset 0 0 100px 40px #545ccd;
					}

				}
			}
		}
	}
	@media screen and (min-width: 1600px) {
		.product-head .h1-md {
			font-size: 7rem;
		}

		.section-product-about {
			padding: 14.4rem 0 14.8rem;
		}

		.section-video-product {
			padding: 3.1rem 0 3rem;
		}

		.section-create-project {
			padding: 5rem 0 5.2rem;
		}

		.subcategories__item {
			margin-bottom: 8rem
		}

		.page-osnova .subcategories__item {
			min-height: 63rem;
		}

		.subcategories__description {
			padding: 31px 14% 40px
		}
	}

	@media screen and (min-width: 1800px) {
		.product-head .h1-md {
			font-size: 8rem;
		}
	}
}

.page-osnova-dark {
	.section-top {
		background-image: url(../img/middle-bg.svg), linear-gradient(270deg, rgba(81, 97, 237, 1) 0%, rgba(43, 41, 143, 1) 100%);
		background-position: 100% 0%;
		background-size: 70% auto, 100% 100%
	}

	&:not(.header-white) .header.fixed {
		background-image: linear-gradient(270deg, rgba(81, 97, 237, 1) 0%, rgba(43, 41, 143, 1) 100%)
	}

	@media screen and (max-width: 767px) {
		.section-top {
			background-image: url(../img/middle-bg_mob.svg), linear-gradient(270deg, rgba(81, 97, 237, 1) 0%, rgba(43, 41, 143, 1) 100%);
		}
	}
}


.blog-list.cases {
	.projects__item {
		margin: 0 1.8rem 3.6rem;
		width: calc(33.333% - 3.6rem);
		flex: 0 0 auto;
	}

	.projects__img {
		&:before {
			display: none;
		}

		img {
			position: static;
			height: auto;
			transform: none
		}
	}
}

.article-info__place {
	padding: 0 1.5rem;
	color: #B5B5B5;
	text-transform: uppercase;
	line-height: 1.05;
	font-size: 1.6rem;
	letter-spacing: .1em;
	display: inline-block;
	border-left: 1px solid #5F01D2;
	border-right: 1px solid #5F01D2;
}

.case-about {
	border-radius: 1.6rem;
	background: #F8F8F8;
	padding: 4.8rem 4rem 4rem 4.2rem;
	display: flex;

	span.wow {
		display: inline-block;
	}

	&__head {
		padding-right: 4rem;
		width: 45.5%
	}

	&__description {
		padding-left: 4rem;
		width: 55.5%;
	}

	&__text {
		overflow: hidden;
	}

	&__subtitle {
		font-weight: 700;
		overflow: hidden;
	}

	&__label {
		overflow: hidden;
		margin-bottom: 1.4rem;
	}

	&__value {
		font-size: 3rem;
		font-weight: 500;
		overflow: hidden;
	}

	&__item {
		margin-bottom: 4.6rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 1365px) {
	.case-about {
		&__head {
			padding-right: 3rem;
			width: 48.5%
		}

		&__description {
			padding-left: 3rem;
			width: 51.5%;
		}
	}

}

@media screen and (max-width: 1023px) {
	.blog-list.cases .projects__item {
		width: calc(33.333% - 3.6rem);
	}
}

@media screen and (max-width: 767px) {
	.blog-list.cases {
		margin: 0;

		.projects__item {
			margin: 0 0 1.6rem;
			width: 100%;
		}
	}

	.case-about {
		display: block;

		&__head {
			padding-right: 0;
			width: 100%
		}

		&__description {
			padding-left: 0;
			width: 100%;
		}
	}

	.case-about {
		padding: 1.6rem 2rem 2rem 1.9rem;
	}

	.case-about__value {
		font-size: 2rem;
	}

	.case-about__label {
		margin-bottom: .8rem;
	}

	.case-about__item {
		margin-bottom: 1.6rem;
	}

	.case-about__description {
		padding-left: 0;
		width: 100%;
		margin-top: 25px;
	}

}