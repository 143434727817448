.section-login {
	display: flex;
	padding: 10rem 0 0;

	&__decor {
		padding: 40px;
		background: #1F1F1F;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__content {
		width: 50%;

	}
}

.btn_login {
	width: 100%
}

.login {
	max-width: 72.6rem;
	padding: 5rem 3rem 5rem 15%;
	.back-arrow {
		margin: 0 0 14.5rem;
	}
}
.forgot-password-text {
	margin-top: -2rem;
    margin-bottom: 4rem;
    font-size: 1.8rem;
    max-width: 47rem;
}
.login-links {
	margin-top: 5.4rem;
	font-size: 1.8rem;
	color: #808080;
	max-width: 38rem;
	&__item+&__item {
		margin-top: 2.1rem
	}

	&__link {
		color: $text-color;
		border-bottom: 1px solid;

		&:hover {
			color: $color-active;
		}
	}
}
.box-field__login {
	margin: 2.5rem 0 0;
}

@media screen and (max-width: 1280px) {
	.login {
		padding-left: 4rem
	}
}
@media screen and (max-width: 767px) {
	.section-login {
		display: block;
		padding-top: 7rem
	}
	.section-login__decor {
	    width: 100%;
	    padding: .9rem 2.4rem 3.2rem;
	    height: 26.5rem;
	    justify-content: flex-start;
	    svg {
		    max-height: 62%;
		    width: auto;
		}
	}

	.section-login__content {
		width: 100%;
	}

	.login {
		padding: 4.2rem 2.4rem 3.6rem
	}

	.login-links {
		margin-top: 4rem;
		font-size: 1.6rem;
	}
	.login-title {
		font-size: 2rem
	}
	.box-field__login {
	    margin: 0.3rem 0 0;
	    .btn {
	    	height: 5rem;
	    	line-height: 5rem;
	    	font-size: 1.6rem
	    }
	}
	.login-links__item + .login-links__item {
	    margin-top: 2.4rem;
	}
	.login .back-arrow {
	    margin: 0 0 3.8rem;
	}
	.forgot-password-text {
	    margin-top: -1rem;
	    margin-bottom: 2.5rem;
	    font-size: 1.6rem;
	    line-height: 1.3;
	}
}