.section-top {
    padding-top: 10rem
}

.main-block {
    margin-top: 14rem;
    padding-bottom: 2rem;
}

.section-text {
    max-width: 63rem;
    font-size: 3rem;
    line-height: 4rem;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        margin-top: 1.5rem;
        border: 0;
        height: 0.4rem;
        width: 9rem;
        background: $color-active
    }
    span {
        display: inline-block;
    }
}

.links-cat {
    margin: 9.4rem -0.4rem 0;
    display: flex;
    flex-wrap: wrap;

    &__item {
        background: #282828;
        margin: 0.4rem;
        width: calc(50% - .8rem);
        display: flex;
        align-items: flex-end;
        position: relative;

        &_big {
            padding-top: 25.3rem;
            width: calc(100% - .8rem);
        }

        &:hover {
            background: var(--bgColor);

            .links-cat__img {
                opacity: 0;
            }

            .links-cat__img_hover {
                opacity: 1;
            }

            &[style*=fff] {
                .links-cat__title {
                    color: $text-color;
                }
            }
        }
            &:nth-child(1), &:nth-child(2) {
                .links-cat__picture img,.links-cat__picture video {
                    width: 100%;
                    height: 100%;
                    object-position: 50% 100%;
                    object-fit: cover;
                }
            }
    }

    &__img {
        vertical-align: top;
    }

    &__picture {
        position: relative;
        ;
        z-index: 0;
        width: 100%;
        text-align: center;
    }

    &__title {
        padding: 4.3rem 4.9rem 2rem 4.9rem;
        font-weight: 500;
        font-size: 3rem;
        left: 30px;
        right: 30px;
        top: 20px;
        position: absolute;
        z-index: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        span {
            display: inline-block;
        }

        i {
            margin-left: 20px;
            flex-shrink: 0;
            display: inline-block;
            font-size: 53%
        }
    }
}

.links-cat__img,
.links-cat__img_hover {
    transition: opacity 0.7s;
    vertical-align: top;
    object-position: 50% 100%
}

.links-cat__img_hover, .links-cat__img_hover-mob {
    opacity: 0;
    position: absolute;
    left: 50%;
    bottom: 0;
    top: 0;
    transform: translateX(-50%);
}

video.links-cat__img_hover {
    width: 100%;
}
@media screen and (max-width: 1366px) {
    .main-block {
        margin-top: 9rem;
    }

    .links-cat__title {
        padding: 2.3rem 3.9rem 2rem 2.9rem;
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 1023px) {
    .section-top {
        padding-top: 7rem;
    }
}

@media screen and (max-width: 767px) {
    .section-text {
        font-size: 2rem;
        line-height: 2.7rem;
    }

    .section-text:after {
        width: 6.3rem
    }

    .main-block {
        margin-top: 3.6rem;
    }

    .links-cat__item {
        margin: 0 0 1.6rem;
        width: 100%;
        min-height: 18.3rem;

        &_big {
            width: 100%
        }
    }

    .links-cat {
        margin: 2.4rem 0 0;

        &__item {

            background: var(--bgColor);

            .links-cat__img {
                opacity: 0;
            }

            .links-cat__img_hover, .links-cat__img_hover-mob {
                opacity: 1;
            }
            .links-cat__img_hover-mob +  .links-cat__img_hover {
                opacity: 0
            }

            &:first-child {
                .links-cat__title {
                    color: $text-color;
                }
            }
            &:nth-child(2) {
                padding-top: 6rem;
                .links-cat__picture .links-cat__img_hover-mob{
                height: auto;
                width: auto
            }
            }
        }
    }

    .links-cat__title {
        padding: 0;
        font-size: 1.6rem;
        left: 2.4rem;
        right: 2.4rem;
        top: 2.2rem;
    }

    .links-cat__item_big {
        padding-top: 0
    }
}