.footer {
	background: #000;
	position: relative;
	z-index: 3;
	overflow: hidden;
	flex-shrink: 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #000;

	.wrapper {
		&:after {
			display: none
		}
	}

	a {
		color: inherit;
	}


	.logo img {
		width: 29.1rem;
	}

	.menu-title {
		opacity: 1;
		margin-bottom: 2.4rem;
		i {
			display: none;
		}
	}

	.menu-dropdown li {
		margin-bottom: 1.9rem;
	}
	.menu-title span{border-bottom: 1px solid transparent;display: inline;}
	.menu-title  a {
		display: inline-block;
	}
	.menu-title  a:hover span{
				border-bottom-color: $color-active
			}
			.menu-dropdown ul a span:before {
				display: none;
			}
	.menu-dropdown ul a span{ 
		padding-right: 0;
		border-bottom: 1px solid transparent
	}
	.menu-dropdown ul a:hover span{
				border-bottom-color: $color-active
			}
			.menu-dropdown ul a span:before {
				display: none;
			}
}

.footer-top {
	padding: 9rem 0 20px;
	background: #1F1F1F;
	background-size: auto 100%;
	background-position: 100% 100%;
	background-repeat: no-repeat;
}

.footer-bottom {
	padding: 4.5rem 0 4.2rem;
	font-size: 1.4rem;

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	a {
		color: inherit;

		&:hover {
			color: $color-active;
		}
	}
}

.footers-socials {
	flex-shrink: 0;
	width: 25%;

	img {
		max-height: 2.4rem;
		transition: filter .6s ease;
	}

	.socials__link:hover img {

		filter: brightness(0) invert(1);
	}
}

.politica {
	padding: 0 10px 0 0;
}


.footer-item {
	display: flex;
	margin-bottom: 1.6rem;

	i {
		color: $color-active;
	}
}


.footer-title {
	margin-bottom: 16px;

	a {
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
		font-family: $custom-font-family;
		color: #616161;

		&:hover {
			color: $color-active;
		}
	}
}


.footer-bottom {
	font-weight: 500
}

.footer-bottom .develop {
	color: $color-active;
}

.footers-socials, .link-btm {
	flex: 1;
}
.link-btm {
    text-align: right;
}
@media screen and (max-width:1023px) {
	.footer {
		.menu-dropdown {
			display: block;
		}

		.nav-inner>ul>li:hover .menu-dropdown {
			display: block;
		}

		.menu-title {
			font-size: 16px;
			opacity: .5;
			margin-bottom: 0.4rem;
		}

		.nav-inner>ul>li {
			padding: 0;
		}

		.nav-inner>ul>li i {
			display: none;
		}

		.menu-dropdown li {
			padding: 0;
			margin-bottom: 0;
			border-bottom-width: 0;
		}

		.menu-dropdown-2 {
			margin: 0;
		}

		.nav-inner>ul>li {
			border-bottom-width: 0;
			margin-bottom: 20px
		}
	}

	.link-btm {
		margin: 2.7rem 0 0;

	}

	.politica {
		display: inline-block;
	}

	.footer-top {
		padding: 36px 0 0;

		background-size: 84% auto;
		background-position: 181% 145%;
	}

	.footer-bottom {
		.wrapper {
			flex-wrap: wrap;
		}
	}

	.copyrights {
		margin-top: 21px;
		width: 100%;
	}

	.footers-socials {
		width: 100%;
		margin: 0;
		padding: 15px 0 15px;

		.socials__item {
			img {
				max-height: 3.2rem
			}

			&:last-child {
				margin-right: 0
			}
		}
	}
}

@media screen and (max-width:767px) {
	.footer-bottom {
		padding: 1.3rem 0 4.2rem;
	}

	.footers-socials .socials__item img {
		max-height: 2.4rem;
	}

	.socials__item {
		margin-right: 3.7rem;
	}

	.copyrights {
		margin-top: 17px;
	}

	.link-btm span {
		display: block;
		margin-top: .9rem;
	}
}