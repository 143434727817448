.breadcrumbs {
    margin: 7.3rem 0 5.8rem;
}

.breadcrumb li:after {
    content: '/';
    margin: 0 .5rem
}

.section-dark .breadcrumbs {
    margin: 0 0 5.8rem;
}


.breadcrumb {
    color: rgba(#fff, .7);
    font-size: 1.6rem;
    line-height: 2rem;
    list-style: none;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;

    li {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
    }

    li:first-child {
        font-size: 2.4rem;

        a {
            color: $color-active;

            &:hover {
                color: #fff;
            }
        }
    }

    li:last-child {
        color: #fff;

        &:after {
            display: none;
        }
    }

    i {
        line-height: inherit;
    }
}

.breadcrumb a {
    color: inherit;

    &:hover {
        color: $color-active;
        opacity: 1
    }
}

.header-white {

    .breadcrumb {

        color: #B5B5B5;
    }

    .breadcrumb {
        li:last-child {
            color: $text-color;

        }

        li:first-child {
            a {

                &:hover {
                    color: $text-color;
                }
            }
        }
    }

}

.section-subtext {
    font-weight: 700
}

.categories {
    margin: 10rem -1.7rem 0;
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: calc(50% - 3.4rem);
        margin: 2.6rem 1.7rem;
        border: 1px solid $line;
        background: #FCFAF1;
        position: relative;
        text-decoration: none;
        color: $text-color;

        &:hover {
            border-color: $color-active;

            .categories__title span {
                border-bottom-color: $text-color;
            }

            .btn {
                i {
                    margin-left: 5.8rem
                }

            }
        }

        &_1 {
            .categories__img {
                width: 75%;
                bottom: 23%;

                img {
                    left: auto;
                    top: 0;
                    right: 0;
                    transform: none;
                }
            }
        }
    }

    &__head {
        padding: 7.8rem 0 0;
        position: relative;
        min-height: 48rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
        overflow: hidden
    }

    &__title {
        max-width: 50%;
        padding: 0 0 0 6rem;
        font-size: 40px;
        line-height: 1.25;
        font-weight: 500;
        z-index: 1;
        position: relative;

        div {
            padding-bottom: 1px;
        }

        span {
            border-bottom: 1px solid transparent;
            transition: border-bottom-color .6s ease
        }
    }

    &__img {
        width: 62%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        display: flex;
        align-items: center;

        img {
            position: absolute;
            max-height: 100%;
            max-width: 100%;
            left: 50%;
            top: 50%;
            position: absolute;
            display: inline-block;
            transform: translate(-50%, -50%);
            vertical-align: top
        }
    }

    &_2 {
        .categories__item {
            background: transparent;
        }
    }
}

.categories__item_down .categories__img {
    width: 74%;

    img {
        transform: translate(-50%, 0);
        top: auto;
        bottom: 0
    }
}

.section-head {
    padding: 7.5rem 0 40px;
    min-height: 61.5rem;
    display: flex;

    &__title {
        width: 50%;
        padding-right: 10%
    }

    &__decor {
        width: 50%;
        text-align: center;

        svg {
            max-width: 100%;
            height: auto
        }
    }

    .h1 {
        max-width: 100%
    }

    &_2 {
        padding-bottom: 0;
        min-height: 45.4rem;

        .section-head__decor {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    &__title-contacts {
        width: 47rem
    }

    &__contacts {
        width: calc(100% - 47rem);
        justify-content: flex-end;
    }

}

.section-dark {

    .breadcrumb li:not(:first-child) a {
        opacity: .7
    }
}

.section-top-product {
    position: relative;

    .product-img {
        padding-top: 10rem;
        background: none;
        align-items: flex-end;
        justify-content: flex-end;

        img {
            vertical-align: top;
            max-height: 100%
        }
    }
}

.section-subcategoies {
    padding: 10rem 0 10rem
}

.subcategories {
    margin-bottom: 40px;

    &__item {
        margin: 2.6rem 0 7.9rem;
        border: 1px solid $line;
        position: relative;
        text-decoration: none;
        color: $text-color;
        display: flex;
        flex-wrap: wrap;
        transition: border-color .6s ease;

        &_reverse {
            flex-direction: row-reverse;

            .subcategories__btn {
                text-align: right;
            }
        }

        &:hover {
            border-color: $color-active;

            .subcategories__title span {
                border-bottom-color: $text-color;
            }

            .btn {
                i {
                    margin-left: 5.8rem
                }

            }
        }
    }

    &__head {
        width: 57%;
        min-height: 48rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
    }

    &__title {
        font-size: 4rem;
        line-height: 1.2;
        font-weight: 500;
        z-index: 1;
        overflow: hidden;
        padding-bottom: 3px;

        span {
            border-bottom: 1px solid transparent;
            transition: border-bottom-color .6s ease
        }
    }

    &__img {
        //background: #FCFAF1;
        width: 43%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 63.1rem;

        img {
            max-height: 60rem;
        }
    }

    &__category {
        color: $color2;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        overflow: hidden;
        text-transform: uppercase;
    }

    &__text {
        margin-top: 15px;
        line-height: 1.5
    }

    &__description {
        padding: 6.2rem 14%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 10;
    }

    &__btn {
        margin-top: 25px
    }
}

.section-product-head {
    position: relative;

    .wrapper {
        z-index: 1
    }
}

.product-head {
    width: 50%;
    padding: 4rem 0 4rem;

    &__title {
        margin-top: 10rem
    }

    .h1 {
        max-width: 100%
    }
}

.tovar-info {
    margin-top: 2.8rem;
    font-weight: 300;

    &__val {
        font-weight: 700
    }
}

.product-img {
    //background: #FCFAF1;
    width: 49%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-height: 100%
    }

    &_3d {
        background: transparent
    }
}

.btn_add-project {
    margin-top: 5.4rem;
    min-width: 40.4rem;
    max-width: 100%;
    padding: 0 1.9rem;

    &:hover i {
        margin-left: 3.8rem;
    }
}

.section-product-about {
    padding: 8.4rem 0 8.8rem
}

.product-about {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $line
}

.menu-navs {
    width: 23.5%;
    padding-top: 4.9rem;
    border-right: 1px solid $line;
    padding-right: 4rem;
    font-size: 2.4rem;

    a {
        padding: 1rem 0;
        margin-bottom: 1.8rem;
        display: block;
        color: $line;

        &:hover {
            color: $color-active;
        }

        &.active {
            pointer-events: none;
            color: $text-color
        }

        &:last-child {
            margin-bottom: 0
        }
    }
}

.product-about-tabs {
    width: 76.5%;
    padding-left: 4.5%;
    padding-top: 4.5rem;
    line-height: 1.5;

    ul {
        list-style: none outside;
    }

    ul li {
        position: relative;
        padding-left: 17px;

        &:before {
            content: '';
            display: block;
            background: $text-color;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 1.2rem;
        }
    }

    ul,
    ol {
        margin-left: 17px
    }

    li {
        margin-bottom: 10px
    }
}

.section-my-project {
    padding-top: 40px;
}

.cart-wrap {
    max-width: 142.8rem;
    margin: 0 auto;

}

.cart {
    font-size: 1.6rem;
    border: 1px solid $line;

    &__item {
        border-bottom: 1px solid $line;
        display: flex;
    }

    &__img {
        background: #FCFAF1;
        width: 17%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-height: 16.3rem;
        }
    }

    &__details {
        width: 83%;
        padding: 2.8rem 3.5rem;
        display: flex;
        justify-content: space-between;
    }

    &__remove {
        display: inline-flex;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
        padding: 9px 19px;
        border: 1px solid $text-color;
        transition: background .6s ease;

        i {
            margin-right: 15px;
        }

        &:hover {

            background: $color-active;
        }
    }

    &__head {
        padding-right: 3rem;
        width: 46%;
    }

    &__title {
        color: $text-color;
        font-size: 1.8rem;
        font-weight: 500
    }

    &__number {
        margin-top: 10px;
    }

    &__details-list {
        margin-top: 15px;
    }

    &__action {
        width: 20%;
        text-align: right;
    }

    &__detail {
        display: flex;

        &+& {
            margin-top: 10px
        }

        &-label {
            margin-right: 1rem
        }
    }

    &__date {
        padding-right: 4rem;
        width: 34%;

        &-value {
            margin-top: 5px;
            color: #808080;
        }
    }

    &__status {
        padding: .9rem 1.8rem;
        font-size: 1.6rem;
        background: $line;
        border-radius: .8rem;
        flex-shrink: 0;
        white-space: nowrap;
        display: inline-block;

        &_send {
            background: #FFDA22;
            border-radius: 8px;
        }

        &_order {
            background: #3AC88C;
        }

        &_closed {
            background: #3ab0c8;
        }

        &_done {
            background: #51c83a
        }
    }

    &-date {
        color: #808080;
        font-size: 1.6rem;
        margin-bottom: 15px
    }
}

.cart-btn {
    margin-top: 40px
}

.cart-remove-alert {
    display: none;
    margin-bottom: 3.6rem
}

.alert-block {
    background: #3AC88C;
    padding: 1.8rem 2.9rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;

    i {
        margin-right: 15px;
    }

    &_warning {
        background: #FF5050;
        color: #fff;
    }

    &_error {
        background: #FCF7B6;
    }
}

.cart-wrap-order {
    display: flex;

    .cart-wrap {
        width: 60%;
        margin-left: 5%
    }
}

.cart-order {
    width: 35%;

    .btn {
        width: 100%;
    }
}

.cart-order-done {
    display: flex;

    &__icon {
        width: 40%;
        padding-left: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: auto;
            max-width: 100%
        }
    }

    &__alert {
        width: 60%;

        .btn {
            width: 40.4rem;
            max-width: 100%;
        }
    }

    &__alert-text {

        a {
            color: $text-color;
            border-bottom: 1px solid;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }

    &__btn {
        margin-top: 35px
    }
}

.section-my-project_done {
    padding-bottom: 50px
}

.section-my-account {
    padding: 15.3rem 0 13.5rem
}

.my-account {
    margin-top: 12.9rem;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $line
}

.my-account-tabs {
    padding: 6.2rem 0 0 4.5%;
    border-left: 1px solid $line;
    margin-left: -1px;
    width: 76.5%;
    line-height: 1.5;

    .cart {
        margin-bottom: 37px;
        padding-bottom: 0;
        border-bottom: 1px solid $line;

        &:last-child {
            padding-bottom: 0;
        }
    }

    .alert-block {
        margin-bottom: 30px
    }

    .box-field__label {
        margin-bottom: 1.3rem;
    }
}

.my-account-form-password-wrap {
    margin-top: 0;
    border-top: 1px solid $line;
    padding-top: 3rem;
}

.show-password {
    position: absolute;
    font-size: 24px;
    color: #000;
    right: 5px;
    display: inline-block;
    top: 50%;
    cursor: pointer;
    padding: 5px;
    transform: translateY(-50%);
    z-index: 1;
    transition: color 0.3s ease;
}

.box-field__input {
    position: relative
}

.my-account-form {
    margin: 0 -1.8rem;
    display: flex;
    flex-wrap: wrap;

    .box-field {
        padding: 0 1.8rem;
        width: 50%;

        &__label {
            color: #808080;
        }
    }
}

.my-account-form-password {
    margin: 0 -1.8rem;
    display: flex;
    flex-wrap: wrap;

    .box-field {
        padding: 0 1.8rem;
        width: 33.333%
    }
}

.my-account-form-sbmit {
    margin-top: 25px
}

.btn-submit-my {
    width: 36rem;
    max-width: 100%
}

.my-account-form-password-title {
    margin-bottom: 30px;
    font-size: 3.2rem;
    font-weight: 500;
}

.box-field__hidden {
    display: none;

    &.shown-input {
        display: flex;
        align-items: center;
    }

    input {
        width: 100%;
        height: 6.4rem;
    }
    .jq-selectbox__select {
        height: 6.4rem;
        padding-top: 2rem
    }
    .jq-selectbox__trigger-arrow {
        top: 2rem;
    }
}
.shown-input .js-info-value {
    width: 100%
}
.accept-change {
    background: $color-active;
    width: 8.2rem;
    height: 6.4rem;
    margin-left: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        @extend .icon-arrow-short;
        font-size: 87%
    }
}

.box-field__info {
    display: flex;
    align-items: center;
}

.change-info {
    flex-shrink: 0;
    margin-left: 15px;
    cursor: pointer;
    transition: color .5s ease;

    &:hover {
        color: #808080;
    }
}

.login {
    .alert-block {
        margin-bottom: 40px
    }
}

.login-form {
    margin: 0 -1.8rem;
    display: flex;
    flex-wrap: wrap;

    .box-field {
        width: 100%;
        padding: 0 1.8rem;

        &_half {
            width: 50%
        }
    }
}

.back-arrow {
    margin-bottom: 40px;
}

.back-arrow__link {
    color: $text-color;
    display: inline-flex;
    align-items: center;

    i {

        margin-right: 10px;
        font-size: 55%;
        transition: margin-right .5s ease;
    }

    &:hover {
        i {
            margin-right: 25px;
        }
    }
}

.section-product-about-2 {
    background: #FCFAF1;
    padding: 8.5rem 0 10.8rem;
}

.product-about-2 {
    display: flex;
    flex-wrap: wrap;

    &__left {
        padding-right: 8%;
        width: 50%
    }

    &__right {
        padding-left: 2rem;
        width: 50%
    }

    &__label {
        font-size: 4rem;
        font-weight: 500;
        line-height: 1.275;
        margin-bottom: 1.1rem;
        overflow: hidden;
    }

    &__value {
        p {
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    ul {
        margin-top: .7rem;
        list-style: none outside;
    }

    ul li {
        position: relative;
        padding-left: 17px;

        &:before {
            content: '';
            display: block;
            background: $text-color;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 1.2rem
        }
    }

    ul,
    ol {
        margin-left: 17px
    }

    li {
        margin-bottom: 1.2rem
    }
}

.product-about-2__item {
    max-width: 74rem
}

.product-about-2__item+.product-about-2__item {
    margin-top: 5.7rem
}

.package-service__btn {
    margin-top: 4rem;
}

.section-blog {
    padding: 2rem 0 50px
}

.projects-nav {
    margin-bottom: 5rem;

    ul {
        list-style: none;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    li {
        margin: 0 2.4rem 1.5rem 0;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: inline-block;
        padding: .9rem 1.9rem;
        background: #E3E3E3;
        color: $text-color;
        transition: color .5s ease, background .5s ease;

        &.active,
        &:hover {
            color: #fff;
            background: $text-color
        }
    }
}

.blog-card {
    a {
        color: $text-color;
    }

    &__content {
        color: inherit;
    }

    &__info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 2.8rem
    }

    &__date {
        color: $color2;
        font-size: 1.6rem;
        line-height: 1.2;
        border-right: 1px solid #FFDA22;
        padding-right: 3.6rem;
    }

    &__category {
        color: $color2;
        font-size: 1.6rem;
        line-height: 1.2;
        text-transform: uppercase;
        border-right: 1px solid #FFDA22;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
    }

    &__title {
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 1.2;
        padding-bottom: 1px;

        a {
            border-bottom: 1px solid transparent;
            transition: border-bottom-color .5s ease;
        }
    }


    &__img {
        min-height: 11rem;

        img {
            width: 100%;
            vertical-align: top;
            border-radius: 1.6rem 1.6rem 0px 0px;
        }
    }

    &__details {
        height: 6rem;
        font-size: 2rem;
        padding: 0 1.9rem;
        position: absolute;
        z-index: 1;
        background: $text-color;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0;
        width: max-content;
        overflow: hidden;
        transition: background .5s linear, color .5s linear;

        i {
            font-size: 40%;
            margin-left: .6rem;
            transition: margin-left .5s linear;
        }

    }

    &__top {
        display: block;
        margin-bottom: 2.4rem;
        position: relative;
    }

    &:hover {
        .blog-card__title a {
            border-bottom-color: $text-color;
        }

        .blog-card__details {
            background: $color-active;
            color: $text-color;

            i {
                margin-left: 2.6rem;
            }

        }
    }
}

.blog-list {
    margin: 0 -1.8rem;
    display: flex;
    flex-wrap: wrap;

    .blog-card {
        padding: 0 1.8rem;
        margin: 0 0 5rem;
        width: 33.333%
    }
}

.article-info {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    &__info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 15px
    }

    &__date {
        color: $color2;
        font-size: 1.6rem;
        line-height: 1.2;
        border-right: 1px solid #FFDA22;
        padding-right: 3.6rem;
    }

    &__category {
        color: $color2;
        font-size: 1.6rem;
        line-height: 1.2;
        text-transform: uppercase;
        border-right: 1px solid #FFDA22;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
    }
}

.block-img-3 {
    padding: 6.2rem 0 3rem;
    margin: 0 -2.5rem;
    display: flex;

    figure {
        padding: 0 2.5rem;
        width: 50%;
        text-align: center;
    }

    h2 {
        margin-top: 0
    }
}

figcaption {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 1.3rem
}

.block-img-4 {
    padding: 5.2rem 0 3rem;
    margin: 0 -2.5rem;
    line-height: 1.5;
    display: flex;

    &__img {
        padding: 0 2.5rem;
        width: 41%;
    }

    &__picture {
        display: inline-block;
        overflow: hidden;
        background: $text-color;

        img {
            vertical-align: top
        }
    }

    &__description {
        padding: 0 2.5rem;
        width: 59%;

        p {
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    h2 {
        margin-top: 0
    }

    img {
        border-radius: .4rem;
    }

    &__block {
        padding-right: 12%;

        img {
            margin-bottom: 2.9rem
        }
    }

    &_reverse {
        flex-direction: row-reverse;

        .block-img-4__block {
            padding-right: 0;
            padding-left: 12%
        }
    }

    .info-block {
        margin-top: 4.6rem;
        line-height: 1.3;
    }
}

.article-wrap-slider {
    padding: 3.5rem 0 2rem
}

.article-inner-slider {
    margin: 0 -1.8rem;

    &__item {
        text-align: center;
        padding: 0 1.8rem 1px;
    }
}

.article-inner-slider-navs {
    margin-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .slick-prev {
        margin-right: 15px
    }

    .slick-next {
        margin-left: 15px
    }
}

.article {
    width: 113.5rem;
    max-width: 100%;
    margin: -2.5rem auto 0;
    line-height: 1.4;

    h1 {
        font-size: 4rem;
        margin-bottom: 5.8rem;
    }

    ul {
        list-style: none outside;
    }

    ol,
    ul {
        margin: .2rem 0 0 1.2rem;

        li {
            margin-bottom: 1.4rem
        }
    }

    ul li {
        position: relative;
        padding-left: 17px;

        &:before {
            content: '';
            display: block;
            background: $text-color;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 1.2rem;
        }
    }

    blockquote {
        margin: 3rem 0 3.5rem;
        font-size: 2.4rem;
        padding: 3.4rem 4rem 4rem 5.7rem;
        background: #FCFAF1;
        border-left: 1.2rem solid $color-active
    }

    picture {
        margin: 3rem 0 5.7rem;
        display: block;
    }

    table {
        width: 100%;
        table-layout: fixed;
        text-align: center;
    }

    .table-wrap {
        margin: 7.5rem 0 3.5rem;
        overflow: auto;
    }

    th {
        font-weight: 500;
        vertical-align: middle;
    }

    tr:first-child th {
        background: #FCFAF1;
    }

    th,
    td {
        border-right: 1px dashed #CDCDCD;
        padding: 1.7rem 1.7rem 1.7rem;
        vertical-align: middle;

        &:last-child {
            border-right-width: 0;
        }
    }

    td {
        padding: 1.7rem 1.7rem 1.7rem;

    }

    tr:nth-child(odd) td {
        background: #FCFAF1;
    }

    .video_wrap {
        margin: 8rem 0 6.8rem
    }
}

body:not(.content-loaded) .js-anim-decor {
    opacity: 0;

}

.styled option:first-child {
    display: none;
}

.content-loaded .js-anim-decor {

    path,
    circle,
    line,
    rect {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 3.5s .2s;

    }

}

.back-arrow {
    margin: 2.2rem 0 0;
    color: $text-color;
    display: inline-flex;
    align-items: center;

    i {
        font-size: 75%;
        margin-right: 2.1rem;
        overflow: hidden;
        transition: margin-right .5s ease;
        display: inline-block;
    }

    &:hover {
        i {
            margin-right: 3.8rem;
        }
    }
}

.section-career-list {
    padding: 8.4rem 0 14.3rem
}

.faq {
    border-bottom: 1px solid $line;

    &__title {
        padding: 4.8rem 0 4.7rem;
        font-size: 3.2rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__icon {
        padding: 15px 0 15px 15px;
        flex-shrink: 0;
        font-size: 50%
    }

    &__body {
        display: none;
        padding-bottom: 3.5rem
    }

    &.active {
        .faq__icon {
            transform: scale(200%);
            transform-origin: 82% 42%
        }

        .faq__icon i {
            @extend .icon-close;
        }

        .faq__title {
            padding-bottom: 3.7rem;
            border-bottom-color: transparent;
        }
    }
}

.section-included {
    padding: 14.4rem 0 40px;

    &__text {
        font-weight: 700;
        margin-bottom: 40px;
        overflow: hidden;
    }

    .h2 {
        max-width: 68rem;
    }
}

.included {
    margin: 7.5rem -40px 0;
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 33.333%;
        padding: 0 40px;
        border-right: 1px solid $line;

        &:nth-child(2) {

            .included__img,
            .included__title {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &:last-child {
            border-right-width: 0;

            .included__img,
            .included__title {
                margin-left: auto;
            }
        }
    }

    &__img {
        background: $text-color;
        overflow: hidden;
        margin-bottom: 3.8rem;
        max-width: 40.4rem;

        img {
            width: 100%;
            vertical-align: top
        }
    }

    &__title {
        overflow: hidden;
        max-width: 40.4rem;
        line-height: 1.5;

        b,
        strong {
            font-weight: 600
        }
    }
}

.section-head__btn-send {
    margin-top: 5.2rem;
    overflow: hidden;
}

.btn-send {
    min-width: 25.8rem
}

.section-head_contacts {
    padding-bottom: 22.5rem;
    flex-wrap: wrap;
    min-height: 0;

    .breadcrumbs {
        width: 100%
    }
}

.contact-items {
    font-size: 2.4rem;
    padding-top: .9rem;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
}

.contact-item {
    margin-left: 7.5%;
    display: flex;
    line-height: 135%;
    opacity: 0;
    transform: translateY(5rem);

    a {
        color: inherit;
    }

    &__title+&__title {
        margin-top: 1.4rem
    }
}

.content-loaded {
    .contact-item {
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.9s ease .1s, opacity 0.9s ease .1s;

        &:nth-child(2) {
            transition-delay: .2s
        }

        &:nth-child(3) {
            transition-delay: .3s
        }
    }

}

.contact-icon {
    color: $color-active;
    flex-shrink: 0;
    margin-right: 4rem;
    font-size: 142%
}

.section-contacts {
    background: #FCFAF1
}

.contacts-main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.contacts-list {
    margin-top: -11.3rem;
    padding: 1.6rem 4rem 6rem 5.2rem;
    background: #fff;
    width: 40%;

    .faq {
        border-bottom: 1px solid $line
    }

    .faq__title {
        padding: 2.8rem 0 2.8rem;
        font-size: 2.4rem;
        border-bottom-width: 0
    }

    .faq__icon {
        font-size: 60%
    }
}

.contacts-map {
    margin-top: -11.3rem;
    padding-left: 40px;
    width: 60%
}

.map {
    width: 100%;
    height: 59.3rem
}

.hero-image {
    overflow: hidden;
    background: $text-color;
    border-radius: 1.6rem;

    img {
        vertical-align: top;
        border-radius: 1.6rem
    }
}

.product-img__slider {
    background: none;
}

.product-slider {

    .slick-track {
        display: flex;
    }

    .slick-slide {
        display: flex !important;
        flex-direction: column;

        &>*,
        &>*>* {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
        }

        &>*>* {
            display: flex !important;
            align-items: center;
            justify-content: center;
            background: #FCFAF1
        }

    }

    &:not(.slick-initialized) {
        display: flex;
    }
}

.error-page {
    padding-bottom: 6rem;
    display: flex;
    flex-wrap: wrap;
    &__head {
        width: 41%
    }
    &__img {
        width: 59%;
        padding-left: 4rem;
        margin-top: -9rem;
    }
    .h1 {
        line-height: 1.05;
        max-width: 100%
    }
}
.text-error {
    line-height: 1.5;
        margin-top: 5.1rem;
}
.btn-error {
    margin-top: 5.3rem;
}
.box-captcha {
    .form-group {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        position: relative;
    }

    input {
        flex-grow: 10
    }

    img {
        margin-right: 3rem;
        border: 1px solid #B5B5B5
    }

    .help-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
    }
}

.product-slider {
    &:not(.slick-initialized) {
        opacity: 0
    }
}

.more-list {
    display: none
}
.section-search {
    padding: 7.7rem 0 13.3rem 
}
.highlight {
    background: #FFF685
}
.h2_search {
    font-weight: 400;
    color: #808080;
    font-size: 1.6rem;
}
.search-list {
    &__item {
        display: flex;
        & + & {
            margin-top: 3.6rem
        }
        &:hover {
            color: $text-color;
            .search-list__title {
                color: #5F01D2;
            }
        }
    }
    &__img {
        width: 16.6rem;
        height: 16.6rem;
        flex-shrink: 0;
        margin-right: 2.4rem;
        img {
            vertical-align: top;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            vertical-align: top
        }
    }
    &__title {
        font-size: 2.4rem;
        font-weight: 500;
        transition: color .6s ease
    }
    &__text {
        margin-top: 2.7rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 150%
    }
    a {
        color: inherit;
    }
    .highlight {
        color: inherit;
    }
    & + .box-paging {
        margin-top: 6.2rem
    }
}
@media screen and (max-width: 1599px) {
    .categories__head {
        padding: 6rem 0 0
    }

    .categories__title {
        font-size: 3.1rem;
        max-width: 52%;
        padding: 0 0 0 4.4rem;
    }

    .contact-items {
        font-size: 2.2rem
    }

    .contact-item {
        margin-left: 7%
    }

    .contact-icon {
        margin-right: 2.8rem;
    }

    .section-contacts {
        padding-bottom: 12rem;
    }

    .section-my-account {
        padding: 11.3rem 0 10.5rem;
    }

    .my-account {
        margin-top: 7.9rem;
    }
    .error-page__img {
        margin-top: -7rem;
    }
}

@media screen and (max-width: 1439px) {

    .categories__title {
        font-size: 2.7rem;
    }

    .back-arrow {
        margin-top: 0;

        i {
            font-size: 64%;
            margin-right: 1.6rem;
        }
    }

    .article {
        margin-top: 3.8rem;
    }

    .section-subcategoies {
        padding: 7rem 0 7rem
    }
}

@media screen and (max-width: 1366px) {
    .categories__head {
        padding: 5.8rem 0 0
    }

    .categories__title {
        font-size: 2.5rem;
        max-width: 47%;
        padding: 0 0 0 3.1rem;
    }

    .subcategories__description {
        padding: 30px 4.4rem;
    }

    .menu-navs {
        font-size: 2rem
    }

    .block-img-4__block {
        padding-right: 7%;
    }

    .block-img-4_reverse .block-img-4__block {
        padding-right: 0;
        padding-left: 7%;
    }

    .info-block {
        font-size: 2.6rem;
    }

    .contacts-list .faq__title {
        font-size: 2.2rem;
        padding: 2.1rem 0;
    }

    .categories {
        margin-top: 7rem
    }

    .categories__head {
        padding: 6.8rem 0 0;
        min-height: 39rem;
    }

    .section-career-list {
        padding: 6.4rem 0 9.3rem;
    }

    .career-list__aside {
        padding-top: 3.5rem;
    }

    .faq__title {
        padding: 3.8rem 0 3.7rem;
    }

    .section-included {
        padding: 9.4rem 0 3rem;
    }

    .categories_2 .categories__head {
        min-height: 30rem;
    }
}

@media screen and (max-width: 1365px) {
    .section-head_contacts {
        padding-bottom: 18.5rem;
    }

    .contact-items {
        display: block;
    }

    .contact-item {
        margin: 0 0 3.5rem;
    }

    .contacts-list {
        padding: 1.6rem 4rem 5rem 4.2rem;
        width: 45%;
    }

    .contacts-map {
        width: 55%;
    }
}

@media screen and (max-width: 1280px) {
    .menu-navs {
        font-size: 1.8rem
    }

    .cart-wrap-order {
        display: block;

        .cart-wrap {
            width: 100%;
            margin: 4rem 0 0
        }
    }

    .cart-order {
        width: 100%;
    }

    .blog-card__title {
        font-size: 2rem;
    }

    .cart__details {
        flex-wrap: wrap;
    }

    .cart__head {
        width: 66.66%
    }

    .cart__date {
        width: 33.33%;
        padding-right: 0
    }

    .cart__action {
        margin-top: 2.5rem;
        width: 100%
    }

    .faq__title {
        font-size: 2.8rem;
    }

    .contact-items {
        font-size: 2rem;
    }
}

@media screen and (max-width: 1023px) {
    .breadcrumbs {
        margin: 4.3rem 0 3.8rem;
    }

    .categories__title {
        font-size: 2rem;
        padding: 0 0 0 2.3rem;
    }

    .categories__head {
        padding: 3.4rem 0 0;
    }

    .cart__details {
        flex-wrap: wrap;
    }

    .cart__action {
        margin-top: 2.5rem;
        width: 100%
    }

    .my-account-form-password .box-field,
    .my-account-form .box-field {
        width: 100%;
    }

    .blog-list .blog-card {
        width: 50%;
    }

    .included__item {
        width: 50%;
        margin-bottom: 4rem;

        &:last-child {
            border-right-width: 0;
        }
    }

    .faq__title {
        font-size: 2.4rem;
    }

    .section-head_contacts {
        padding-bottom: 13.5rem;
    }

    .contacts-main {
        display: block;
        position: relative;
        top: -8rem;
    }

    .contacts-list {
        width: 100%;
        margin-top: 0;
    }

    .contacts-map {
        margin: 1.6rem 0 0;
        width: 100%;
        padding: 0
    }

    .block-img-4 {
        display: block;
        padding: 1.5rem 0;
        margin: 0;

        &__description {
            padding: 0 0 .5rem;
            width: 100%;
        }

        .block-img-4__img {
            margin-top: 30px;
            padding: 0;
            width: 100%;
        }

        .block-img-4__block {
            padding-right: 0;
        }

        &_reverse {
            display: flex;
            flex-direction: column-reverse;

            .block-img-4__block {
                padding-right: 0;
            }

            .block-img-4__img {
                margin-top: .9rem;
            }

            .block-img-4__description {
                margin-top: 1rem
            }
        }
    }

    .blog-card__title {
        font-size: 1.8rem;
    }

    .blog-card__details {
        height: 4rem;
        font-size: 1.6rem;
    }

    .projects-nav {
        margin-bottom: 2.8rem;
    }

    .blog-list .blog-card {
        margin: 0 0 4.4rem;
    }

    .blog-card__details i {
        margin-left: 1.6rem;
    }

    .blog-card__top {
        margin-bottom: 1.8rem;
    }

    .blog-card__info {
        margin-bottom: 1.8rem;
    }

    .product-head .h1 {
        font-size: 3.8rem
    }

    .product-img {
        width: 47%
    }

    .product-about-tabs {
        padding-left: 3.5%;
    }
     .error-page__img {
        margin-top: -1rem;
    }
    .text-error {
        margin-top: 3.1rem;
    }
    .btn-error {
        margin-top: 3.2rem;
    }
    .search-list__title  {
        font-size: 2rem
    }
}

@media screen and (max-width: 767px) {
    .btn_add-project {
        min-width: 0;
        width: 100%;
    }

    .categories__title {
        font-size: 1.8rem;
        max-width: 50%;
        padding: 0 0 0 2.3rem;
    }

    .categories__img,
    .categories__item_down .categories__img {
        width: 44%;
    }

    .categories_2 .categories__head {
        min-height: 24rem;
    }

    .section-head {
        min-height: 0;
        padding-top: 2.5rem;
        display: block;

        &__title {
            width: 100%;
            padding-right: 0
        }

        &__decor {
            width: 100%
        }
    }

    .subcategories__item {
        flex-direction: column-reverse;
    }

    .subcategories__head {
        width: 100%;
        min-height: 0;
    }

    .subcategories__item {
        margin: 2.6rem 0 2.4rem;
    }

    .subcategories__description {
        padding: 2.7rem 2.4rem 1.4rem
    }

    .subcategories__img {
        width: 100%;
        height: 25.3rem;
        min-height: 25.3rem;

        img {
            max-height: 95%
        }
    }

    .subcategories__title {
        font-size: 1.8rem;
    }

    .subcategories__text {
        font-size: 1.6rem;
        line-height: 130%
    }

    .subcategories__btn {
        margin-top: 0;
        text-align: left
    }

    .section-product-head {
        padding: 0 0 30px
    }

    .product-img {
        position: static;
        margin-top: 1.1rem;
        width: 100%;
        padding: 2.3rem 2rem;

        img {
            max-height: 25rem;
        }

        .slider-thumbs {
            img {
                max-height: 90%
            }
        }
    }

    .product-head {
        width: 100%;
        min-height: 0;
        padding: 0 0 3rem;

        .btn {
            margin-top: 3.8rem;
            height: 5rem;
            line-height: 5rem;
        }

        .h1 {
            font-size: 2.3rem;
            margin-bottom: 2rem
        }
    }

    .tovar-info {
        margin-top: 2rem;
    }

    .product-head__title {
        margin-top: 4rem;
    }

    .product-about {
        border-top-width: 0
    }

    .main-section-inner {
        padding-top: 7rem;
    }

    .breadcrumbs {
        margin: 2.3rem 0 5.1rem;
    }

    .breadcrumb {
        font-size: 1.4rem;

        i {
            font-size: 2.1rem
        }
    }

    .section-product-about {
        padding: .7rem 0 1.1rem;
    }

    .cart {
        border-width: 0;
        font-size: 1.4rem
    }

    .cart__item {
        display: block;
        margin-bottom: 1.6rem;
        border: 1px solid $line
    }

    .cart__img img {
        max-height: 148px;
    }

    .cart__img {
        width: 100%;
    }

    .cart__details {
        padding: 1.6rem 1.7rem;
        width: 100%;
    }

    .cart__status {
        padding: 0.8rem 1.3rem;
        font-size: 1.3rem;
        top: 1.9rem;
        left: 1.7rem;
    }

    .cart__head {
        padding-right: 0;
        width: 100%;
    }

    .cart__number {
        margin-top: 1.2rem;
    }

    .cart__details-list {
        margin-top: .4rem;
    }

    .cart__date {
        padding-right: 0;
        width: 100%;
    }

    .cart__remove {
        width: 100%;
        justify-content: center;
    }

    .cart__title {
        font-size: 1.6rem
    }

    .cart-order-done {
        display: block;

        &__icon {
            width: 100%;
            padding-left: 0;
            margin-top: 3.6rem;
            justify-content: flex-start;

            svg {
                width: 14.4rem
            }
        }

        &__alert {
            width: 100%;
        }

        &__btn {
            text-align: center;
        }
    }

    .my-account-tabs {
        padding: 20px 0 0;
        border-left: 0;
        margin-left: 0;
        width: 100%;
    }

    .my-account {
        border-width: 0
    }

    .menu-navs {
        width: 100%;
        padding-top: 0;
        border-right: 0;
    }

    .login-form .box-field_half {
        width: 100%;
    }

    .section-blog {
        padding: 2px 0 40px;
    }

    .blog-list .blog-card {
        width: 100%;
    }

    .blog-card__title {
        font-size: 1.8rem;
    }

    .projects-nav li {
        margin: 0 1.6rem 2rem 0;
    }

    .projects-nav {
        margin-bottom: 20px;
    }

    .product-about-2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        line-height: 2.1rem;

        &__left {
            padding-right: 0;
            border-right-width: 0;
            margin-right: 0;
            width: 100%
        }

        &__right {
            margin-bottom: 20px;
            border-bottom: 1px solid $line;
            padding: 0 0 20px;
            border-left-width: 0;
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
        }

        &__label,
        .h2-sm {
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 3.1rem;
            margin-bottom: 1rem;
        }
    }

    .section-product-about-2 {
        padding: 4rem 0 4rem;
    }

    .product-about-2__item+.product-about-2__item {
        margin: 0 0 1.7rem;
    }

    .block-img-3 {
        display: block;
        padding: 3rem 0 2rem;
        margin: 0;

        figure {
            padding: 0;
            width: 100%;
        }

        figure+figure {
            margin-top: 25px
        }
    }

    figcaption {
        font-size: 1.4rem;
        margin-top: 1.1rem;
    }


    .article-main {
        .breadcrumbs {
            margin-bottom: 3.1rem
        }
    }


    .article {
        margin-top: 3.8rem;
        line-height: 1.3;

        h1 {
            font-size: 2rem;
            margin-bottom: 1.8rem;
        }

        picture {
            margin: 3rem 0 2.2rem;
        }

        ol,
        ul {
            margin: .7rem 0 0 1.0rem;

            li {
                margin-bottom: .8rem
            }
        }

        blockquote {
            margin: 2.5rem 0 3.5rem;
            font-size: 1.6rem;
            padding: .6rem 1.2rem .6rem 2rem;
        }

        .table-wrap {
            margin: 30px 0 15px;

            table {
                width: 150%
            }
        }
    }

    .article-info {
        margin-bottom: 3.9rem;
    }

    .article-wrap-slider {
        padding: 1.5rem 0 1rem;
    }

    .article-inner-slider-navs {
        margin-top: 2.2rem;
        justify-content: flex-start;
    }

    .article .video_wrap {
        margin: 3rem 0 2.8rem;
    }

    .block-img-4_reverse .block-img-4__block {
        padding-right: 0;
        padding-left: 0;
        margin-top: 15px
    }

    .included {
        margin: 0
    }

    .included__item {
        width: 100%;
        border-right-width: 0;
        padding: 0;
        margin-bottom: 4rem
    }

    .faq__title {
        font-size: 1.8rem;
    }

    .section-head__contacts {
        width: 100%;
        margin-top: 4rem
    }

    .contact-items {
        font-size: 1.8rem;
    }

    .contact-icon {
        margin-right: 2.3rem;
    }

    .contact-item {
        margin: 0 0 2.3rem;
    }

    .contact-item__title+.contact-item__title {
        margin-top: 0;
    }

    .section-head_contacts {
        padding-bottom: 15.8rem;
    }

    .contacts-list .faq__title {
        font-size: 1.8rem;
        padding: 1.5rem 0 1.5rem;
    }

    .contacts-main {
        top: -10.5rem;
    }

    .contacts-list {
        padding: 2.2rem 2rem 4.5rem;
    }

    .section-contacts {
        margin-bottom: -7.3rem;
    }

    .faq__title {
        padding: 2.6rem 0 2.5rem;
    }

    .faq.active .faq__title {
        padding-bottom: 2.5rem;
    }

    .section-career-list {
        padding: 3.8rem 0 4rem;
    }

    .faq__body {
        padding-bottom: 2rem;
        margin-right: -6px;

        b {
            letter-spacing: -.03em
        }
    }

    .block-img-4__description {
        line-height: 1.3;
    }

    .block-img-4 .info-block {
        margin-top: 2.8rem;
    }

    .product-slider-wrap {
        display: block;
        width: 100%
    }

    .product-slider {
        &>*>* {}

        &__item {
            display: flex;
            background: #FCFAF1;
            height: 30rem;
            max-height: 30rem;
        }
    }

    .slider-thumbs {
        margin: 0 -.8rem 1rem .8rem;

        .slick-slide {
            padding-right: .8rem;
        }

        .slick-arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6rem;
            height: auto;
            transform: none;
            z-index: 2
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }

        &__item {
            background: #FCFAF1;
            height: 8.2rem;
            text-align: center;
        }
    }

    .web .slider-thumbs .slick-arrow:hover {
        position: absolute;
    }

    .section-my-account {
        padding: 5.3rem 0 1.2rem;

        .h1 {
            margin-bottom: 1.4rem;
        }
    }

    .my-account {
        margin-top: 0;

        .show-password {
            font-size: 3.4rem;
            right: 1.3rem;
        }
    }

    .my-account-form .box-field__label {
        margin-bottom: 0.6rem
    }

    .my-account-form .box-field {
        margin-bottom: 2.1rem
    }

    .box-field__hidden.shown-input {
        display: block;
    }

    .box-field__hidden {
        input {
        width: 100%;
        height: 5rem;
    }

    .jq-selectbox__select {
        height: 5rem;
        padding-top: 1.3rem
    }
    .jq-selectbox__trigger-arrow {
        top: 1.5rem;
        width: 1rem;
        height: 1rem;
    }
    }

    .accept-change {
        width: 100%;
        height: 5rem;
        margin: 1.5rem 0 0;
    }

    .my-account-form-password-title {
        margin-bottom: 2.1rem;
        font-size: 2rem;
    }

    .cart-btn .btn {
        width: 100%
    }

    .subcategories__category {
        font-size: 1.2rem;
        margin-bottom: 2.7rem
    }

    .breadcrumb {
        white-space: normal;
        overflow: visible;
    }

    .categories__item_1 .categories__img {
        width: 69%;
        bottom: 20%;
    }

    .section-top-product {
        height: 64rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .section-top-product .product-img {
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        height: 35rem;
    }
    .error-page {
        padding-top: 4.3rem;
        padding-bottom: 7.5rem;
        .text-error {
            display: none;
        }
    }
     .error-page__img {
        margin-top: 0;
        width: auto;
        margin-right: 2rem;
        padding: 3.3rem 0 0
    }

     .error-page__head {
        width: 100%;
    }
        .btn-error {
        margin-top: 5.5rem;
    }
    .search-list {
        max-width: 40rem;
        margin: 0 auto;
        &__title  {
            font-size: 2rem
        }
        &__item {
            display: block;
        }
        &__img {
            margin: 0 0 2.3rem;
            width: 100%;
            position: relative;
            height: auto;
            &:before {
                content: '';
                display: block;
                padding: 100% 0 0;
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    .search-list + .box-paging {
        margin-top: 4rem;
    }
    .section-search {
        padding: 3.3rem 0 2.3rem;
    }
    .search-list__text {
        margin-top: 1.7rem;
        line-height: 187.5%;
    }
    .search-list__item + .search-list__item {
        margin-top: 3.8rem;
    }
}


@media screen and (min-width: 768px) {
    .cart__item:last-child {
        border-bottom: 0
    }

    .product-slider-wrap {
        display: flex;
        width: 100%;
        position: relative;

        img {
            vertical-align: top
        }
    }

    .slider-thumbs {
        width: 28%;
        padding-right: 3.5rem;
        position: absolute;
        left: 0;
        top: 0;
        bottom: -10px;

        img {
            max-height: 100%;
        }

        &__item {
            position: relative;
            background: #FCFAF1;
            cursor: pointer;
        }

        .slick-slide {
            padding-bottom: 10px;
            height: 17.7rem;
            display: flex !important;

            &>*,
            &>*>* {
                display: flex;
                flex-direction: column;
                flex: 1 1 100%;
                height: 100%
            }

            &>*>* {
                display: flex !important;
                align-items: center;
                justify-content: center;
            }

            img {
                height: calc(100% - 10rem)
            }
        }

        .slick-arrow {
            position: absolute;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 3.8rem);
            height: 5rem;
            transform: none;
            z-index: 2
        }

        .slick-prev {
            top: 0;
        }

        .slick-next {
            bottom: 1rem;
        }
    }

    .web .slider-thumbs .slick-arrow:hover {
        position: absolute;
    }

    .product-slider {
        margin-left: 28%;
        width: 72%;
    }

    .product-img {
        width: 56.5%;
    }

    .product-head {
        width: 40%;
    }

    .product-slider__item {
        height: 51.9rem;
        min-height: 51.9rem;
    }

    .menu-navs_sm {
        font-size: 1.5rem;
        padding-right: 2rem
    }

    .categories_2 {
        .categories__img {
            width: 46%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .product-slider__item {
        height: 63.9rem;
        min-height: 63.9rem;
    }

    .section-product-head {
        min-height: 77.9rem;
    }

    .slider-thumbs .slick-slide {
        height: 21.7rem;
    }

    .categories_2 .categories__img {
        width: 49%;
    }
}

@media screen and (min-width: 1367px) {
    .categories_2 .categories__img {
        width: 49%;
    }
}

@media screen and (min-width: 1440px) {
    .product-slider__item {
        height: 77.9rem;
        min-height: 77.9rem;
    }

    .slider-thumbs .slick-slide {
        height: 26.4rem;
    }

    .categories_2 {
        .categories__img {
            width: 49%;
        }
    }
}

@media screen and (min-width: 1600px) {
    .menu-navs_sm {
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 1700px) {
    .menu-navs_sm {
        font-size: 1.7rem;
    }
}

@media screen and (min-width: 1800px) {
    .menu-navs_sm {
        font-size: 1.8rem;
    }
}