.main-wrapper {
	padding: 0;
	min-width: $min-width;
	width: 100%;
	position: relative;
	overflow: hidden;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	transition: filter .3s ease-out;

	z-index: 1
}


.wrapper {
	min-width: $min-width;
	max-width: $max-width;
	padding-right: 5rem;
	padding-left: 5rem;
	;
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.wrapper-full {
	max-width: 100%;
	padding-right: 3.4rem;
	padding-left: 3.4rem;
}

footer {
	order: 3;
}

/* titles */
p {
	padding: 1.3rem 0;
}

h1,
.h1 {
	font-weight: 500;
	font-size: 8rem;
	line-height: 120%;
	margin-bottom: 3.6rem;
	overflow: hidden;
	padding-bottom: 2px;

	span {
		display: inline-block;
		transform: translateY(120%);
	}
}

.content-loaded {

	h1,
	.h1 {
		span {
			transform: translateY(0);
			transition: transform .6s ease
		}
	}
}

body:not(.content-loaded) {
	.wow:not(.anim-2) {
		opacity: 0;
		animation: none
	}
}

.h1 {
	margin-bottom: 3rem;
	max-width: 60%;
	position: relative;

	&:after {
		content: '';
		display: block;
		margin-top: 20px;
		height: 0.4rem;
		width: 9rem;
		background: $color-active
	}
}

.h1-md {
	font-size: 6rem
}

.h1-sm {
	font-size: 4rem
}

h2,
.h2 {
	font-weight: 500;
	font-size: 3rem;
	line-height: 1.3;
	padding: 0;
	margin: 6.9rem 0 .9rem;
	padding-bottom: 2px;

	div {
		overflow: hidden;
	}

	span {
		display: inline-block;
	}
}

.h2 {
	margin: 0 0 44px;
	font-size: 4rem;
	line-height: 1.2;


	&:after {
		content: '';
		display: block;
		margin-top: 20px;
		border: 0;
		height: 0.4rem;
		width: 9rem;
		background: $color-active
	}
}

h3,
.h3 {
	font-size: 3rem;
	line-height: 1.2;
	font-weight: 700;
	padding: 1rem 0 2rem;
	text-align: center;
}

.h3 {
	padding: 0 0 3.3rem
}

.h2.animated:after {

	transform: scale(1);
}

.section-dark {
	background: #1F1F1F;
	color: #fff;

	.h2 {
		color: #fff;
	}

	.h3 {
		color: #fff;
	}
}


h4 {
	font-weight: 700;
	line-height: 1.4;
	text-transform: uppercase;
	padding-top: 25px
}


/* text position */
.text-left {
	text-align: left !important
}

.text-center {
	text-align: center !important
}

.text-right {
	text-align: right !important
}

.nowrap {
	white-space: nowrap !important;
}

.center {
	text-align: center;
}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {
	min-width: $min-width;
	text-align: left;
	width: 100%;
	order: 2;
	flex-grow: 1;
	position: relative;
}


@keyframes smoothbounceball {
	from {
		transform: translate3d(0, 0, 0);
	}

	30% {
		transform: translate3d(-2rem, 0, 0);
	}

	60% {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes smoothbounceball2 {
	from {
		transform: translate3d(0, 0, 0);
	}

	30% {
		transform: translate3d(2rem, 0, 0);
	}

	60% {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.btn {
	border: 0;
	color: $text-color;
	font-weight: 400;
	padding: 0 3.9rem;
	vertical-align: top;
	text-align: center;
	height: 10rem;
	line-height: 10rem;
	font-size: 1.8rem;
	background: $color-active;
	text-transform: uppercase;
	border-radius: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	i {
		width: 0;
		font-size: 70%;
		//margin-left: 24px;
		overflow: hidden;
		transition: margin-left .5s ease, width .5s ease;
		display: inline-block;
	}

	&:disabled {
		pointer-events: none;
		background: #E3E3E3
	}

	&:hover {
		i {
			margin-left: 5.8rem;
			width: auto
		}
	}
}

.btn-2 {
	i {
		margin-left: 24px;
		width: auto
	}

	&:hover {
		i {
			margin-left: 5.8rem;
		}
	}
}

.btn-3 {
	background: $text-color;
	color: #fff;
}

.btn-sm {

	height: 6rem;
	line-height: 6rem;
}

.btn_sm {
	height: 5.9rem;
	line-height: 5.9rem
}


[type="text"],
[type="tel"],
[type="email"],
[type="password"],
textarea {

	height: 6.4rem;
	position: relative;
	border: 1px solid $line;
	border-radius: 0;
	color: $text-color;
	background: #FCFCFC;
	font-size: 1.6rem;
	z-index: 1;
	padding: 0 3.1rem;
	font-weight: 400;
	width: 100%;

	&.error {

		border-color: #FF5050;
	}

	&:focus {
		border-color: #000
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background: #fff;
		border: 1px solid $line;
		-webkit-text-fill-color: #000;
		-webkit-box-shadow: inset 0 0 100px 40px transparent;
		transition: none;
	}
	&:disabled  {
			border-color: #e4e4e4;
			color: #cdcdcd;
			&::-webkit-input-placeholder {
	color: #cdcdcd;
}

&::-moz-placeholder {
	color: #cdcdcd;

}

&:-moz-placeholder {
	color: #cdcdcd;
}

&:-ms-input-placeholder {
	color: #cdcdcd;
}
	}
}


::-webkit-input-placeholder {
	color: $placeholder;
}

::-moz-placeholder {
	color: $placeholder;

}

:-moz-placeholder {
	color: $placeholder;
}

:-ms-input-placeholder {
	color: $placeholder;
}
.field-disable {
	.box-field_label { color: #cdcdcd; }
}
textarea {
	padding-top: 1.6rem;
	width: 100%;
	height: 11.2rem;
	resize: none;
}

label.error,
.help-block {
	color: #FF5050;
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.9rem;
	display: block;
}

.box-field {
	position: relative;
	margin-bottom: 3.5rem;

	&__input {
		position: relative;

		label.error {
			position: absolute;
			left: 0;
			top: 100%;
		}
	}

	&__checkbox {
		margin-top: 15px
	}

	&__btn {
		margin-top: 25px
	}

	&__label {
		font-size: 1.6rem;
		margin-bottom: 6px;
	}
}

.form-checkbox {
	margin: 47px 0 28px
}


.scrollDisabled {
	overflow: hidden;
	position: fixed !important;
	margin-top: 0;
	width: 100%;
	z-index: 0;
}

.scrollDisabled.web,
.scrollDisabled.web.compensate-for-scrollbar {
	padding-right: 17px
}

.scrollDisabled .main-wrapper {
	overflow: visible;
}

.hide {
	display: none;
}

.color-decor {
	color: $color1;
}


.decor-wrap {
	overflow: hidden;
	height: 21rem;
	z-index: 1;
	width: 100%;
	position: relative;
	margin-top: 8rem;

	&_2 {
		margin-top: 11rem;
	}
}


.socials {
	&__item {
		margin-right: 3rem;
		display: inline-block;
	}
}

.checkbox-el {
	font-weight: 500;

	label {
		position: relative;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		&:disabled {

		}
	}

	&__txt {
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 1.9rem;
		color: $text-color;
		display: inline-flex;
		align-items: center;
		cursor: pointer;
	}

	.check {
		display: block;
		border: 1px solid $line;
		background: #FCFCFC;
		width: 3.5rem;
		height: 3.5rem;
		flex-shrink: 0;
		margin: 0 1.5rem 0 0;
		position: relative;
		border-radius: 0;

		&:after {
			position: absolute;
			left: -.2rem;
			content: '';
			top: 50%;
			margin-top: -1rem;
			width: 100%;
			height: 100%;
		}
	}

	input:checked~.checkbox-el__txt .check {
		background: #FFDA22;
		transition: border-color .2s ease, background .2s ease;
	}

	input:checked~.checkbox-el__txt .check:after {
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNyAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTcgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNS4zLDAuMyA3LjQsOC42IDEuNiwzLjcgMC40LDUuMyA3LjYsMTEuNCAxNi43LDEuNyAiLz4NCjwvc3ZnPg0K");
		background-size: 1.8rem 1.8rem;
		background-position: 64% 0%;
		background-repeat: no-repeat;
	}

	.decor {
		border-bottom: 1px solid
	}
	&:hover {
		.check {
			border-color: #808080
		}
	}
	input:disabled~.checkbox-el__txt  {
		pointer-events: none;
		color: #cdcdcd;
		.check {
			border-color: #e4e4e4
		}
	}
}
.radio-el {
	font-weight: 500;

	label {
		position: relative;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		&:disabled {

		}
	}

	&__txt {
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 1.9rem;
		color: $text-color;
		display: inline-flex;
		align-items: center;
		cursor: pointer;
	}

	.check {
		display: block;
		border: 1px solid $line;
		background: #fff;
		width: 2.5rem;
		height: 2.5rem;
		flex-shrink: 0;
		margin: 0 1.5rem 0 0;
		position: relative;
		border-radius: 50%;

		&:after {
			position: absolute;
			left: 50%;
			content: '';
			top: 50%;
		width: 1.5rem;
		height: 1.5rem;
		transition:  background .2s ease;
		transform: translate(-50%, -50%);
		border-radius: 50%
		}
	}

	input:checked~.radio-el__txt .check {
		border-color: #FFDA22;
		transition: border-color .2s ease
	}

	input:checked~.radio-el__txt .check:after {
		background: $color-active;
	}
	input.error~.radio-el__txt .check {
		border-color: #FF5050;
	}
	.decor {
		border-bottom: 1px solid
	}
	&:hover {
		.check {
			border-color: #808080
		}
	}
	input:disabled~.radio-el__txt  {
		pointer-events: none;
		color: #cdcdcd;
		.check {
			border-color: #e4e4e4
		}
	}
}
.web {
	.checkbox-el__txt:hover:before {
		transition: border-color .4s ease;
		border-color: $color-active;
	}
}

.tab .tab-content {
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity .5s ease;
}

.link-accordion {
	display: none;
}

.tab.shown {
	.tab-content {
		height: auto;
		opacity: 1;
	}

	.link-accordion i {
       @extend .icon-close;
       font-size: 130%
	}
}

.accordion-body {
	display: none;
}
.tooltip-wrap {
	position: relative;
	margin-left: 8px;
	font-size: 1.6rem;
	display: inline-block;

	&:hover {
		z-index: 10000;

		.tooltip-text {
			display: block;
		}
	}
}

.tooltip-icon {
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	background: #FFDA22;
	line-height: 2.4rem;
	font-weight: 500;
	display: inline-block;
	text-align: center;
	cursor: pointer;
}

.tooltip-text {
	position: absolute;
	z-index: 1;
	display: none;
	left: 0;
	top: 100%;
	padding: 10px;
	width: 150px;
	background: #fff;
	border: 1px solid $line
}
@media screen and (max-width: 1439px) {
	.btn {
		padding: 0 3.1rem;
		height: 7rem;
		line-height: 7rem;
	}
}

@media screen and (max-width: 1365px) {
	.h1 {
		font-size: 5rem;
	}

	.h2 {
		font-size: 3.5rem
	}
}


@media screen and (min-width: 1024px) {
	.tablet-show {
		display: none !important;
	}

}

@media screen and (max-width: 1023px) {

	.h1 {
		font-size: 4.2rem;
	}

	.wrapper {
		padding-left: 3rem;
		padding-right: 3rem
	}

	.tablet-hide {
		display: none !important;
	}


	h2 {
		font-size: 2.4rem;
		margin-bottom: 1rem;
		margin-top: 4.2rem
	}

	.h2 {
		font-size: 3rem;
		margin-bottom: 2.9rem;
		margin-top: 0
	}

	.h3 {
		text-align: center;
		font-size: 2.8rem;
		line-height: 3.7rem;
		margin-left: auto;
		margin-right: auto
	}

	.box-field {
		margin-bottom: 2.3rem;
	}

	[type=text],
	[type=tel],
	[type=email],
	[type="password"] {
		font-size: 1.4rem;
		padding-left: 2.3rem;
		padding-right: 2.3rem;
		height: 5.6rem
	}

}


@media screen and (max-width: 767px) {


	h1,
	.h1 {
		font-size: 2.4rem;
		line-height: 130%;
		max-width: 100%
	}

	.h1:after {
		width: 6.3rem;
		margin-top: 1.2rem;
	}

	h2 {
		font-size: 1.8rem;
		margin-top: 2rem;
		margin-bottom: 0.6rem
	}

	.h2 {
		max-width: 100%;
		font-size: 2.4rem;
		margin-top: 0
	}

	.h2:after {
		width: 6.3rem;
		margin-top: 1.2rem;
	}

	.h1-sm {
		font-size: 2.4rem;
	}

	.h2-sm {
		font-size: 2rem;
	}

	.h3 {
		font-size: 2rem;
		line-height: 2.3rem;
		padding: 0 0 2.3rem;
	}

	p {
		padding: .8rem 0 .9rem;

	}

	[type=text],
	[type=tel],
	[type=email],
	[type="password"], textarea {
		font-size: 1.6rem;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		height: 5rem;
	}
	textarea {
		height: 11.2rem;
		border-radius: .8rem
	}
	.wrapper {
		padding-left: 2.4rem;
		padding-right: 2.4rem
	}

	.mob-hide {
		display: none !important;
	}


	.label-placeholder {
		font-size: 12px;
		line-height: 15px;
	}

	.form-control {
		font-size: 1.6rem
	}

	.filled .label-placeholder {
		font-size: 11px
	}


	.btn {
		font-size: 1.4rem;
		height: 6rem;
		line-height: 6rem;
		padding: 0 1.6rem;

		i {
			font-size: .7rem;
		}
	}
	.btn:hover i {
	    margin-left: 1.8rem;
	}
	.btn-2 i {
		margin-left: 1.7rem;
	}

	.box-field__button {
		text-align: center;

		.btn {
			width: 100%;
			max-width: 48rem
		}
	}

	.link-accordion {
		padding: 2.3rem 0;
		font-size: 1.8rem;
		font-weight: 500;
		color: $text-color;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: color .4s ease, background .4s ease;

		i {
			font-size: 50%;
			margin-left: 2.5rem;
			flex-shrink: 0
		}
	}

	.tab {
		border-bottom: 1px solid $line;

		&:first-child {
			border-top: 1px solid $line;
		}
	}

	.tab.shown .tab-content {
		padding-bottom: 2rem
	}
}



@media screen and (min-width: 768px) {
	.mob-show {
		display: none !important;
	}
}