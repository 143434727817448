
/* -------------------------------- 

Main Components 

-------------------------------- */
.cd-product-viewer-wrapper {
  text-align: center;
  padding: 1.5rem 0;
}
.cd-product-viewer-wrapper > div {
  display: inline-block;
}
.cd-product-viewer-wrapper .product-viewer {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
}
.cd-product-viewer-wrapper img {
  /* this is the image visible before the image sprite is loaded */
  display: block;
  position: relative;
  z-index: 1;
}
.cd-product-viewer-wrapper.loaded img {
    opacity: 0
}
.cd-product-viewer-wrapper .product-sprite {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
background-repeat: no-repeat;
background-position: center center;
  background-size: 100%;
  width: 1600%;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.cd-product-viewer-wrapper.loaded .product-sprite {
  /* image sprite has been loaded */
  opacity: 1;
  cursor: ew-resize;
}

.cd-product-viewer-handle {
  position: relative;
  z-index: 2;
  width: 70%;
  max-width: 300px;
  border-radius: 50em;
  margin: 1em auto 3em;
  height: .4rem;
  background: $color-active;
}
.cd-product-viewer-handle .fill {
  /* this is used to create the loading fill effect */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background: $color-active;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}
.no-csstransitions .cd-product-viewer-handle .fill {
  display: none;
}
.loaded .cd-product-viewer-handle .fill {
  /* image sprite has been loaded */
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s 0.3s;
  transition: transform 0.3s, opacity 0.2s 0.3s;
}
.cd-product-viewer-handle .handle {
  position: absolute;
  z-index: 2;
  display: inline-block;
  height: 4.4rem;
  width: 4.4rem;
  left: 0;
  top: -2rem;
  background: $color-active url(../img/cd-arrows.svg) no-repeat center center;
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba($color-active, 0.3), 0 0 20px rgba(0, 0, 0, 0.2);
  /* replace text with image */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: transparent;
  -webkit-transform: translateX(-50%) scale(0);
  -moz-transform: translateX(-50%) scale(0);
  -ms-transform: translateX(-50%) scale(0);
  -o-transform: translateX(-50%) scale(0);
  transform: translateX(-50%) scale(0);
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
.cd-product-viewer-handle .handle:active, .cd-product-viewer-handle .handle:hover {
  box-shadow: 0 0 0 0 rgba(181, 66, 64, 0), 0 0 20px rgba(0, 0, 0, 0.2);
}
.loaded .cd-product-viewer-handle .handle {
  /* image sprite has been loaded */
  -webkit-transform: translateX(-50%) scale(1);
  -moz-transform: translateX(-50%) scale(1);
  -ms-transform: translateX(-50%) scale(1);
  -o-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
  -webkit-animation: cd-bounce 0.3s 0.3s;
  -moz-animation: cd-bounce 0.3s 0.3s;
  animation: cd-bounce 0.3s 0.3s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  cursor: ew-resize;
}

@-webkit-keyframes cd-bounce {
  0% {
    -webkit-transform: translateX(-50%) scale(0);
  }
  60% {
    -webkit-transform: translateX(-50%) scale(1.1);
  }
  100% {
    -webkit-transform: translateX(-50%) scale(1);
  }
}
@-moz-keyframes cd-bounce {
  0% {
    -moz-transform: translateX(-50%) scale(0);
  }
  60% {
    -moz-transform: translateX(-50%) scale(1.1);
  }
  100% {
    -moz-transform: translateX(-50%) scale(1);
  }
}
@keyframes cd-bounce {
  0% {
    -webkit-transform: translateX(-50%) scale(0);
    -moz-transform: translateX(-50%) scale(0);
    -ms-transform: translateX(-50%) scale(0);
    -o-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0);
  }
  60% {
    -webkit-transform: translateX(-50%) scale(1.1);
    -moz-transform: translateX(-50%) scale(1.1);
    -ms-transform: translateX(-50%) scale(1.1);
    -o-transform: translateX(-50%) scale(1.1);
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    -webkit-transform: translateX(-50%) scale(1);
    -moz-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    -o-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
  }
}
.ui-loader {
  display: none !important
}
@media screen and (max-width: 767px) {
  .cd-product-viewer-handle .handle {
    height: 3rem;
    width: 3rem;
    top: -1.4rem;
    background-size: 35px 19px;
  }
  .cd-product-viewer-wrapper {
    padding: 0
  }
}