/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
     -ms-touch-action: pan-y;
     touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    /*height: 100%;*/
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        vertical-align: top
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    background: transparent;
    z-index: 10;
    transition: color .5s ease,border-color .5s ease;
width: 7rem;
height: 7rem;
padding: 0;
font-size: 15px;
border: 0;
    color: #838EA4;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0

}
.web .slick-arrow:hover {
    color: $color-active;
    border-color: $color-active;
    position: relative;
    z-index: 1
}


.slick-next {
    margin-left: -1px;

}



.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
        text-align: center;
    list-style: none;
    margin: 66px 100px 0;
    li {
        display: inline-block;
        height: 7px;
        width: 7px;
        margin-right: 5px;
        position: relative;
        padding: 0;
        cursor: pointer;
        border: 1px solid transparent; 
        border-radius: 50%;



    }
    button {
        text-indent: -99999px;
        font-size: 0;
        padding: 0;
        background: #D8D8D8;
            width: 5px;
            height: 5px;
        vertical-align: top;
        border-radius: 50%;
        border: 1px solid #D8D8D8;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) ;       


    }
    .slick-active {
            border: 2px solid #4E3EFE;
            button {
            width: 3px;
            height: 3px;
                border-color: #fff;
                background: #fff;
            }
        &:after {
            transform: scale(1)
        }
    }
} 
.slick-disabled, .slick-disabled:hover {
    opacity: 0.5;
    pointer-events: none
}



@media screen and (max-width:767px) { 
    .slick-arrow {
text-align: center;
font-size: 1.2rem;
height: 5rem;
    width: 5rem;
}

}